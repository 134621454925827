import React, { useEffect } from "react";
import PageTitle from "../../shared/components/PageTitle";
import { IPublishedJob } from "../../types/PublishedJob";
import { OfficeChairIcon, SaveIcon } from "../../assets/icons";
import NoRecordFound from "../../shared/components/NoRecordFound";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import { RequisitionType } from "../../shared/constants/JobRequisition";
import moment from "moment/moment";
import { IProfileUser } from "../../types/ProfileUser";

const Jobs = () => {
  const navigate = useNavigate();
  const { response, loading, error, sendRequest, clearError } = useAxios();

  const userData: IProfileUser = JSON.parse(localStorage.getItem("userData") as string);

  const fetchPublishedJobs = async () => {
    try {
      await sendRequest({
        url: `/public/publishedJob?candidate_id=${userData.userId.id}`,
        method: "GET",
      });
    } catch (err) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchPublishedJobs();
  }, []);

  return (
    <>
      <PageTitle title="Open Positions" />
      <div className="px-5 sm:px-10">
        {response?.data?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {response?.data.map((item: IPublishedJob, index: number) => (
              <div
                key={index}
                className="max-h-80 border border-gray-300 rounded-xl p-1.5"
              >
                <div className="border border-transparent rounded-xl p-3 bg-jobPostCardBG mb-5 min-h-48">
                  <div className="flex items-center justify-between mb-5">
                    <div className="border border-transparent rounded-3xl bg-white px-2.5 py-1.5">
                      <h3 className="text-xs">
                        {moment(item.application_deadline).format("ll")}
                      </h3>
                    </div>
                    <img src={SaveIcon} alt="save icon" />
                  </div>
                  <div className="mb-5">
                    <div className="flex items-center justify-start">
                      <div className="bg-sidebarBG rounded-full w-2 h-2 mr-1.5"></div>
                      <span className="text-xs text-gray-600 mb-0.5">
                        Open Position
                      </span>
                    </div>
                    <div className="flex items-center justify-between space-x-3">
                      <h3 className="text-lg font-semibold text-wrap">
                        {item.title}
                      </h3>
                      <img src={OfficeChairIcon} alt="position icon" />
                    </div>
                  </div>
                  <div className="flex items-center justify-start flex-wrap gap-2">
                    <div className="border border-gray-500 rounded-3xl px-2 py-1 w-fit">
                      <h3 className="text-xs">
                        {item.requisition_reference_number}
                      </h3>
                    </div>
                    <div className="border border-gray-500 rounded-3xl px-2 py-1 w-fit">
                      <h3 className="text-xs">
                        {item.requisition_type === RequisitionType.CONTRACT
                          ? "Contract"
                          : item.requisition_type === RequisitionType.PERMANENT
                          ? "Permanent"
                          : item.requisition_type === RequisitionType.INTERNSHIP
                          ? "Internship"
                          : item.requisition_type === RequisitionType.REGULAR
                          ? "Regular"
                          : item.requisition_type ===
                            RequisitionType.TEMPORARY
                          ? "Temporary"
                          : item.requisition_type ===
                            RequisitionType.LIMITED_TERM
                          ? "Limited Term"
                          : " "}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between mx-3 mb-5">
                  <div>
                    <h3 className="text-sm font-semibold mb-0.5 underline">
                      Deadline:
                    </h3>
                    <p className="text-xs text-gray-500 text-wrap">
                      {moment(item.application_deadline).format("lll")}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      navigate("/candidate/job-details", { state: item })
                    }
                    className="border border-transparent border-sidebarBG rounded-3xl bg-sidebarBG px-2.5 py-1.5 cursor-pointer"
                  >
                    <h3 className="text-xs font-semibold text-white">
                      View Position
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoRecordFound />
        )}
      </div>
      {error && <ErrorModal error={error.error} onClose={clearError} />}
      {loading && <FullPageLoader />}
    </>
  );
};

export default Jobs;
