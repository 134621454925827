import React, {useEffect, useState} from "react";
import {IUsers} from "../../types/user";
import {Select, type SelectProps} from "antd";
import Button from "../../shared/components/Button";
import useAxios from "../../hooks/useAxios";
import {IRoles} from "../../types/roles";
import ErrorModal from "../../shared/components/ErrorModal";

interface IProps {
    onCancel: () => void;
    userDetails: IUsers
}

const AssignRoles = ({onCancel, userDetails}: IProps) => {

    const [selectedOption, setSelectedOption] = useState<string>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: rolesRes,
        error: rolesError,
        loading: rolesLoading,
        sendRequest: sendRolesReq,
        clearError: clearRolesError,
    } = useAxios();

    const options: { label: string, value: string }[] = rolesRes?.data?.map(
        (role: IRoles) => ({
            label: role.name,
            value: role.id,
        }),
    );

    const handleChange = (value: string) => {
        setSelectedOption(value);
    };

    const onSubmit = async () => {
        try {
            await sendRequest({
                url: `/user/assignRole/${userDetails.id}/${selectedOption}`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    };

    const getRolesHandler = async () => {
        try {
            await sendRolesReq({
                url: `/user/${userDetails.id}/notAssignedRoles`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getRolesHandler();
    }, []);

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form>
                <div className="mb-3">
                    <label
                        htmlFor="roles"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Select Role:
                    </label>
                    <div className="mt-3">
                        <Select
                            rootClassName="font-Montserrat"
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            loading={rolesLoading}
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleChange}
                            options={options?.length ? options : []}
                        />
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button
                        type="button"
                        btnText="Assign Role"
                        loading={loading}
                        onButtonClick={onSubmit}
                        isValid={!selectedOption}
                    />
                </div>
            </form>
            {rolesError ? <ErrorModal error={rolesError.error} onClose={clearRolesError}/> : error ?
                <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    )
}

export default AssignRoles