import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Select, type SelectProps} from "antd";
import {countries, ICountry} from "../../shared/constants/countries";
import {degreeOptions} from "../../shared/constants/SelectOptions";
import Button from "../../shared/components/Button";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";

interface IFormData {
    institution: string;
    qualification: string;
}

interface IProps {
    onCancel: () => void;
}

const schema = yup
    .object({
        institution: yup.string().required("Institution name is required"),
        qualification: yup.string().required("Qualification is required"),
    })
    .required();

const AddNewEducation = ({onCancel}: IProps) => {

    const userData: IProfileUser = getCurrentUserData();

    const [country, setCountry] = useState<string>("");
    const [degree, setDegree] = useState<string>("");
    const [yearCompletion, setYearCompletion] = useState<number>();
    const [yearOptions, setYearOptions] = useState<SelectProps["options"]>([]);

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError
    } = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const countryOptions: SelectProps["options"] = countries?.map(
        (country: ICountry) => ({
            label: country.name,
            value: country.name,
        }),
    );

    const generateYearOptions = () => {
        let currentYear: number = (new Date().getFullYear());
        let options: SelectProps["options"] = []
        while (currentYear >= 1974) {
            options.push({label: currentYear, value: currentYear});
            currentYear--;
        }
        setYearOptions(options);
    };


    const handleCountryChange = (value: string) => {
        setCountry(value);
    };

    const handleYearChange = (value: number) => {
        setYearCompletion(value);
    };

    const handleDegreeChange = (value: string) => {
        setDegree(value);
    };

    useEffect(() => {
        generateYearOptions();
    }, []);

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                institution: data.institution,
                degree,
                year_of_completion: yearCompletion,
                qualification: data.qualification,
                country: country,
                candidate: userData.userId.id
            };
            await sendRequest({
                url: "/education",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    }

    if (response?.data) {
        onCancel();
    }

    return (<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label
                    htmlFor="institution"
                    className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                >
                    Institution:
                </label>
                <div className="mt-1">
                    <div className='flex items-center justify-center space-x-5'>
                        <input
                            {...register("institution")}
                            type="text"
                            name="institution"
                            placeholder='Type here'
                            id="institution"
                            className={`pl-2 block w-full rounded ${errors.institution && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{errors.institution?.message}</p>
                </div>
            </div>
            <div className="mb-3">
                <label
                    htmlFor="roles"
                    className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                >
                    Qualification:
                </label>
                <div className="mt-2">
                    <Select
                        rootClassName="font-Montserrat"
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: "100%"}}
                        placeholder="Please select"
                        onChange={handleDegreeChange}
                        options={degreeOptions?.length ? degreeOptions : []}
                    />
                </div>
            </div>
            <div className="mb-3">
                <label
                    htmlFor="country"
                    className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                >
                    Field of Study:
                </label>
                <div className="mt-1">
                    <div className='flex items-center justify-center space-x-5'>
                        <input
                            {...register("qualification")}
                            type="text"
                            placeholder='Type here'
                            name="qualification"
                            id="qualification"
                            className={`pl-2 block w-full rounded ${errors.qualification && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{errors.qualification?.message}</p>
                </div>
            </div>
            <div className="mb-3">
                <label
                    htmlFor="roles"
                    className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                >
                    Year of Completion:
                </label>
                <div className="mt-2">
                    <Select
                        rootClassName="font-Montserrat"
                        allowClear
                        style={{width: "100%"}}
                        placeholder="Please select"
                        onChange={handleYearChange}
                        options={yearOptions?.length ? yearOptions : []}
                    />
                </div>
            </div>
            <div className="mb-3">
                <label
                    htmlFor="roles"
                    className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                >
                    Country:
                </label>
                <div className="mt-2">
                    <Select
                        rootClassName="font-Montserrat"
                        allowClear
                        showSearch
                        style={{width: "100%"}}
                        placeholder="Please select"
                        onChange={handleCountryChange}
                        options={countryOptions?.length ? countryOptions : []}
                    />
                </div>
            </div>
            <div className="my-5 border-b"></div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="button"
                    onClick={onCancel}
                    className="text-sm bg-dropdownBG px-3 py-1 rounded font-semibold font-Montserrat leading-6 text-black"
                >
                    Cancel
                </button>
                <Button type="submit" btnText="Save Qualification" loading={loading}
                />
            </div>
        </form>
        {error ? <ErrorModal error={error.error} onClose={clearError}/> : " "}
    </>);
}
export default AddNewEducation;