import React, {useEffect, useState} from "react";
import Dropdown from "../../shared/components/Dropdown";
import DropdownItem from "../../shared/components/DropdownItem";
import {AddIcon, EditIcon, GradeIcon} from "../../assets/icons";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateGrade from "./CreateGrade";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import {IGrade} from "../../types/grade";
import useAxios from "../../hooks/useAxios";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import NoRecordFound from "../../shared/components/NoRecordFound";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import EditGrade from "./EditGrade";
import PageButton from "../../shared/components/PageButton";

const ContainerHeight = 400;

const Grade = () => {
    const [showAddGrade, setShowAddGrade] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [data, setData] = useState<IGrade[]>([]);
    const [editGrade, setEditGrade] = useState(false);
    const [gradeDetails, setGradeDetails] = useState<IGrade>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const getGradeHandler = async () => {
        try {
            await sendRequest({
                url: "/settings/grade",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getGradeHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getGradeHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} qualifications loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getGradeHandler();
        }
    };

    return (
        <>
            <PageTitle title='Grades' dropdown={
                <PageButton title='Add New Grade' onClickHandler={() => {
                    setShowAddGrade(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                            onScroll={onScroll}
                        >
                            {(item: IGrade) => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={GradeIcon}/>}
                                        title={
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                                                {item.grade_code}
                                            </span>
                                        }
                                        description={
                                            <span className="font-Montserrat text-gray-500 text-xs">
                                                {item.description ? item.description : "No description"}
                                            </span>
                                        }
                                    />
                                    <div>
                                        <Actions
                                            items={[
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setGradeDetails(item)
                                                        setEditGrade(true)
                                                    }}
                                                    icon={EditIcon}
                                                    label="Edit Grade"
                                                    color="gray-500"
                                                />,
                                            ]}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddGrade && (
                <Modal
                    title="Add New Grade"
                    content={<CreateGrade onCancel={() => {
                        setShowAddGrade(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddGrade}
                    isHidden={() => setShowAddGrade(false)}
                    size="medium"
                />
            )}
            {editGrade && gradeDetails && (
                <Modal
                    title="Edit Job Grade"
                    content={<EditGrade gradeDetails={gradeDetails} onCancel={() => {
                        setEditGrade(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={editGrade}
                    isHidden={() => {
                        setGradeDetails(undefined)
                        setEditGrade(false)
                    }}
                    size="medium"
                />)}
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default Grade;