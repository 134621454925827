export interface ITemplate {
    id: string;
    template_name: string;
    total_weight: number;
    description: string;
    questionnaire_type: number;
    created_at: string;
    updated_at: string;
}

export interface IQuestionnaire {
    id: string;
    weight: number;
    question: string;
    question_type: number;
    created_at: string;
    updated_at: string;
    answer_options: AnswerOption[];
    selected?: number;
}

interface AnswerOption {
    id: string;
    answer_option: string;
    weight: number;
    is_correct_answer: boolean;
    created_at: string;
    updated_at: string;
}


export enum QuestionnaireType {
    SHORTLISTING_QUESTIONS = 0,
    INTERVIEW_QUESTIONS = 1
}