import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {TemplateFileIcon} from "../../assets/icons";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {CandidateAnswer} from "./types/ApplicationDetails";
import {QuestionType} from "../../shared/constants/QuestionType";
import moment from "moment";
import {IProfileUser, UserType} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";

const ApplicationDetails = () => {
    const {applId} = useParams();
    const userData: IProfileUser = getCurrentUserData();
    const {response, loading, error, sendRequest, clearError} = useAxios();

    const getApplicationDetailsHandler = async () => {
        try {
            await sendRequest({
                url: `/jobApplication/details/${applId}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getApplicationDetailsHandler();
    }, []);

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-20 pb-10">
                <div className="mb-5 p-4 sm:p-6 bg-userBadgeBg rounded-md border-t-sidebarBG border-t-8">
                    <div className="flex items-center space-x-2 mb-4">
                        <img src={TemplateFileIcon} alt="Template File Icon"/>
                        <h3 className="font-bold text-lg sm:text-xl">Application Details</h3>
                    </div>
                    {response?.data && (
                        <div className='ml-1.5'>
                            {userData.userId.user_type === UserType.CANDIDATE && (
                                <div className="flex items-center space-x-2 mb-3">
                                    <h3 className="font-semibold">Status:</h3>
                                    <div className="border border-gray-500 rounded-3xl px-3 py-1">
                                        <div className="flex items-center">
                                            <div className="bg-blue-400 rounded-full w-2 h-2 mr-2"></div>
                                            <span className="text-xs text-gray-600">SUBMITTED</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="space-y-2">
                                <div className="flex flex-col sm:flex-row sm:items-center">
                                    <h3 className="font-semibold">Names:</h3>
                                    <p className="text-sm text-gray-600 ml-2">
                                        {response.data.candidate.first_name} {response.data.candidate.last_name}
                                    </p>
                                </div>
                                {userData.userId.user_type !== UserType.CANDIDATE && (
                                    <div className="flex flex-col sm:flex-row sm:items-center">
                                        <h3 className="font-semibold">Marks:</h3>
                                        <p className="text-sm text-gray-600 ml-2">
                                            {response.data.total_marks}/{response.data.out_of_marks}
                                        </p>
                                    </div>
                                )}
                                <div className="flex flex-col sm:flex-row sm:items-center">
                                    <h3 className="font-semibold">National ID:</h3>
                                    <p className="text-sm text-gray-600 ml-2">{response.data.candidate.nid}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row sm:items-center">
                                    <h3 className="font-semibold">Submitted on:</h3>
                                    <p className="text-sm text-gray-600 ml-2">
                                        {moment(response.data.created_at).format("LLLL")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {response?.data?.answers?.length > 0 ? (
                    response?.data?.answers.map((item: CandidateAnswer) => (
                        <div
                            key={item.id}
                            className="mb-5 p-4 sm:p-6 bg-userBadgeBg border border-transparent border-gray-200 rounded-md"
                        >
                            <h3 className="font-semibold text-gray-700 mb-3">{item.question.question}</h3>
                            {item.question.question_type === QuestionType.SHORT_ANSWER ? (
                                <div className="ml-4 flex items-center">
                                    <div className="bg-black rounded-full w-2 h-2 mr-2"></div>
                                    <span className="text-gray-500 text-sm">{item.answers_text}</span>
                                </div>
                            ) : item.question.question_type === QuestionType.SINGLE_CHOICE ? (
                                item.question.answer_options.map((optionItem) => (
                                    optionItem.answer_option ? (
                                        <div key={optionItem.id} className="ml-4 flex items-center mb-2">
                                            <div
                                                className={`${
                                                    item.answers_choices.some((ans) => ans.id === optionItem.id)
                                                        ? "bg-sidebarBG"
                                                        : "bg-gray-500"
                                                } rounded-full w-2 h-2 mr-2`}
                                            ></div>
                                            <div className='flex items-center justify-center space-x-3'>
                                                <span
                                                    className={`${
                                                        item.answers_choices.some((ans) => ans.id === optionItem.id)
                                                            ? "text-sidebarBG font-semibold"
                                                            : "text-gray-600"
                                                    } text-sm`}
                                                >{optionItem.answer_option}</span>
                                                {userData.userId.user_type !== UserType.CANDIDATE && (
                                                    <span className='text-gray-500 text-xs'>
                                                        {optionItem.weight + '/' + item.question.weight}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ) : ('')
                                ))
                            ) : item.question.question_type === QuestionType.MULTIPLE_CHOICE ? (
                                item.question.answer_options.map((optionItem) => (
                                    optionItem.answer_option ? (
                                        <div key={optionItem.id} className="ml-4 flex items-center mb-2">
                                            <div
                                                className={`${
                                                    item.answers_choices.some((ans) => ans.id === optionItem.id)
                                                        ? "bg-sidebarBG"
                                                        : "bg-gray-500"
                                                } rounded-full w-2 h-2 mr-2`}
                                            ></div>
                                            <div className='flex items-center justify-center space-x-3'>
                                                <span
                                                    className={`${
                                                        item.answers_choices.some((ans) => ans.id === optionItem.id)
                                                            ? "text-sidebarBG font-semibold"
                                                            : "text-gray-600"
                                                    } text-sm`}
                                                >{optionItem.answer_option}</span>
                                                {userData.userId.user_type !== UserType.CANDIDATE && (
                                                    <span className='text-gray-500 text-xs'>
                                                        {optionItem.weight + '/' + item.question.weight}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ) : ('')
                                ))
                            ) : (
                                item.question.question_type === QuestionType.PARAGRAPH && (
                                    <div className="ml-4 flex items-center">
                                        <div className="bg-black rounded-full w-2 h-2 mr-2"></div>
                                        <span className="text-gray-500 text-sm">{item.answers_text}</span>
                                    </div>
                                )
                            )}
                        </div>
                    ))
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
};

export default ApplicationDetails;
