import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import PageTitle from "../../shared/components/PageTitle";
import useAxios from "../../hooks/useAxios";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import moment from "moment/moment";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {PositionIcon, SaveIcon, ViewIcon} from "../../assets/icons";
import Modal from "../../shared/components/Modal";
import JobApplicationDetails from "./JobApplicationDetails";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {ApplicationStatusEnum} from "./types/ApplicationStatus";
import {IReceivedApplications} from "../job-requisition/types/ReceivedApplications";
import {RequisitionType} from "../../shared/constants/JobRequisition";
import {Dropdown, MenuProps, Space, Tooltip} from "antd";
import ViewDocuments from "../job-requisition/ViewDocuments";
import ViewCandidateInfo from "../job-requisition/ViewCandidateInfo";

const MyApplication = () => {
    const [applicationDetails, setApplicationDetails] = useState<IReceivedApplications>();
    const [openApplicationDetailsModel, setOpenApplicationDetailsModel] = useState<boolean>(false);
    const [showViewDocuments, setShowViewDocuments] = useState(false);
    const [showViewCandidateInfo, setShowViewCandidateInfo] = useState(false);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const userData: IProfileUser = getCurrentUserData();

    const navigate = useNavigate();

    const getJobApplicationsHandler = async () => {
        try {
            await sendRequest({
                url: `/jobApplication?candidate=${userData?.userId?.id}`,
                method: "GET",
            });
        } catch (err) {
            // Handle error
        }
    };

    useEffect(() => {
        getJobApplicationsHandler();
    }, []);

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <h3
                    onClick={() => setShowViewDocuments(true)}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    View Documents
                </h3>
            ),
            icon: <img className="w-5" src={ViewIcon} alt="add icon"/>,
        },
        {
            key: "2",
            label: (
                <h3
                    onClick={() => navigate(`/job-requisition/application-details/${applicationDetails?.id}`)}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Application Details
                </h3>
            ),
            icon: <img className="w-5" src={ViewIcon} alt="add icon"/>,
        },
        {
            key: "3",
            label: (
                <h3
                    onClick={() => setShowViewCandidateInfo(true)}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Personal Information
                </h3>
            ),
            icon: <img className="w-5" src={ViewIcon} alt="add icon"/>,
        },
    ];

    return (
        <>
            <PageTitle title="My Applications"/>
            <div className="px-0">
                {response?.data?.length > 0 ? (
                    // Full-width grid with 3 cards per row on large screens
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        {response?.data.map((item: IReceivedApplications) => (
                            <div className="border border-gray-300 rounded-xl p-3 w-full">
                                <div className="border border-transparent rounded-xl p-3 bg-userBadgeBg mb-5 min-h-48">
                                    <div className="flex items-center justify-between mb-5">
                                        <div className="border border-transparent rounded-3xl bg-white px-2.5 py-1.5">
                                            <h3 className="text-xs">
                                                {moment(item.created_at).format("ll")}
                                            </h3>
                                        </div>
                                        <img src={SaveIcon} alt="save icon"/>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-xs text-gray-600 mb-0.5">
                                            {item.requisition.requisition_type === RequisitionType.CONTRACT
                                                ? "Contract"
                                                : item.requisition.requisition_type === RequisitionType.PERMANENT
                                                    ? "Permanent"
                                                    : item.requisition.requisition_type === RequisitionType.INTERNSHIP
                                                        ? "Internship"
                                                        : item.requisition.requisition_type === RequisitionType.REGULAR
                                                            ? "Regular"
                                                            : item.requisition.requisition_type === RequisitionType.TEMPORARY
                                                                ? "Temporary"
                                                                : item.requisition.requisition_type === RequisitionType.LIMITED_TERM
                                                                    ? "Limited Term"
                                                                    : " "}
                                        </p>
                                        <div className="flex items-center justify-between space-x-3">
                                            <h3 className="text-lg font-semibold text-wrap">{item?.requisition?.job_profile?.title}</h3>
                                            <img src={PositionIcon} alt="position icon"/>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start flex-wrap gap-2">
                                        <div className="border border-gray-500 rounded-3xl px-2 py-1 w-fit">
                                            <div className="flex items-center justify-center">
                                                <div className="bg-blue-400 rounded-full w-2 h-2 mr-1.5"></div>
                                                <span className="text-xs">SUBMITTED</span>
                                            </div>
                                        </div>
                                        <div className="border border-gray-500 rounded-3xl px-2 py-1 w-fit">
                                            <h3 className="text-xs">{moment(item.created_at).fromNow()}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mx-3 mb-5">
                                    <div>
                                        <h3 className="text-sm font-semibold mb-0.5 underline">Applied On:</h3>
                                        <p className="text-xs text-gray-500">{moment(item.created_at).format("llll")}</p>
                                    </div>
                                    <Dropdown trigger={["click"]} menu={{items}}>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setApplicationDetails(item);
                                            }}
                                        >
                                            <Space>
                                                <Tooltip title="View Application Details">
                                                    <div
                                                        className="border border-transparent border-sidebarBG rounded-3xl bg-sidebarBG px-2.5 py-1 cursor-pointer">
                                                        <h3 className="text-sm font-semibold text-white">Details</h3>
                                                    </div>
                                                </Tooltip>
                                            </Space>
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <NoRecordFound/>
                )}
            </div>

            {openApplicationDetailsModel && applicationDetails && (
                <Modal
                    title={`Job Application - ${applicationDetails.requisition.job_profile.title}`}
                    content={<JobApplicationDetails applicationDetails={applicationDetails}/>}
                    isShown={openApplicationDetailsModel}
                    isHidden={() => {
                        setApplicationDetails(undefined);
                        setOpenApplicationDetailsModel(false);
                    }}
                    size="large"
                />
            )}

            {showViewDocuments && applicationDetails && (
                <Modal
                    title={`Documents Attached`}
                    content={<ViewDocuments applicationDetails={applicationDetails}/>}
                    isShown={showViewDocuments}
                    isHidden={() => {
                        setApplicationDetails(undefined);
                        setShowViewDocuments(false);
                    }}
                    size="large"
                />
            )}

            {showViewCandidateInfo && applicationDetails && (
                <Modal
                    title="Personal Information"
                    content={<ViewCandidateInfo applicationDetails={applicationDetails}/>}
                    isShown={showViewCandidateInfo}
                    isHidden={() => {
                        setApplicationDetails(undefined);
                        setShowViewCandidateInfo(false);
                    }}
                    size="large"
                />
            )}

            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
};

export default MyApplication;
