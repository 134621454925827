import {PasswordIcon} from "../../assets/icons";
import React from "react";
interface IProps {
    register: any;
    errors: any;
}

const SetPassword = ({register,errors}: IProps) =>{
    return (<div>
        <div className="mb-3">
            <div className="mt-3">
                <div className='flex border-2 rounded w-full '>
                    <img className='ml-3' src={PasswordIcon} alt="user email icon"/>
                    <input
                        {...register("password")}
                        type="password"
                        name="password"
                        id="password"
                        placeholder='Enter your password'
                        className={`pl-2 block w-full ${errors.password && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                    />
                </div>
                <p className="text-red-500 text-xs">{errors.password?.message}</p>
            </div>
        </div>

        <div className="mb-3">
            <div className="mt-3">
                <div className='flex border-2 rounded w-full '>
                    <img className='ml-3' src={PasswordIcon} alt="user email icon"/>
                    <input
                        {...register("rePassword")}
                        type="password"
                        name="rePassword"
                        id="rePassword"
                        placeholder='Re-Enter your password'
                        className={`pl-2 block w-full ${errors.rePassword && "border-red-500"}  py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                    />
                </div>
                <p className="text-red-500 text-xs">{errors.rePassword?.message}</p>
            </div>
        </div>
    </div>);
}

export default SetPassword;