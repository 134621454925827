import React from "react";
import {
    DropdownTable,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "./ActionsDropdown";
import { OptionsIcon } from "../../assets/icons";

const Actions = ({ items }: any) => {
    return (
        <DropdownTable>
            <DropdownToggle>
                <img src={OptionsIcon} alt="" className="h-6" />
            </DropdownToggle>
            <DropdownMenu>
                {items.map((item: any, index: number) => (
                    <DropdownItem key={index}>{item}</DropdownItem>
                ))}
            </DropdownMenu>
        </DropdownTable>
    );
};
export default Actions;
