import React from "react";
import {ICandidate_Experience, IReceivedApplications} from "./types/ReceivedApplications";
import {Avatar, List} from "antd";
import VirtualList from "rc-virtual-list";
import {ExperienceIcon} from "../../assets/icons";
import moment from "moment/moment";

interface IProps {
    applicationDetails: IReceivedApplications
}

const ContainerHeight = 400;

const CandidateExperience = ({applicationDetails}: IProps) => {

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            return applicationDetails.candidate.experiences;
        }
    };

    return (
        <>
            <div className='py-10 px-3'>
                <List size='small'>
                    <VirtualList
                        data={applicationDetails.candidate.experiences}
                        height={ContainerHeight}
                        itemHeight={47}
                        itemKey="id"
                        onScroll={onScroll}
                    >
                        {(item: ICandidate_Experience) => (
                            <List.Item key={item.id}>
                                <List.Item.Meta
                                    avatar={<Avatar src={ExperienceIcon}/>}
                                    title={
                                        <span className=" text-sidebarBG  text-base">
                                            {item.job_title}
                                        </span>
                                    }
                                    description={
                                        <p className="text-xs">
                                            <span className='text-gray-500'>
                                                {item['employer_name']} | {moment(new Date(item['start_date'])).format("ll")} - {' '}
                                            </span>
                                            {
                                                item.is_current_work_here ?
                                                    <span className='text-sidebarBG font-semibold'>Present</span> :
                                                    <span className='text-gray-500'>
                                                        {moment(new Date(item['end_date'])).format("ll")}
                                                    </span>
                                            }
                                        </p>
                                    }
                                />
                            </List.Item>
                        )}
                    </VirtualList>
                </List>
            </div>
        </>
    )
}

export default CandidateExperience;