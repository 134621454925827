import React from "react";
import Chart from 'react-apexcharts'

const series = [{
    name: "Candidates",
    data: [230, 340, 120, 540, 320, 430, 350, 210, 450]
}]

const options: any = {
    chart: {
        type: 'area',
        height: 270,
        zoom: {
            enabled: false
        },
        fontFamily: 'Montserrat',
        animations: {
            easing: 'easeinout',
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 3
    },
    colors: ['#81632D', '#D9C092', '#F7EFDE', '#FEFDFB'],
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
        }
    },
    title: {
        text: 'Applicants Report',
        align: 'left'
    },
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    xaxis: {
        type: 'string',
    },
    yaxis: {
        opposite: false
    },
    legend: {
        horizontalAlign: 'left'
    }
}


const AreaChartComponent = () => {

    return (
        <div>
            <Chart options={options} series={series} type="area" height={270}/>
        </div>
    )
}

export default AreaChartComponent;