import React, {useEffect, useState} from "react";
import {IJobProfile} from "../../types/JobProfile";
import {Select, type SelectProps} from "antd";
import {Editor} from "react-draft-wysiwyg";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import {useForm} from "react-hook-form";
import useAxios from "../../hooks/useAxios";
import {RawDraftContentState} from "draft-js";
import {IErpPosition} from "./types/ErpPosition";

interface IProps {
    onCancel: () => void;
    jobProfile: IJobProfile
}

const EditJobProfile = ({onCancel, jobProfile}: IProps) => {

    const [selectedJobProfile, setSelectedJobProfile] = useState<string>(jobProfile.erp_position_id);
    const [editorState, setEditorState] = useState<RawDraftContentState>(JSON.parse(
        jobProfile.job_responsibilities,
    ));
    const [jobDescription, setJobDescription] = useState<RawDraftContentState>(JSON.parse(
        jobProfile.job_summary,
    ));
    const [jobRequirements, setJobRequirements] = useState<RawDraftContentState>(JSON.parse(
        jobProfile.job_requirements,
    ));

    const {
        response, error, loading, sendRequest, clearError
    } = useAxios();

    const {
        response: jobProfileRes,
        error: jobProfileError,
        loading: jobProfileLoading,
        sendRequest: sendJobProfileReq,
        clearError: clearJobProfileError
    } = useAxios();

    const {
        handleSubmit,
    } = useForm();

    const jobProfileOptions: { label: string, value: string }[] = jobProfileRes?.data?.map(
        (position: IErpPosition) => ({
            label: position.name,
            value: position.position_id
        }),
    );

    const handleJobProfileChange = (value: string) => {
        setSelectedJobProfile(value);
    }

    const onEditorStateChange = (editorContent: RawDraftContentState) => {
        setEditorState(editorContent);
    };

    const onJobDescriptionChange = (editorContent: RawDraftContentState) => {
        setJobDescription(editorContent);
    }

    const onJobRequirementsChange = (editorContent: RawDraftContentState) => {
        setJobRequirements(editorContent);
    }

    const onSubmit = async () => {
        try {
            const payload = {
                position_id: selectedJobProfile,
                job_responsibilities: JSON.stringify(editorState),
                job_summary: JSON.stringify(jobDescription),
                job_requirement: JSON.stringify(jobRequirements)
            };
            await sendRequest({
                url: `/jobProfile/${jobProfile.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    }

    const getJobProfileHandler = async () => {
        try {
            await sendJobProfileReq({
                url: `/jobProfile/erp`,
                method: "GET",
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        getJobProfileHandler();
    }, []);

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3 w-2/3'>
                    <label
                        htmlFor="job_title"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Title:
                    </label>
                    <div className="mt-2">
                        <Select
                            defaultValue={jobProfile.title}
                            rootClassName="font-Montserrat"
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            loading={jobProfileLoading}
                            style={{width: '100%'}}
                            placeholder="Please select"
                            allowClear
                            onChange={handleJobProfileChange}
                            options={jobProfileOptions?.length ? jobProfileOptions.filter((item) => item.value !== jobProfile.erp_position_id) : []}
                        />
                    </div>
                </div>
                <div className="mb-24">
                    <label
                        htmlFor="responsibilities"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        About the Job:
                    </label>
                    <div className="mt-1">
                        <Editor
                            defaultContentState={JSON.parse(
                                jobProfile.job_summary,
                            )}
                            editorClassName="bg-notesBG"
                            wrapperClassName="h-32"
                            onContentStateChange={onJobDescriptionChange}
                            placeholder=" Type job description here..."
                        />
                    </div>
                </div>
                <div className="mb-24">
                    <label
                        htmlFor="responsibilities"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Responsibilities:
                    </label>
                    <div className="mt-1">
                        <Editor
                            defaultContentState={JSON.parse(
                                jobProfile.job_responsibilities,
                            )}
                            editorClassName="bg-notesBG"
                            wrapperClassName="h-32"
                            onContentStateChange={onEditorStateChange}
                            placeholder=" Type job responsibilities here..."
                        />
                    </div>
                </div>
                <div className="">
                    <label
                        htmlFor="requirements"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Requirements:
                    </label>
                    <div className="mt-1">
                        <Editor
                            defaultContentState={JSON.parse(
                                jobProfile.job_requirements,
                            )}
                            editorClassName="bg-notesBG"
                            wrapperClassName="h-32"
                            onContentStateChange={onJobRequirementsChange}
                            placeholder=" Type job requirements here..."
                        />
                    </div>
                </div>
                <div className="mt-24 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Changes" loading={loading}
                            isValid={!selectedJobProfile}/>
                </div>
            </form>
            ,
            {jobProfileError ?
                <ErrorModal error={jobProfileError.error} onClose={clearJobProfileError}/> : error ?
                    <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    );
};

export default EditJobProfile;