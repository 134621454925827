export enum DegreeEnum {
    GENERAL_PRIMARY = 0,
    O_LEVEL,
    DIPLOMA_A2,
    DIPLOMA_A1,
    ADVANCED_DIPLOMA_A1,
    BACHELORS_A0,
    POST_GRADUATE_DIPLOMA,
    MASTERS,
    PHD,
    DRIVING_LICENSE,
}

export enum EmploymentType {
    REMOTE = 1,
    HYBRID,
    ON_SITE
}

export enum levelEnum {
    EXCELLENT = 0,
    VERY_GOOD,
    GOOD,
}

export enum languageEnum {
    ENGLISH = 0,
    FRENCH,
    KINYARWANDA,
}