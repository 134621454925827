import PinInput from 'react-pin-input';
import React from "react";
import {CheckedIcon, IdIcon, VerifiedIcon} from "../../assets/icons";

interface IProps{
    otp: string;
    email: string;
    isEmailVerified: boolean;
    otpValidationHandler: (email: string,otpInput: string)=>Promise<void>;
}
const OtpVerification = ({otp,email,isEmailVerified,otpValidationHandler}: IProps) =>{
    return (
        <div>
            {isEmailVerified? <>
                <div className="flex items-center justify-center mt-4"><img className='mr-3' src={CheckedIcon} alt="user email icon"/></div>
                <p className="w-full mt-3 bg-badgeBG p-1 rounded text-primary text-xs">Your email <b>{email}</b> has
                    been verified successfully! </p>
            </> : <>
                <p className="w-full mt-3 bg-badgeBG p-1 rounded text-primary text-xs">We have
                    sent a verification code to your email and phone number <b>{email}</b>,please enter the code to complete
                    registration </p>
                <div className="mt-3 flex items-center justify-center">
                    <PinInput
                        length={4}
                        initialValue={otp}
                        secret
                        focus
                        type="numeric"
                        inputMode="number"
                        style={{padding: '10px', borderRadius: "7px"}}
                        onComplete={async (value: string, index: number) => {
                            await otpValidationHandler(email, value);
                        }}
                        autoSelect={false}
                    />
                </div>
            </>}
        </div>);
}

export default OtpVerification;