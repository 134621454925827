import React, {useEffect, useState} from "react";
import PageTitle from "../../shared/components/PageTitle";
import {
    ApproveIcon,
    AssignRole,
    DisabledIcon,
    EditIcon,
    RemoveRole,
    UserRole,
    ViewIcon
} from "../../assets/icons";
import Modal from "../../shared/components/Modal";
import AddNewRole from "./AddNewRole";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import useAxios from "../../hooks/useAxios";
import {IRoles} from "../../types/roles";
import NoRecordFound from "../../shared/components/NoRecordFound";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import EditRole from "./EditRole";
import AssignPrivileges from "../privileges/AssignPrivileges";
import {IPrivileges} from "../../types/privileges";
import {RoleAction, Status} from "../../shared/constants/status";
import Badge from "../../shared/components/Badge";
import PrivilegesAssignedToRole from "../privileges/PrivilegesAssignedToRole";
import PageButton from "../../shared/components/PageButton";

const ContainerHeight = 400;

const Roles = () => {
    const [showAddRole, setShowAddRole] = useState(false);
    const [data, setData] = useState<IRoles[]>([]);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [editRole, setEditRole] = useState(false);
    const [roleDetails, setRoleDetails] = useState<IRoles>();
    const [showAssignPrivilege, setShowAssignPrivilege] = useState(false);
    const [privilegeDetails, setPrivilegeDetails] = useState<IPrivileges>();
    const [viewPrivileges, setViewPrivileges] = useState(false);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: roleStatusRes,
        error: roleStatusError,
        loading: roleStatusLoading,
        sendRequest: sendRoleStatusReq,
        clearError: clearRoleStatusError
    } = useAxios();

    const roleStatusHandler = async (id: string, action: string) => {
        if (action === RoleAction.disable) {
            try {
                await sendRoleStatusReq({
                    url: `/role/${id}/status/${Status.INACTIVE}`,
                    method: "PUT",
                    data: {},
                });
            } catch (err) {
            }
        } else if (action === RoleAction.approve) {
            try {
                await sendRoleStatusReq({
                    url: `/role/${id}/status/${Status.ACTIVE}`,
                    method: "PUT",
                    data: {},
                });
            } catch (err) {
            }
        } else {
            return null
        }

    }

    useEffect(() => {
        if (roleStatusRes?.data) {
            setFetchAgain(true);
        }
    }, [roleStatusRes?.data]);

    const getRolesHandler = async () => {
        try {
            await sendRequest({
                url: "/role",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getRolesHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getRolesHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} roles loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getRolesHandler();
        }
    };

    return (
        <>
            <PageTitle title='Roles' dropdown={
                <PageButton title='Add New Role' onClickHandler={() => {
                    setShowAddRole(true)
                }
                }/>
            }/>
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                            onScroll={onScroll}
                        >
                            {(item: IRoles) => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={UserRole}/>}
                                        title={item.status === Status.INACTIVE ?
                                            <div className='flex items-center space-x-3.5'>
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                            {item.name}
                          </span> <Badge key={item.id} label="Disabled" color="bg-red-500" icon={DisabledIcon}/></div> :
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                            {item.name}
                          </span>
                                        }
                                        description={
                                            <span className="font-Montserrat text-gray-500 text-xs">
                            {item.description ? item.description : "No description"}
                          </span>
                                        }
                                    />
                                    <div>
                                        <Actions
                                            items={[
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setRoleDetails(item)
                                                        setViewPrivileges(true)
                                                    }}
                                                    icon={ViewIcon}
                                                    label="View Privileges"
                                                    color="gray-500"
                                                />,
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setPrivilegeDetails(item)
                                                        setShowAssignPrivilege(true)
                                                    }}
                                                    icon={AssignRole}
                                                    label="Assign Privilege"
                                                    color="gray-500"
                                                />,
                                                item.status === Status.ACTIVE && (
                                                    <DropdownMenuItem
                                                        handlerFn={async () => {
                                                            await roleStatusHandler(item.id, RoleAction.disable)
                                                        }}
                                                        icon={RemoveRole}
                                                        label="Disable Role"
                                                        color="gray-500"
                                                    />
                                                ),
                                                item.status === Status.INACTIVE && (
                                                    <DropdownMenuItem
                                                        handlerFn={async () => {
                                                            await roleStatusHandler(item.id, RoleAction.approve)
                                                        }}
                                                        icon={ApproveIcon}
                                                        label="Approve Role"
                                                        color="gray-500"
                                                    />
                                                ),
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setRoleDetails(item)
                                                        setEditRole(true)
                                                    }}
                                                    icon={EditIcon}
                                                    label="Edit Role"
                                                    color="gray-500"
                                                />
                                            ]}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddRole && (
                <Modal
                    title="Add New Role"
                    content={<AddNewRole onCancel={() => {
                        setShowAddRole(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddRole}
                    isHidden={() => setShowAddRole(false)}
                    size="medium"
                />
            )}
            {editRole && roleDetails && (
                <Modal
                    title="Edit Role"
                    content={<EditRole roleDetails={roleDetails} onCancel={() => {
                        setEditRole(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={editRole}
                    isHidden={() => {
                        setRoleDetails(undefined)
                        setEditRole(false)
                    }}
                    size="medium"
                />
            )}
            {showAssignPrivilege && privilegeDetails && (
                <Modal
                    title={`Assign Privileges to ${privilegeDetails.name}`}
                    content={<AssignPrivileges privilegeDetails={privilegeDetails} onCancel={() => {
                        setShowAssignPrivilege(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAssignPrivilege}
                    isHidden={() => setShowAssignPrivilege(false)}
                    size="medium"
                />
            )}
            {viewPrivileges && roleDetails && (
                <Modal
                    title={`Privileges Assigned to ${roleDetails.name}`}
                    content={<PrivilegesAssignedToRole key={roleDetails.id} roleDetails={roleDetails}/>}
                    isShown={viewPrivileges}
                    isHidden={() => {
                        setRoleDetails(undefined)
                        setViewPrivileges(false)
                    }}
                    size="large"
                />
            )}
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : roleStatusError ?
                <ErrorModal error={roleStatusError.error} onClose={clearRoleStatusError}/> : ""}
            {(loading || roleStatusLoading) && <FullPageLoader/>}
        </>
    )
}

export default Roles;