import axios from "axios";
import download from "downloadjs";
import {message} from "antd";
import {IProfileUser} from "../types/ProfileUser";


export const handleFileDownload = async (fileId: string, fileName: string, url: string) => {

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string,
    );

    const filename = fileName;
    const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${url}/${fileId}`,
        {
            headers: {
                "Authorization": `Bearer ${userData.token}`,
            },
            responseType: "blob",
            onDownloadProgress: (progressEvent: any) => {
                console.log(
                    "Download progress: " +
                    Math.round(
                        (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
                    ) +
                    "%"
                );
            },
        }
    );
    if (res.statusText === "OK") {
        const data = res.data as Blob;
        download(data, filename);
        message.success("File downloaded successfully");
    } else {
        message.error("File download failed");
    }
}