import React from "react";
import {Gender} from "../../types/ProfileUser";
import {Select} from "antd";
import {AttachmentTypeEnum, MaritalStatus, maritalStatusOptions} from "../jobs/types/constants";
import moment from "moment/moment";
import {UploadIcon} from "../../assets/icons";
import DetailRow from "../../shared/components/DetailRow";
import {IReceivedApplications} from "./types/ReceivedApplications";

interface IProps {
    applicationDetails: IReceivedApplications;
}

const ViewCandidateInfo = ({applicationDetails}: IProps) => {
    return (
        <div className='mt-3 mb-8 mx-5'>
            <div className="mb-8 flex items-center justify-center space-x-5">
                <div className='w-1/2'>
                    <DetailRow label='Full Name'
                               value={applicationDetails.candidate.first_name + ' ' + applicationDetails.candidate.last_name}
                               color='bg-searchBG' valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
                <div className='w-1/2'>
                    <DetailRow label='National ID' value={applicationDetails.candidate.nid} color='bg-searchBG'
                               valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
            </div>
            <div className="mb-8 flex items-center justify-center space-x-5">
                <div className='w-1/2'>
                    <DetailRow label='Gender'
                               value={applicationDetails.candidate.gender === Gender.MALE ? 'Male' : applicationDetails.candidate.gender === Gender.FEMALE ? 'Female' : ''}
                               color='bg-searchBG'
                               valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
                <div className='w-1/2'>
                    <DetailRow label='Marital Status'
                               value={applicationDetails.candidate.marital_status === MaritalStatus.SINGLE ? 'Single' : applicationDetails.candidate.marital_status === MaritalStatus.MARRIED ? 'Married' : applicationDetails.candidate.marital_status === MaritalStatus.DIVORCED ? 'Divorced' : applicationDetails.candidate.marital_status === MaritalStatus.WIDOWED ? 'Widowed' : ''}
                               color='bg-searchBG'
                               valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
            </div>
            <div className="mb-8 flex items-center justify-center space-x-5">

                <div className='w-1/2'>
                    <DetailRow label='Date of Birth'
                               value={applicationDetails.candidate.date_of_birth}
                               color='bg-searchBG'
                               valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
                <div className='w-1/2'>
                    <DetailRow label='Place of Residence' value={applicationDetails.candidate.place_of_residence}
                               color='bg-searchBG'
                               valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
            </div>
            <div className="mb-8 flex items-center justify-center space-x-5">
                <div className='w-1/2'>
                    <DetailRow label='Email' value={applicationDetails.candidate.email} color='bg-searchBG'
                               valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
                <div className='w-1/2'>
                    <DetailRow label='Phone Number' value={applicationDetails.candidate.phone_number}
                               color='bg-searchBG' valueColor='text-gray-500' labelColor='text-gray-700'/>
                </div>
            </div>
        </div>
    )
}
export default ViewCandidateInfo;