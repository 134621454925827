import React from 'react'
import PageTitle from "../../shared/components/PageTitle";
import {TrendDownIcon, TrendUpIcon, UsersIcon} from "../../assets/icons";
import AreaChartComponent from "../../shared/charts/AreaChart";
import PieChartComponent from "../../shared/charts/PieChart";

const Dashboard = () => {
    return (
        <>
            <PageTitle title='Dashboard'/>
            <div className='flex items-center justify-start flex-wrap gap-5'>
                <div className='border-2 border-transparent p-5 bg-dashboardCardBG w-1/5 rounded-lg'>
                    <div className='flex items-center space-x-4 mb-5'>
                        <div className='p-2 border-2 border-transparent bg-iconBadgeBG rounded-xl'>
                            <img src={UsersIcon} alt="candidates icon"/>
                        </div>
                        <div>
                            <p className='text-xs text-gray-500'>Total Applications</p>
                            <div className='flex items-center justify-start space-x-5'>
                                <p className='text-black font-bold text-2xl'>1,200</p>
                                <img src={TrendUpIcon} alt="trend up icon"/>
                            </div>
                        </div>
                    </div>

                    <p className='text-gray-500 text-xs'>50% Increase from last month</p>
                </div>
                <div className='border-2 border-transparent p-5 bg-dashboardCardBG w-1/5 rounded-lg'>
                    <div className='flex items-center space-x-4 mb-5'>
                        <div className='p-2 border-2 border-transparent bg-blueIconBadge rounded-xl'>
                            <img src={UsersIcon} alt="candidates icon"/>
                        </div>
                        <div>
                            <p className='text-xs text-gray-500'>Shortlisted Applicants</p>
                            <div className='flex items-center justify-start space-x-5'>
                                <p className='text-black font-bold text-2xl'>350</p>
                                <img src={TrendDownIcon} alt="trend down icon"/>
                            </div>
                        </div>
                    </div>

                    <p className='text-gray-500 text-xs'>30% Decrease from last month</p>
                </div>
                <div className='border-2 border-transparent p-5 bg-dashboardCardBG w-1/5 rounded-lg'>
                    <div className='flex items-center space-x-4 mb-5'>
                        <div className='p-2 border-2 border-transparent bg-purpleIconBadge rounded-xl'>
                            <img src={UsersIcon} alt="candidates icon"/>
                        </div>
                        <div>
                            <p className='text-xs text-gray-500'>Total Applicants</p>
                            <div className='flex items-center justify-start space-x-5'>
                                <p className='text-black font-bold text-2xl'>850</p>
                                <img src={TrendUpIcon} alt="trend up icon"/>
                            </div>
                        </div>
                    </div>

                    <p className='text-gray-500 text-xs'>15% Increase from last month</p>
                </div>
                <div className='border-2 border-transparent p-5 bg-dashboardCardBG w-1/5 rounded-lg'>
                    <div className='flex items-center space-x-4 mb-5'>
                        <div className='p-2 border-2 border-transparent bg-darkBlueIconBadge rounded-xl'>
                            <img src={UsersIcon} alt="candidates icon"/>
                        </div>
                        <div>
                            <p className='text-xs text-gray-500'>Open Positions</p>
                            <div className='flex items-center justify-start space-x-5'>
                                <p className='text-black font-bold text-2xl'>10</p>
                                <img src={TrendUpIcon} alt="trend up icon"/>
                            </div>
                        </div>
                    </div>

                    <p className='text-gray-500 text-xs'>45% Increase from last month</p>
                </div>
            </div>
            {/*Charts*/}
            <div className='mt-20 flex items-center justify-between'>
                <div className='w-1/2 p-5 rounded-lg border-2 border-transparent bg-dashboardCardBG'>
                    <AreaChartComponent/>
                </div>
                <div className='w-1/3 p-5 h-80 rounded-lg border-2 border-transparent bg-dashboardCardBG'>
                    <PieChartComponent/>
                </div>
            </div>
        </>
    )
}

export default Dashboard