import {useState, useEffect} from "react";
import axios from "axios";
import moment from "moment";
import {IResponse} from "../types/response";
import {IError} from "../types/error";

// Custom hook for API calls with request cancellation and interceptors

interface IRequest {
    url: string;
    method: "GET" | "POST" | "PUT";
    data?: any;
    params?: object;
    callBack?: (args: Record<string, any>) => void;
}

const useAxios = () => {
    const [response, setResponse] = useState<IResponse>();
    const [error, setError] = useState<IError | null>();
    const [loading, setLoading] = useState(false);

    // Create an Axios instance
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your actual base URL
    });

    // Set up request and response interceptors
    axiosInstance.interceptors.request.use(
        (config) => {
            // Log or modify request here
            console.log("Sending request to: ", config.url);
            const userData = JSON.parse(localStorage.getItem("userData") as string);
            config.headers["Content-Type"] = "application/json; charset=utf-8";
            config.headers["Accept"] = "application/json";
            if (
                userData &&
                userData.token &&
                moment(userData.expiration) > moment()
            ) {
                config.headers["Authorization"] = `Bearer ${userData.token}`;
            }

            return config;
        },
        (error) => {
            // Handle request error here
            return Promise.reject(error);
        },
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            // Log or modify response here
            console.log("Received response from: ", response.config.url);
            return response;
        },
        (error) => {
            // Handle response error here
            return Promise.reject(error);
        },
    );

    let controller = new AbortController();

    useEffect(() => {
        //const source = axios.CancelToken.source();

        return () => {
            // Cancel the request when the component unmounts
            //source.cancel("Component unmounted: Request cancelled.");
            controller?.abort();
        };
    }, []);

    // Making the API call with cancellation support
    const sendRequest = async ({
                                   url,
                                   method,
                                   data,
                                   params = {},
                                   callBack,
                               }: IRequest) => {
        setLoading(true);
        controller.abort();
        controller = new AbortController();
        try {
            const result = await axiosInstance({
                url,
                method,
                data: method.toLowerCase() === "get" ? undefined : data, // Only include data for non-GET requests
                params: method.toLowerCase() === "get" ? data : params, // For GET requests, use data as query params
                //cancelToken: axios.CancelToken.source().token,
                signal: controller.signal,
            });
            // added callback to update initiator immediately
            callBack && callBack(result.data)
            setResponse(result.data);
        } catch (error: any) {
            // reset response :: to be revised
            setResponse(undefined);
            if (axios.isCancel(error)) {
                console.log("Request cancelled: ", error.message);
            } else {
                // added callback to update initiator immediately
                callBack && callBack(error.response.data)
                setError(error.response.data as IError);
            }
        } finally {
            setLoading(false);
        }
    };

    const clearError = () => {
        setError(null);
    };

    // Expose the state and the fetchData function
    return {response, error, loading, sendRequest, clearError};
};

export default useAxios;
