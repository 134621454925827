import React, {useContext} from 'react'
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import {KeyIcon, UserEmailIcon} from "../../assets/icons";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {UserType} from "../../types/ProfileUser";

interface IProps {
    onCancel: () => void;
    openForgotPswdModal: () => void;
    openRegisterModal?: () => void;
    isApplying?: boolean;
}

interface IFormData {
    username: string;
    password: string;
}

const schema = yup
    .object({
        username: yup.string().email('Invalid email').required("Email is required"),
        password: yup.string().required("Password is required")
    })
    .required();

const Login = ({onCancel, openForgotPswdModal, isApplying, openRegisterModal}: IProps) => {

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const navigate = useNavigate();

    const auth = useContext(AuthContext);

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                username: data.username,
                password: data.password,
            };
            await sendRequest({
                url: "/authentication",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    };

    if (response?.data) {
        if (response.data.user.user_type === UserType.ADMIN) {
            auth.login(response.data.user, response.data.token);
            navigate("/admin/dashboard", {replace: true});
        } else {
            auth.login(response.data.user, response.data.token);
            navigate("/candidate/profile", {replace: true});
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Email:
                    </label>
                    <div className="mt-3">
                        <div className='flex border-2 rounded items-center justify-center'>
                            <img className='ml-3' src={UserEmailIcon} alt="user email icon"/>
                            <input
                                {...register("username")}
                                type="text"
                                name="username"
                                id="username"
                                placeholder='Enter your email address'
                                className={`pl-2 block w-full ${errors.username && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs mt-1">{errors.username?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="password"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Password:
                    </label>
                    <div className="mt-3">
                        <div className='flex border-2 rounded items-center justify-center'>
                            <img className='ml-3' src={KeyIcon} alt=" password key icon"/>
                            <input
                                {...register("password")}
                                type="password"
                                name="password"
                                placeholder='Enter your password'
                                id="password"
                                className={`pl-2 block w-full ${errors.password && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <div className='flex items-center justify-between mt-1'>
                            <p className="text-red-500 text-xs">{errors.password?.message}</p>
                            {isApplying && (
                                <button
                                    onClick={openForgotPswdModal}
                                    type='button'
                                    className='text-xs text-sidebarBG hover:underline hover:decoration-dropdownLabelBg hover:decoration-dotted hover:decoration-2'
                                >
                                    Forgot your password?
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 mb-3 flex items-center justify-between space-x-5">

                    <Button type="submit" width="w-1/2" btnText="Login" loading={false}/>

                    {isApplying ? (
                        <button
                            onClick={openRegisterModal}
                            type='button'
                            className={`text-sidebarBG w-1/2 font-bold px-3 py-1.5 rounded border border-sidebarBG text-xs  transition-colors duration-200`}
                        >
                            Create Account
                        </button>
                    ) : (
                        <button
                            onClick={openForgotPswdModal}
                            type='button'
                            className='text-sidebarBG hover:underline hover:decoration-dropdownLabelBg hover:decoration-dotted hover:decoration-2'
                        >
                            Forgot your password?
                        </button>
                    )}
                </div>
            </form>

            {error && <ErrorModal error={error.message} onClose={clearError}/>}
            {loading && <FullPageLoader/>}

        </>
    )
}

export default Login