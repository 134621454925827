import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {JobSearchIcon} from "../../assets/icons";
import useAxios from "../../hooks/useAxios";
import FullPageLoader from "../../shared/components/FullPageLoader";
import ErrorModal from "../../shared/components/ErrorModal";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {IPublishedJob} from "../../types/PublishedJob";
import {RequisitionType} from "../../shared/constants/JobRequisition";
import moment from "moment";

const FeaturedJobs = () => {
    const {response, loading, error, sendRequest, clearError} = useAxios();

    const navigate = useNavigate();

    const fetchPublishedJobs = async () => {
        try {
            await sendRequest({
                url: "/public/publishedJob",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        fetchPublishedJobs();
    }, []);

    return (
        <>
            {/* Featured Positions */}
            <div className="bg-cardBG">
                <div className="p-6 sm:p-8 lg:p-16">
                    {/* Header Section */}
                    <div className="flex flex-col lg:flex-row items-center justify-between text-center lg:text-left">
                        <div className="mb-4 lg:mb-0">
                            <h3 className="text-primary text-xl font-bold">
                                Current Job Vacancies
                            </h3>
                            <p className="text-primary text-sm">
                                You find them everywhere, but nothing more special than at BNR
                            </p>
                        </div>
                        <button className="bg-primary text-textWhite font-bold px-4 py-2 rounded text-xs sm:text-sm">
                            View All Vacancies
                        </button>
                    </div>

                    {/* Job Cards */}
                    <div className="mt-10">
                        {response?.data?.length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {response?.data?.map((job: IPublishedJob) => (
                                    <div
                                        key={job.id}
                                        className="border-2 p-5 bg-primaryBG border-transparent rounded-md"
                                    >
                                        {/* Job Type */}
                                        <div className="flex items-center justify-between">
                                            <div
                                                className="bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs sm:text-xs">
                                                Featured
                                            </div>
                                            <div
                                                className="bg-badgeBG text-primary font-bold px-3 py-1 rounded text-xs sm:text-xs">
                                                {job.requisition_type === RequisitionType.CONTRACT
                                                    ? "CONTRACT"
                                                    : job.requisition_type === RequisitionType.PERMANENT
                                                        ? "PERMANENT"
                                                        : job.requisition_type === RequisitionType.INTERNSHIP
                                                            ? "INTERNSHIP"
                                                            : job.requisition_type === RequisitionType.REGULAR
                                                                ? "REGULAR"
                                                                : job.requisition_type === RequisitionType.TEMPORARY
                                                                    ? "TEMPORARY"
                                                                    : job.requisition_type === RequisitionType.LIMITED_TERM
                                                                        ? "LIMITED TERM"
                                                                        : " "}
                                            </div>
                                        </div>

                                        {/* Job Details */}
                                        <div className="flex flex-col items-center text-center">
                                            <img
                                                className="w-12 sm:w-16 mb-4"
                                                src={JobSearchIcon}
                                                alt="Job Icon"
                                            />
                                            <h3 className="text-primary font-semibold text-lg mt-3 sm:text-xl">
                                                {job.title}
                                            </h3>
                                            <div className="mt-3 flex items-center justify-center space-x-2">
                                                <h3 className="text-gray-700 font-semibold underline text-xs sm:text-sm">
                                                    Deadline:
                                                </h3>
                                                <p className="text-gray-500 text-xs sm:text-sm">
                                                    {moment(job.application_deadline).format("llll")}
                                                </p>
                                            </div>
                                            <div className="mt-5 mb-2">
                                                <button
                                                    onClick={(e) =>
                                                        navigate("/job-description", {state: job})
                                                    }
                                                    className="bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs sm:text-sm"
                                                >
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            // Center the "No Record Found" component
                            <div className="flex items-center justify-center h-96">
                                <NoRecordFound type="JOB"/>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
};

export default FeaturedJobs;
