import { PageLoadingSpinner } from "../../assets/icons";

const FullPageLoader = () => {
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="flex justify-center items-center h-screen z-10">
                    <img src={PageLoadingSpinner} alt="loader" />
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default FullPageLoader;
