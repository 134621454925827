import {useState, useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";

let logoutTimer: any;

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [tokenExpirationDate, setTokenExpirationDate] = useState<any>(null);
    const [userId, setUserId] = useState(null);

    const navigate = useNavigate();

    const login = useCallback((uid: any, token: any, expirationDate: any) => {
        setToken(token);
        setUserId(uid);
        const tokenExpirationTime =
            expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
        setTokenExpirationDate(tokenExpirationTime);
        localStorage.setItem(
            "userData",
            JSON.stringify({
                userId: uid,
                token: token,
                expiration: tokenExpirationTime.toISOString(),
            }),
        );
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        localStorage.removeItem("userData");
        navigate("/", {replace: true});
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime =
                tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("userData") as string);
        if (
            storedData &&
            storedData.token &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(
                storedData.userId,
                storedData.token,
                new Date(storedData.expiration),
            );
        }
    }, [login]);

    return {token, login, logout, userId};
};
