import React, {useEffect, useState} from "react";
import {EditIcon, SkillsIcon} from "../../assets/icons";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateKeyCompetency from "./CreateKeyCompetency";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import useAxios from "../../hooks/useAxios";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {IKeyCompetency} from "../../types/KeyCompetency";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import EditKeyCompetency from "./EditKeyCompetency";
import PageButton from "../../shared/components/PageButton";

const ContainerHeight = 400;

const KeyCompetency = () => {
    const [showAddKeyCompetency, setShowAddKeyCompetency] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [data, setData] = useState<IKeyCompetency[]>([]);
    const [editKeyCompetency, setEditKeyCompetency] = useState(false);
    const [keyCompetencyDetails, setKeyCompetencyDetails] = useState<IKeyCompetency>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const getKeyCompetencyHandler = async () => {
        try {
            await sendRequest({
                url: "/settings/keyCompetency",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getKeyCompetencyHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getKeyCompetencyHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} qualifications loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getKeyCompetencyHandler();
        }
    };


    return (
        <>
            <PageTitle title='Key Competency' dropdown={
                <PageButton title='Add Key Competency' onClickHandler={() => {
                    setShowAddKeyCompetency(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                            onScroll={onScroll}
                        >
                            {(item: IKeyCompetency) => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={SkillsIcon}/>}
                                        title={
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                                                {item.name}
                                            </span>
                                        }
                                        description={
                                            <span className="font-Montserrat text-gray-500 text-xs">
                                                {item.description ? item.description : "No description available"}
                                            </span>
                                        }
                                    />
                                    <div>
                                        <Actions
                                            items={[
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setKeyCompetencyDetails(item)
                                                        setEditKeyCompetency(true)
                                                    }}
                                                    icon={EditIcon}
                                                    label="Edit Key Competency"
                                                    color="gray-500"
                                                />,
                                            ]}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddKeyCompetency && (
                <Modal
                    title="Add New Key Competency"
                    content={<CreateKeyCompetency onCancel={() => {
                        setShowAddKeyCompetency(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddKeyCompetency}
                    isHidden={() => setShowAddKeyCompetency(false)}
                    size="medium"
                />
            )}
            {editKeyCompetency && keyCompetencyDetails && (
                <Modal
                    title="Edit Key Competency"
                    content={<EditKeyCompetency keyCompetencyDetails={keyCompetencyDetails} onCancel={() => {
                        setEditKeyCompetency(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={editKeyCompetency}
                    isHidden={() => {
                        setKeyCompetencyDetails(undefined)
                        setEditKeyCompetency(false)
                    }}
                    size="medium"
                />)}
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default KeyCompetency;