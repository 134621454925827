import React from "react";
import {IAllowance} from "../../types/allowance";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";

interface IProps {
    onCancel: () => void;
    allowanceDetails: IAllowance | undefined;
}

interface IFormData {
    allowance_name: string;
    amount: number;
    description: string;
}

const schema = yup
    .object({
        allowance_name: yup.string().required("Benefit name is required"),
        amount: yup.number().required("Amount are required"),
        description: yup.string().required("Description is required"),
    })
    .required();

const EditAllowance = ({onCancel, allowanceDetails}: IProps) => {
    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                allowance_name: data.allowance_name,
                amount: data.amount,
                description: data.description,
            };
            await sendRequest({
                url: `/settings/allowance/${allowanceDetails?.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    };

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="allowance_name"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Benefit name:
                    </label>
                    <div className="mt-3">
                        <input
                            {...register("allowance_name")}
                            type="text"
                            defaultValue={allowanceDetails?.allowance_name}
                            name="allowance_name"
                            id="allowance_name"
                            className={`pl-2 block w-full rounded ${errors.allowance_name && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.allowance_name?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="Amount"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Benefit amount:
                    </label>
                    <div className="mt-3">
                        <input
                            {...register("amount")}
                            type="text"
                            defaultValue={allowanceDetails?.amount}
                            name="amount"
                            id="amount"
                            className={`pl-2 block w-full rounded ${errors.amount && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.amount?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Description:
                    </label>
                    <div className="mt-2">
            <textarea
                {...register("description")}
                id="description"
                name="description"
                rows={3}
                className={`pl-2 block w-full ${errors.description && "border-red-500"} rounded-md border-2 py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                defaultValue={allowanceDetails?.description}
            />
                        <p className="text-red-500 text-xs">{errors.description?.message}</p>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Changes" loading={loading}/>
                </div>
            </form>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
        </>
    );
};

export default EditAllowance;