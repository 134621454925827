import React, {useEffect, useState} from "react";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateNewUser from "./CreateNewUser";
import PageButton from "../../shared/components/PageButton";
import DataTable, {TableColumn} from "react-data-table-component";
import moment from "moment";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {customTableStyles} from "../../shared/css/customTableStyles";
import NoRecordFound from "../../shared/components/NoRecordFound";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import {ApproveIcon, AssignRole, RemoveRole, ViewIcon} from "../../assets/icons";
import {IUsers} from "../../types/user";
import {Status, UserAction} from "../../shared/constants/status";
import AssignRoles from "./AssignRoles";
import RolesAssignedToUser from "./RolesAssignedToUser";

const Users = () => {

    const [showAddNewUser, setShowAddNewUser] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [showAssignRole, setShowAssignRole] = useState(false);
    const [userDetails, setUserDetails] = useState<IUsers>();
    const [viewRoles, setViewRoles] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: userStatusRes,
        error: userStatusError,
        loading: userStatusLoading,
        sendRequest: sendUserStatusReq,
        clearError: clearUserStatusError
    } = useAxios();

    const columns: TableColumn<IUsers>[] = [
        {
            name: "Created On",
            selector: (row) => moment(new Date(row.created_at)).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "Full name",
            selector: (row) =>
                (
                    <span className="text-gray-500">
            {" "}
                        {row.names}{" "}
          </span>
                ) as unknown as string,
            wrap: true,
        },
        {
            name: "Email",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.email}</span>
                ) as unknown as string,
        },
        {
            name: "Department",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.organization_structure}</span>
                ) as unknown as string,
            minWidth: "250px",
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) =>
                (
                    <>
                        {row.status === Status.ACTIVE ? (
                            <div className='flex items-center justify-center'>
                                <div className='bg-approvedBG rounded-full w-2 h-2 mr-2'></div>
                                <span
                                    className="text-gray-500">Active</span>
                            </div>
                        ) : (<div className='flex items-center justify-center'>
                            <div className='bg-red-500 rounded-full w-2 h-2 mr-2'></div>
                            <span
                                className="text-gray-500">Disabled</span>
                        </div>)}
                    </>
                ) as unknown as string,
        },
        {
            name: "Actions",
            selector: (row) =>
                (
                    <Actions
                        items={[
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setUserDetails(row);
                                    setViewRoles(true);
                                }}
                                icon={ViewIcon}
                                label="View Roles"
                                color="gray-500"
                            />,
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setUserDetails(row);
                                    setShowAssignRole(true);
                                }}
                                icon={AssignRole}
                                label="Assign Role"
                                color="gray-500"
                            />,
                            row.status === Status.ACTIVE && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={async () => {
                                        await userStatusHandler(row.id, UserAction.disable)
                                    }}
                                    icon={RemoveRole}
                                    label="Disable User"
                                    color="gray-500"
                                />),
                            row.status === Status.INACTIVE && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={async () => {
                                        await userStatusHandler(row.id, UserAction.approve)
                                    }}
                                    icon={ApproveIcon}
                                    label="Approve User"
                                    color="gray-500"
                                />)
                        ]}
                    />
                ) as unknown as string,
        },
    ];

    const userStatusHandler = async (id: string, action: string) => {
        if (action === UserAction.disable) {
            try {
                await sendUserStatusReq({
                    url: `/user/${id}/role/${Status.INACTIVE}`,
                    method: "PUT",
                    data: {},
                });
            } catch (err) {
            }
        } else if (action === UserAction.approve) {
            try {
                await sendUserStatusReq({
                    url: `/user/${id}/role/${Status.ACTIVE}`,
                    method: "PUT",
                    data: {},
                });
            } catch (err) {
            }
        } else {
            return null
        }

    }

    useEffect(() => {
        if (userStatusRes?.data) {
            setFetchAgain(true);
        }
    }, [userStatusRes?.data]);

    const getUsersHandler = async (page: number) => {
        try {
            await sendRequest({
                url: `/user?pageNumber=${page}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const pageNumberChangeHandler = (page: number) => {
        setPageNumber(page)
    }

    useEffect(() => {
        getUsersHandler(pageNumber);
    }, [pageNumber]);

    useEffect(() => {
        if (fetchAgain) {
            getUsersHandler(pageNumber);
            setFetchAgain(false);
        }
    }, [fetchAgain, pageNumber]);

    return (
        <>
            <PageTitle title='Users' dropdown={
                <PageButton title='Create New User' onClickHandler={() => setShowAddNewUser(true)}/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={response?.data}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        paginationTotalRows={response?.pagination?.total}
                        onChangePage={pageNumberChangeHandler}
                        customStyles={customTableStyles}
                    />
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddNewUser && (
                <Modal
                    title="Create New User"
                    content={<CreateNewUser onCancel={() => {
                        setShowAddNewUser(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddNewUser}
                    isHidden={() => setShowAddNewUser(false)}
                    size='medium'
                />
            )}
            {showAssignRole && userDetails && (
                <Modal
                    title={`Assign Role to ${userDetails.names}`}
                    content={<AssignRoles userDetails={userDetails} onCancel={() => {
                        setShowAssignRole(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAssignRole}
                    isHidden={() => {
                        setUserDetails(undefined)
                        setShowAssignRole(false)
                    }}
                    size="medium"
                />
            )}
            {viewRoles && userDetails && (
                <Modal
                    title={`Roles Assigned to ${userDetails.names}`}
                    content={<RolesAssignedToUser userDetails={userDetails}/>}
                    isShown={viewRoles}
                    isHidden={() => {
                        setUserDetails(undefined)
                        setViewRoles(false)
                    }}
                    size="large"
                />
            )}
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : userStatusError ?
                <ErrorModal error={userStatusError.error} onClose={clearUserStatusError}/> : " "}
            {(loading || userStatusLoading) && <FullPageLoader/>}
        </>
    );
}

export default Users;