import React, {useEffect, useState} from "react";
import {AddPrimaryIcon, EditIcon, EducationIcon, RemoveGrayIcon} from "../../assets/icons";
import Modal from "../../shared/components/Modal";
import AddNewEducation from "./addNewEducation";
import useAxios from "../../hooks/useAxios";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import NoRecordFound from "../../shared/components/NoRecordFound";
import FullPageLoader from "../../shared/components/FullPageLoader";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import ErrorModal from "../../shared/components/ErrorModal";
import {ICandidateEducation} from "./types/candidateEducation";
import EditEducation from "./EditEducation";


const Education = () => {

    const [fetchAgain, setFetchAgain] = useState<boolean>(false);
    const [openEducationModel, setOpenEducationModel] = useState<boolean>(false);
    const [educationDetails, setEducationDetails] = useState<ICandidateEducation>();
    const [showEditEducation, setShowEditEducation] = useState<boolean>(false);

    const userData: IProfileUser = getCurrentUserData();

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError
    } = useAxios();

    const {
        response: educationRes,
        error: educationError,
        loading: educationLoading,
        sendRequest: sendEducationReq,
        clearError: clearEducationError
    } = useAxios();

    const handleDelete = async (id: string) => {
        try {
            await sendEducationReq({
                url: `/education/remove/${id}`,
                method: "PUT",
            });
            setFetchAgain(true);
        } catch (err) {
        }
    }

    const fetchEducation = async () => {
        try {
            await sendRequest({
                url: `/education?candidate=${userData?.userId?.id}`,
                method: "GET",
                params: {candidate: userData?.userId?.id}
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchEducation();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            fetchEducation();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (educationRes?.data) {
            setFetchAgain(true);
        }
    }, [educationRes?.data]);

    return (
        <>
            <div className="flex justify-end">
                <div
                    className="text-sidebarBG flex items-center space-x-2 px-3 py-1.5 rounded border border-sidebarBG  transition-colors duration-200 cursor-pointer w-max"
                    onClick={() => {
                        setOpenEducationModel(true);
                    }}
                >
                    <img src={AddPrimaryIcon} alt="Add Icon" className="w-4 h-4"/>
                    <h3 className="text-xs font-semibold ">Academic Qualification</h3>
                </div>
            </div>
            <div className="overflow-y-auto h-52">
                <ul className=" divide-y divide-gray-300 dark:divide-gray-300">
                    {response?.data?.map((education: ICandidateEducation) => <li className="py-3 sm:py-2">
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <div className="w-10 h-10 flex items-center justify-center mt-2 bg-white rounded-full ">
                                <img className="w-8 h-8" src={EducationIcon} alt="Work Icon"/>
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-sm text-sidebarBG">
                                    {education['qualification']}
                                </p>
                                <p className="text-xs">
                                    {education['country']} | {education['institution']} | {education['year_of_completion']}
                                </p>
                            </div>
                            <div>
                                <Actions
                                    items={[
                                        <DropdownMenuItem
                                            handlerFn={() => {
                                                setEducationDetails(education);
                                                setShowEditEducation(true);
                                            }}
                                            icon={EditIcon}
                                            label="Edit Qualification"
                                            color="gray-500"
                                        />,
                                        <DropdownMenuItem
                                            handlerFn={async () => {
                                                await handleDelete(education.id);
                                            }}
                                            icon={RemoveGrayIcon}
                                            label="Remove Qualification"
                                            color="gray-500"
                                        />
                                    ]}
                                />
                            </div>
                        </div>
                    </li>)}
                </ul>

                {response?.data.length <= 0 && <div className="flex items-center justify-center w-full">
                    <div className="w-36"><NoRecordFound textSize="medium"/></div>
                </div>}
            </div>
            {openEducationModel && (
                <Modal
                    title="Academic Qualification"
                    content={<AddNewEducation onCancel={() => {
                        setOpenEducationModel(false);
                        setFetchAgain(true);
                    }}/>}
                    isShown={openEducationModel}
                    isHidden={() => setOpenEducationModel(false)}
                    size='medium'
                />
            )}

            {
                showEditEducation && educationDetails && (
                    <Modal
                        title="Edit Academic Qualification"
                        content={<EditEducation educationDetails={educationDetails} onCancel={() => {
                            setEducationDetails(undefined);
                            setShowEditEducation(false);
                            setFetchAgain(true);
                        }}/>}
                        isShown={showEditEducation}
                        isHidden={() => {
                            setEducationDetails(undefined);
                            setShowEditEducation(false)
                        }}
                        size='medium'
                    />
                )
            }

            {(loading || educationLoading) && <FullPageLoader/>}
            {error ? <ErrorModal error={error.message} onClose={clearError}/> : educationError ?
                <ErrorModal error={educationError.message} onClose={clearEducationError}/> : ''}
        </>
    )
}

export default Education;