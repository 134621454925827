import React, {useState} from "react";
import {ICandidateExperience} from "./types/candidateExperience";
import {Checkbox, Select} from "antd";
import {jobTypeOptions} from "../../shared/constants/SelectOptions";
import {Editor} from "react-draft-wysiwyg";
import Button from "../../shared/components/Button";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {RawDraftContentState} from "draft-js";
import ErrorModal from "../../shared/components/ErrorModal";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import moment from "moment";

interface IProps {
    onCancel: () => void;
    candidateExperience: ICandidateExperience;
}

interface IFormData {
    job_title: string;
    location?: string;
    employer_name: string;
    start_date: string;
    end_date: string;
}

const schema = yup
    .object({
        employer_name: yup.string().required("Employer name is required"),
        job_title: yup.string().required("Job Title is required"),
        start_date: yup.string().required("Start date is required"),
        end_date: yup.string().required("End date is required"),
        location: yup.string().optional(),
    })
    .required();

const EditExperience = ({onCancel, candidateExperience}: IProps) => {

    const [employment_type, setEmployment_type] = useState<number>(candidateExperience.employment_type);
    const [currentWorkHere, setCurrentWorkHere] = useState<boolean>(candidateExperience.is_current_work_here);
    const [editorState, setEditorState] = useState<RawDraftContentState>(JSON.parse(
        candidateExperience?.responsibilities,
    ));

    const userData: IProfileUser = getCurrentUserData();

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError,
    } = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                job_title: data.job_title,
                employer_name: data.employer_name,
                responsibilities: JSON.stringify(editorState),
                employment_type: employment_type,
                location: data.location,
                start_date: data.start_date,
                end_date: data.end_date,
                is_current_work_here: currentWorkHere,
                candidate_id: userData.userId.id
            };
            console.log("Payload being sent:", payload); // Debug here
            await sendRequest({
                url: `/experience/${candidateExperience.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    }

    const handleEmploymentTypeChange = (value: number) => {
        setEmployment_type(value);
    }

    const onEditorStateChange = (editorContent: RawDraftContentState) => {
        setEditorState(editorContent);
    };

    if (response?.data) {
        onCancel()
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-2 grid-cols-2">
                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            Job Title:
                        </label>
                        <div className="mt-1">
                            <div className='flex items-center justify-center space-x-5'>
                                <input
                                    {...register("job_title")}
                                    defaultValue={candidateExperience.job_title}
                                    type="text"
                                    placeholder='Type here'
                                    name="job_title"
                                    id="job_title"
                                    className={`pl-2 block w-full rounded ${errors.job_title && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                            </div>
                            <p className="text-red-500 text-xs">{errors.job_title?.message}</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            Employment Type:
                        </label>
                        <div className="mt-1">
                            <Select
                                defaultValue={candidateExperience.employment_type}
                                rootClassName="font-Montserrat"
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleEmploymentTypeChange}
                                options={jobTypeOptions?.length ? jobTypeOptions : []}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-32">
                    <label
                        htmlFor="responsibilities"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Responsibilities:
                    </label>
                    <div className="mt-1">
                        <Editor
                            defaultContentState={JSON.parse(
                                candidateExperience?.responsibilities,
                            )}
                            onContentStateChange={onEditorStateChange}
                            editorClassName="bg-notesBG rounded px-2 py-1"
                            wrapperClassName="h-32"
                            placeholder="Type job responsibilities here..."

                        />
                    </div>
                </div>
                <div className="grid gap-2 grid-cols-2">
                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            Company Name:
                        </label>
                        <div className="mt-1">
                            <div className='flex items-center justify-center space-x-5'>
                                <input
                                    {...register("employer_name")}
                                    type="text"
                                    defaultValue={candidateExperience.employer_name}
                                    placeholder='Type here'
                                    name="employer_name"
                                    id="employer_name"
                                    className={`pl-2 block w-full rounded ${errors.employer_name && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                            </div>
                            <p className="text-red-500 text-xs">{errors.employer_name?.message}</p>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            Location
                        </label>
                        <div className="mt-1">
                            <div className='flex items-center justify-center space-x-5'>
                                <input
                                    {...register("location")}
                                    type="text"
                                    defaultValue={candidateExperience.location}
                                    placeholder='Type here'
                                    name="location"
                                    id="location"
                                    className={`pl-2 block w-full rounded ${errors.location && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                            </div>
                            {/* <p className="text-red-500 text-xs">{errors.employer_phone_number?.message}</p> */}
                        </div>
                    </div>
                </div>
                <div className="grid gap-2 grid-cols-2">
                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            Start Date:
                        </label>
                        <div className="mt-1">
                            <div className='flex items-center justify-center space-x-5'>
                                <input
                                    {...register("start_date")}
                                    type="date"
                                    defaultValue={moment(candidateExperience.start_date).format("YYYY-MM-DD")}
                                    name="start_date"
                                    id="start_date"
                                    className={`pl-2 block w-full rounded ${errors.start_date && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                            </div>
                            <p className="text-red-500 text-xs">{errors.start_date?.message}</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="email"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            End Date:
                        </label>
                        <div className="mt-1">
                            <div className='flex items-center justify-center space-x-5'>
                                <input
                                    {...register("end_date")}
                                    type="date"
                                    name="end_date"
                                    defaultValue={moment(candidateExperience.end_date).format("YYYY-MM-DD")}
                                    id="end_date"
                                    className={`pl-2 block w-full rounded ${errors.end_date && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                            </div>
                            <p className="text-red-500 text-xs">{errors.end_date?.message}</p>
                            <div className="flex mt-2"><Checkbox checked={currentWorkHere}
                                                                 onChange={(e) => {
                                                                     setCurrentWorkHere(e.target.checked);
                                                                 }} className="mr-1"/><p className="mt-1 text-xs">I
                                currently
                                work here</p></div>
                        </div>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Experience" loading={loading}
                    />
                </div>
            </form>
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : ""}
        </>
    )
}

export default EditExperience;