import React from 'react';
import {IRequisition} from "../../types/JobApplication";
import {ClockCircleOutlined, FormOutlined, AuditOutlined} from '@ant-design/icons';
import {Timeline} from 'antd';
import {DeadlineIcon, DepartmentIcon, EmployeseIcon, RequisitionTypeIcon} from "../../assets/icons";
import moment from "moment";
import {IReceivedApplications} from "../job-requisition/types/ReceivedApplications";

interface IProps {
    applicationDetails: IReceivedApplications | undefined
}

const JobApplicationDetails = ({applicationDetails}: IProps) => {
    return (<>
        <div className="bg-notesBG rounded-lg p-5 mt-2">
            <div className="mt-2 grid grid-cols-4 gap-4">

                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img className="w-10 h-10" src={EmployeseIcon}
                             alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 ms-4">
                        <p className="text-xs text-gray-700 mb-1">
                            Number of openings
                        </p>
                        <p className="text-xs capitalize font-bold">
                            {applicationDetails?.requisition.number_of_openings}
                        </p>
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img className="w-10 h-10 rounded-full" src={DeadlineIcon}
                             alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 ms-4">
                        <p className="text-xs text-gray-700 mb-1">
                            Deadline
                        </p>
                        <p className="text-xs capitalize font-bold">
                            {moment(new Date(`${applicationDetails?.requisition.application_deadline}`)).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>


                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img className="w-10 h-10 rounded-full" src={DepartmentIcon}
                             alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 ms-4">
                        <p className="text-xs text-gray-700 mb-1">
                            Department
                        </p>
                        <p className="text-xs capitalize font-bold">
                            ...
                        </p>
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img className="w-10 h-10" src={RequisitionTypeIcon}
                             alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0 ms-4">
                        <p className="text-xs text-gray-700 mb-1">
                            Requisition Type
                        </p>
                        <p className="text-xs capitalize font-bold">
                            ...
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-10">
            <Timeline
                mode="alternate"
                items={[
                    {
                        children: `Submitted Job Application ${moment(new Date(`${applicationDetails?.created_at}`)).format('DD-MM-YYYY')}`,
                    },
                    {
                        children: `Your job application will be reviewed soon`,
                        color: 'green',
                    },
                    {
                        dot: <FormOutlined style={{fontSize: '16px'}}/>,
                        children: `Oral Exam date will be informed soon`,
                    },
                    {
                        color: 'red',
                        children: 'Practical Exam will be communicated soon',
                    },
                    {
                        dot: <AuditOutlined style={{fontSize: '16px'}}/>,
                        children: 'Offer letter wil be sent via email',
                    },
                ]}
            />
        </div>

    </>);
}

export default JobApplicationDetails;