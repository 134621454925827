export interface IProfileUser {
    userId: UserID;
    token: string;
    expiration: string;
}

export enum UserType {
    ADMIN = 0,
    CANDIDATE
}

export enum Gender {
    MALE = 1,
    FEMALE
}

interface Addresses {
    cell: string;
    sector: string;
    village: string;
    district: string;
}

export interface UserID {
    id: string;
    username: string;
    names: string;
    email: string;
    status: number;
    created_at: string;
    updated_at: string;
    roles: string[];
    privileges: string[];
    user_type: UserType;
    profile_photo?: string;
    gender?: Gender;
    nid?: string;
    first_name?: string;
    last_name?: string;
    father_name?: string;
    mother_name?: string;
    place_of_birth?: string;
    date_of_birth?: string;
    phone_number?: string;
    addresses: Addresses;
    bio?: string; // Add bio if it's part of the API response
    skills?: string[];
}
