import React from "react";
import {IRoles} from "../../types/roles";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import useAxios from "../../hooks/useAxios";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import {IJobLevel} from "../../types/JobLevel";

interface IProps {
    onCancel: () => void;
    jobLevelDetails: IJobLevel | undefined;
}

interface IFormData {
    description: string;
    name: string;
}

const schema = yup
    .object({
        name: yup.string().required("Name is required"),
        description: yup.string().required("Description is required"),
    })
    .required();

const EditJobLevel = ({onCancel, jobLevelDetails}: IProps) => {

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                name: data.name,
                description: data.description,
            };
            await sendRequest({
                url: `/settings/jobField/${jobLevelDetails?.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    };

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="name"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Name:
                    </label>
                    <div className="mt-3">
                        <input
                            {...register("name")}
                            type="text"
                            defaultValue={jobLevelDetails?.name}
                            name="name"
                            id="title"
                            className={`pl-2 block w-full rounded ${errors.name && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.name?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Description:
                    </label>
                    <div className="mt-2">
            <textarea
                {...register("description")}
                id="description"
                name="description"
                rows={3}
                className={`pl-2 block w-full ${errors.description && "border-red-500"} rounded-md border-2 py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                defaultValue={jobLevelDetails?.description}
            />
                        <p className="text-red-500 text-xs">{errors.description?.message}</p>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Changes" loading={loading}/>
                </div>
            </form>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
        </>
    )
}

export default EditJobLevel;