import type {SelectProps} from "antd";

export const requisitionTypeOptions: SelectProps["options"] = [
    {
        label: 'PERMANENT',
        value: 0,
    },
    {
        label: 'CONTRACT',
        value: 1,
    },
    {
        label: 'INTERNSHIP',
        value: 2,
    },
    {
        label: 'REGULAR ',
        value: 3,
    },
    {
        label: 'TEMPORARY',
        value: 4,
    },
    {
        label: 'LIMITED TERM',
        value: 5,
    }
]

export const justificationOptions: SelectProps["options"] = [
    {
        label: 'NEW POSITION ',
        value: 0,
    },
    {
        label: 'REPLACEMENT',
        value: 1,
    },
    {
        label: 'MODIFICATION',
        value: 2,
    },
    {
        label: 'EXTENSION',
        value: 3,
    }
]

export const employmentStatusOptions: SelectProps["options"] = [
    {
        label: 'REGULAR ',
        value: 0,
    },
    {
        label: 'CONTRACTUAL',
        value: 1,
    },
    {
        label: 'TEMPORARY',
        value: 2,
    },
    {
        label: 'LIMITED TERM',
        value: 3,
    }
]

export const payBasisOptions: SelectProps["options"] = [
    {
        label: 'MONTHLY ',
        value: 0,
    },
    {
        label: 'YEARLY',
        value: 1,
    }

]

export const hiringModelOptions: SelectProps["options"] = [
    {
        label: 'OPEN',
        value: 0,
    },
    {
        label: 'COMPETITION',
        value: 1,
    },
    {
        label: 'INTERNAL RECRUITMENT',
        value: 2,
    }
]

export const questionnaireTypeOptions: SelectProps["options"] = [
    {
        label: 'SHORTLISTING QUESTIONS',
        value: 0,
    },
    {
        label: 'INTERVIEW QUESTIONS',
        value: 1,
    },
]

export const questionTypeOptions: SelectProps["options"] = [

    {
        label: 'SINGLE CHOICE',
        value: 2,
    },
    {
        label: 'MULTIPLE CHOICE',
        value: 1,
    },

    // {
    //     label: 'SHORT ANSWER',
    //     value: 3,
    // },
    // {
    //     label: 'PARAGRAPH',
    //     value: 4,
    // }
]


export const degreeOptions: { label: string, value: number }[] = [
    {
        label: 'GENERAL PRIMARY',
        value: 0,
    },
    {
        label: 'O LEVEL',
        value: 1,
    },
    {
        label: 'DIPLOMA A2',
        value: 2,
    },
    {
        label: 'DIPLOMA A1',
        value: 3,
    },
    {
        label: 'ADVANCED DIPLOMA A1',
        value: 4,
    },
    {
        label: 'BACHELORS A0',
        value: 5,
    },
    {
        label: 'POST GRADUATE DIPLOMA',
        value: 6,
    },
    {
        label: 'MASTERS',
        value: 7,
    },
    {
        label: 'PHD',
        value: 8,
    },
    {
        label: 'DRIVING LICENSE',
        value: 9,
    }
]


export const languageOptions: SelectProps["options"] = [
    {
        label: 'ENGLISH',
        value: 0,
    },
    {
        label: 'FRENCH',
        value: 1,
    },
    {
        label: 'KINYARWANDA',
        value: 2,
    },
]

export const languageLevelOptions: SelectProps["options"] = [
    {
        label: 'EXCELLENT',
        value: 0,
    },
    {
        label: 'VERY GOOD',
        value: 1,
    },
    {
        label: 'GOOD',
        value: 2,
    },
]


export const disabilityLevelOptions: SelectProps["options"] = [
    {
        label: 'Between 90 to 100%',
        value: 0,
    },
    {
        label: 'Between 70 to 89%',
        value: 1,
    },
    {
        label: 'Between 50 to 69%',
        value: 2,
    },
    {
        label: 'Between 30 to 49%',
        value: 3,
    },
    {
        label: 'Below 30%',
        value: 4,
    },
]

export const fileTypeOptions: SelectProps["options"] = [
    {
        label: 'NATIONAL ID',
        value: 0,
    },
    {
        label: 'RESUME',
        value: 1,
    },
    {
        label: 'PASSPORT',
        value: 2,
    },
    {
        label: 'COVER LETTER',
        value: 3,
    },
    {
        label: 'PROFESSIONAL QUALIFICATIONS',
        value: 4,
    },
    {
        label: 'CRIMINAL RECORD',
        value: 5,
    },
    {
        label: 'OTHER',
        value: 6,
    }
]

export const jobTypeOptions: SelectProps["options"] = [
    {
        label: 'REMOTE',
        value: 1,
    },
    {
        label: 'HYBRID',
        value: 2,
    },
    {
        label: 'ON-SITE',
        value: 3,
    },
]