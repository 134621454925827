import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IPublishedJob} from "../../types/PublishedJob";
import {message, Select, Upload, UploadProps} from "antd";
import Button from "../../shared/components/Button";
import {AttachmentTypeEnum, maritalStatusOptions} from "./types/constants";
import {PageLoadingSpinner, UploadIcon} from "../../assets/icons";
import {Gender, IProfileUser} from "../../types/ProfileUser";
import useAxios from "../../hooks/useAxios";
import moment from "moment";
import ErrorModal from "../../shared/components/ErrorModal";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

interface IProps {
    onCancel: () => void;
    jobDetails: IPublishedJob;
}

interface IFormData {
    place_of_residence: string;
    email: string;
    phone_number: string;
}

const schema = yup
    .object({
        place_of_residence: yup.string().required('Place of residence is required'),
        email: yup.string().email('Invalid email').required("Email is required"),
        phone_number: yup.string().required("Phone number is required"),
    })
    .required();

const JobApplicationForm = ({jobDetails, onCancel}: IProps) => {

    const [selectedMaritalStatus, setSelectedMaritalStatus] = React.useState<number>();
    const [attachmentType, setAttachmentType] = useState<number>();
    const [fileIDs, setFileIDs] = useState<{
        file: string,
        fileId: string,
        fileName: string,
        attachmentType: number
    }[]>();

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string,
    );

    const navigate = useNavigate();

    const {response, error, loading, sendRequest, clearError} = useAxios()

    const {
        error: deleteFileError,
        sendRequest: sendFileDeleteReq,
        clearError: clearFileDeleteError
    } = useAxios();

    const {
        response: candidateInfoRes,
        error: candidateInfoError,
        loading: candidateInfoLoading,
        sendRequest: sendCandidateInfoReq,
        clearError: clearCandidateInfoError
    } = useAxios()

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const getCandidatePersonalInfo = async () => {
        try {
            await sendRequest({
                url: `/candidate-profile/${userData.userId.id}?requisition_id=${jobDetails.id}`,
                method: 'GET'
            })
        } catch (err) {
        }
    }

    const onSavingCandidatePersonalInfo = async (data: IFormData) => {
        try {
            await sendCandidateInfoReq({
                url: `/candidate-profile/${userData.userId.id}`,
                method: 'PUT',
                data: {
                    marital_status: selectedMaritalStatus,
                    place_of_residence: data.place_of_residence,
                    email: data.email,
                    requisition_id: jobDetails.id,
                    phone_number: data.phone_number,
                    attachments: fileIDs?.length ? fileIDs.map((item) => ({
                        attachment_id: item.fileId,
                        attachment_type: item.attachmentType,
                        title: item.fileName
                    })) : []
                }
            })
        } catch (err) {
        }
    }

    const handleMaritalStatusChange = (value: number) => {
        setSelectedMaritalStatus(value);
    };

    const fileProps: UploadProps = {
        name: 'file',
        accept: '.pdf, .png, .jpg, .jpeg',
        action: `${process.env.REACT_APP_API_BASE_URL}/attachment/upload`,
        headers: {
            "Authorization": `Bearer ${userData.token}`,
        },
        onChange(info) {
            if (info.file.status === 'done' && info.file.response?.data?.id) {
                setFileIDs(prev => [...(prev || []), {
                    file: info.file.uid,
                    fileId: info.file.response?.data?.id,
                    fileName: info.file.name,
                    attachmentType: attachmentType!
                }]);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        async onRemove(file) {

            const result = fileIDs?.find((item) => item.file === file.uid);

            if (result) {
                try {
                    await sendFileDeleteReq({
                        url: `/attachment/delete/${result.fileId}`,
                        method: "POST",
                    });
                } catch (err) {
                }

                setFileIDs(prev => prev?.filter((item) => item.file !== file.uid));

                message.success('File removed successfully');

            } else {
                message.error('File not found');
            }

        }
    };

    const handleClearError = () => {
        onCancel();
        clearError();
    }

    useEffect(() => {
        getCandidatePersonalInfo();
    }, []);

    if (candidateInfoRes?.data) {
        message.success(`Information saved successfully`);
        navigate('/candidate/questionnaire', {state: jobDetails})
    }

    return (
        <>
            {loading && <img className="mx-auto" src={PageLoadingSpinner} alt="loader"/>}
            {response?.data && (
                <form>
                    <div className="mb-3 flex items-center justify-center space-x-5">
                        <div className='w-1/2'>
                            <label
                                htmlFor="names"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                Full Name:
                            </label>
                            <div className="mt-2">
                                <input
                                    defaultValue={response?.data?.first_name + ' ' + response?.data?.last_name}
                                    type="text"
                                    name="names"
                                    disabled
                                    id="names"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-transparent py-0.5 text-gray-500 sm:text-sm sm:leading-6 focus:outline-none hover:cursor-not-allowed bg-gray-100`}
                                />
                                <p className="text-red-500 text-xs"></p>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <label
                                htmlFor="nid"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                National ID:
                            </label>
                            <div className="mt-2">
                                <input
                                    defaultValue={response?.data?.nid}
                                    disabled
                                    type="text"
                                    name="nid"
                                    id="nid"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-transparent py-0.5 text-gray-500 sm:text-sm sm:leading-6 focus:outline-none hover:cursor-not-allowed bg-gray-100`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 flex items-center justify-center space-x-5">
                        <div className='w-1/2'>
                            <label
                                htmlFor="Gender"
                                className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                            >
                                Gender:
                            </label>
                            <div className="mt-2">
                                <input
                                    defaultValue={response?.data?.gender === Gender.MALE ? 'Male' : response?.data?.gender === Gender.FEMALE ? 'Female' : ''}
                                    disabled
                                    type="text"
                                    name="gender"
                                    id="gender"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-transparent py-0.5 text-gray-500 sm:text-sm sm:leading-6 focus:outline-none hover:cursor-not-allowed bg-gray-100`}
                                />
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <label
                                htmlFor="marital_status"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                Marital Status:
                            </label>
                            <div className="mt-2">
                                <Select
                                    rootClassName="font-Montserrat"
                                    loading={!maritalStatusOptions?.length}
                                    allowClear
                                    style={{width: "100%"}}
                                    placeholder="Please select"
                                    onChange={handleMaritalStatusChange}
                                    options={maritalStatusOptions}
                                />
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <label
                                htmlFor="place_of_residence"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                Place of Residence:
                            </label>
                            <div className="mt-2">
                                <input
                                    {...register("place_of_residence")}
                                    type="text"
                                    name="place_of_residence"
                                    id="place_of_residence"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-gray-300 py-0.5 text-gray-500 ${errors.place_of_residence && "border-red-500"} sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                                <p className="text-red-500 text-xs">{errors.place_of_residence?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 flex items-center justify-center space-x-5">
                        <div className='w-1/2'>
                            <label
                                htmlFor="date_of_birth"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                Date of Birth:
                            </label>
                            <div className="mt-2">
                                <input
                                    defaultValue={moment(response?.data?.date_of_birth).format("LL")}
                                    disabled
                                    type="text"
                                    name="date_of_birth"
                                    id="date_of_birth"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-transparent py-0.5 text-gray-500 sm:text-sm sm:leading-6 focus:outline-none hover:cursor-not-allowed bg-gray-100`}
                                />
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <label
                                htmlFor="email"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                Email:
                            </label>
                            <div className="mt-2">
                                <input
                                    {...register("email")}
                                    defaultValue={response?.data?.email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-gray-300 py-0.5 text-gray-500 ${errors.email && "border-red-500"} sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                                <p className="text-red-500 text-xs">{errors.email?.message}</p>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <label
                                htmlFor="hiring_model"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                            >
                                Phone Number:
                            </label>
                            <div className="mt-2">
                                <input
                                    {...register("phone_number")}
                                    defaultValue={response?.data?.phone_number}
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    placeholder='Type here'
                                    className={`pl-2 block w-full rounded border border-gray-300 py-0.5 text-gray-500 ${errors.phone_number && "border-red-500"} sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                                <p className="text-red-500 text-xs">{errors.phone_number?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 mt-8 ">
                        <div className='mb-3 w-1/2'>
                            <label
                                htmlFor="resume"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-700"
                            >
                                CV / Resume <span className='text-xs text-gray-400'>( pdf / image )</span>
                            </label>
                            <div className="mt-2">
                                <Upload {...fileProps}>
                                    <button
                                        onClick={() => setAttachmentType(AttachmentTypeEnum.RESUME)}
                                        type='button'
                                        className="outline outline-1 outline-offset-0 rounded px-3 py-1 text-xs text-gray-500 flex items-center justify-center space-x-1.5 hover:bg-userBadgeBg hover:text-sidebarBG hover:outline-none">
                                        <img src={UploadIcon} alt="upload icon"/>
                                        <span>Upload CV / Resume</span>
                                    </button>
                                </Upload>
                            </div>
                        </div>
                        <div className='mb-3 w-1/2'>
                            <label
                                htmlFor="national_id"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-700"
                            >
                                National ID <span className='text-xs text-gray-400'>( pdf / image )</span>
                            </label>
                            <div className="mt-2">
                                <Upload {...fileProps}>
                                    <button
                                        onClick={() => setAttachmentType(AttachmentTypeEnum.NID)}
                                        type='button'
                                        className="outline outline-1 outline-offset-0 rounded px-3 py-1 text-xs text-gray-500 flex items-center justify-center space-x-1.5 hover:bg-userBadgeBg hover:text-sidebarBG hover:outline-none">
                                        <img src={UploadIcon} alt="upload icon"/>
                                        <span>Upload National ID</span>
                                    </button>
                                </Upload>
                            </div>
                        </div>
                        <div className='mb-3 w-1/2'>
                            <label
                                htmlFor="cover_letter"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-700"
                            >
                                Degree <span className='text-xs text-gray-400'>( pdf / image )</span>
                            </label>
                            <div className="mt-2">
                                <Upload {...fileProps}>
                                    <button
                                        onClick={() => setAttachmentType(AttachmentTypeEnum.DEGREE)}
                                        type='button'
                                        className="outline outline-1 outline-offset-0 rounded px-3 py-1 text-xs text-gray-500 flex items-center justify-center space-x-1.5 hover:bg-userBadgeBg hover:text-sidebarBG hover:outline-none">
                                        <img src={UploadIcon} alt="upload icon"/>
                                        <span>Upload Degree</span>
                                    </button>
                                </Upload>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <label
                                htmlFor="other_documents"
                                className="block text-sm font-Montserrat font-medium leading-6 text-gray-700"
                            >
                                Other Document <span className='text-xs text-gray-400'>( pdf / image )</span>
                            </label>
                            <div className="mt-2">
                                <Upload {...fileProps}>
                                    <button
                                        onClick={() => setAttachmentType(AttachmentTypeEnum.OTHER)}
                                        type='button'
                                        className="outline outline-1 outline-offset-0 rounded px-3 py-1 text-xs text-gray-500 flex items-center justify-center space-x-1.5 hover:bg-userBadgeBg hover:text-sidebarBG hover:outline-none">
                                        <img src={UploadIcon} alt="upload icon"/>
                                        <span>Upload Other Document</span>
                                    </button>
                                </Upload>
                            </div>
                        </div>
                    </div>
                    <div className="my-5 border-b"></div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                        >
                            Cancel
                        </button>
                        <Button type="button" onButtonClick={handleSubmit(onSavingCandidatePersonalInfo)}
                                btnText="Save & Continue" loading={candidateInfoLoading}
                                isValid={
                                    !selectedMaritalStatus ||
                                    !fileIDs?.some((item) => item.attachmentType === AttachmentTypeEnum.RESUME) ||
                                    !fileIDs?.some((item) => item.attachmentType === AttachmentTypeEnum.NID) ||
                                    !fileIDs?.some((item) => item.attachmentType === AttachmentTypeEnum.DEGREE)
                                }
                        />
                    </div>
                </form>
            )}

            {error ? <ErrorModal error={error.error} onClose={handleClearError}/> : deleteFileError ?
                <ErrorModal error={deleteFileError.error} onClose={clearFileDeleteError}/> : candidateInfoError ?
                    <ErrorModal error={candidateInfoError.error} onClose={clearCandidateInfoError}/> : ''}
        </>
    )
}

export default JobApplicationForm;