import React, {useEffect, useState} from 'react';
import PageButton from "../../shared/components/PageButton";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateJobProfile from "./CreateJobProfile";
import DataTable, {TableColumn} from "react-data-table-component";
import {customTableStyles} from "../../shared/css/customTableStyles";
import NoRecordFound from "../../shared/components/NoRecordFound";
import useAxios from "../../hooks/useAxios";
import moment from "moment/moment";
import {Status} from "../../shared/constants/status";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import {AddIcon, EditIcon, ViewIcon} from "../../assets/icons";
import {IJobProfile} from "../../types/JobProfile";
import ErrorModal from "../../shared/components/ErrorModal";
import EditorView from "../../shared/components/EditorView";
import EditJobProfile from "./EditJobProfile";
import AddKeyCompetency from "./AddKeyCompetency";
import FullPageLoader from "../../shared/components/FullPageLoader";
import AddBenefits from "./AddBenefits";
import {Editor} from "react-draft-wysiwyg";
import jobDetails from "../jobs/jobDetails";

const JobProfile = () => {
    const [showAddNewProfile, setShowAddNewProfile] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [viewResponsibilities, setViewResponsibilities] = useState(false);
    const [profileDetails, setProfileDetails] = useState<IJobProfile>();
    const [editJobProfile, setEditJobProfile] = useState(false);
    const [showAddKeyCompetency, setShowAddKeyCompetency] = useState(false);
    const [showAddBenefits, setShowAddBenefits] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const columns: TableColumn<IJobProfile>[] = [
        {
            name: "Created On",
            selector: (row) => moment(new Date(row.created_at)).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "Job Title",
            selector: (row) =>
                (
                    <span className="text-gray-500">
            {" "}
                        {row.title}{" "}
          </span>
                ) as unknown as string,
            minWidth: "250px",
            wrap: true,
        },
        {
            name: "Department",
            selector: (row) =>
                (
                    <span className="text-gray-500">{String(row.organization_structure).toUpperCase()}</span>
                ) as unknown as string,
            minWidth: "300px",
            wrap: true,
        },

        {
            name: "Status",
            selector: (row) =>
                (
                    <>
                        {row.status === Status.ACTIVE ? (
                            <div className='flex items-center justify-center'>
                                <div className='bg-approvedBG rounded-full w-2 h-2 mr-2'></div>
                                <span
                                    className="text-gray-500">Active</span>
                            </div>
                        ) : (<div className='flex items-center justify-center'>
                            <div className='bg-red-500 rounded-full w-2 h-2 mr-2'></div>
                            <span
                                className="text-gray-500">Disabled</span>
                        </div>)}
                    </>
                ) as unknown as string,
        },
        {
            name: "Actions",
            selector: (row) =>
                (
                    <Actions
                        items={[
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setProfileDetails(row);
                                    setViewResponsibilities(true)
                                }}
                                icon={ViewIcon}
                                label="View Job Details"
                                color="gray-500"
                            />,
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setProfileDetails(row);
                                    setEditJobProfile(true)
                                }}
                                icon={EditIcon}
                                label="Edit Job Profile"
                                color="gray-500"
                            />,
                            // <DropdownMenuItem
                            //     row={row}
                            //     handlerFn={() => {
                            //         setProfileDetails(row);
                            //         setShowAddKeyCompetency(true)
                            //     }}
                            //     icon={AddIcon}
                            //     label="Add Key Competencies"
                            //     color="gray-500"
                            // />,
                            // <DropdownMenuItem
                            //     row={row}
                            //     handlerFn={() => {
                            //         setProfileDetails(row);
                            //         setShowAddBenefits(true)
                            //     }}
                            //     icon={AddIcon}
                            //     label="Add Benefits"
                            //     color="gray-500"
                            // />
                        ]}
                    />
                ) as unknown as string,
        },
    ];

    const getJobProfilesHandler = async (page: number) => {
        try {
            await sendRequest({
                url: `/jobProfile?pageNumber=${page}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const pageNumberChangeHandler = (page: number) => {
        setPageNumber(page)
    }

    useEffect(() => {
        getJobProfilesHandler(pageNumber);
    }, [pageNumber]);

    useEffect(() => {
        if (fetchAgain) {
            getJobProfilesHandler(pageNumber);
            setFetchAgain(false);
        }
    }, [fetchAgain, pageNumber]);

    return (
        <>
            <PageTitle title='Job Profiles' dropdown={
                <PageButton title='Create Job Profile' onClickHandler={() => {
                    setShowAddNewProfile(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={response?.data}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        paginationTotalRows={response?.pagination?.total}
                        onChangePage={pageNumberChangeHandler}
                        customStyles={customTableStyles}
                    />
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddNewProfile && (
                <Modal
                    title="Create Job Profile"
                    content={<CreateJobProfile onCancel={() => {
                        setShowAddNewProfile(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddNewProfile}
                    isHidden={() => setShowAddNewProfile(false)}
                    size='large'
                />
            )}
            {viewResponsibilities && profileDetails && (
                <Modal
                    title="Job Details"
                    content={
                        <div className='ml-3'>
                            <div className='mb-3'>
                                <h3 className='font-bold text-sm'>Job Description</h3>
                                <div className=''>
                                    <Editor
                                        contentState={JSON.parse(profileDetails.job_summary)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="text-xs px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <h3 className='font-bold text-sm'>Job Responsibilities</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={JSON.parse(profileDetails.job_responsibilities)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="text-xs px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <h3 className='font-bold text-sm'>Job Requirements</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={JSON.parse(profileDetails.job_requirements)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="text-xs px-3 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    isShown={viewResponsibilities}
                    isHidden={() => {
                        setProfileDetails(undefined)
                        setViewResponsibilities(false)
                    }}
                    size='large'
                />
            )}
            {editJobProfile && profileDetails && (
                <Modal
                    title="Edit Job Profile"
                    content={<EditJobProfile jobProfile={profileDetails} onCancel={() => {
                        setEditJobProfile(false)
                        setFetchAgain(true)
                    }}
                    />}
                    isShown={editJobProfile}
                    isHidden={() => {
                        setProfileDetails(undefined)
                        setEditJobProfile(false)
                    }}
                    size='large'
                />
            )}
            {showAddKeyCompetency && profileDetails && (
                <Modal
                    title="Add Key Competencies"
                    content={<AddKeyCompetency jobProfile={profileDetails}/>}
                    isShown={showAddKeyCompetency}
                    isHidden={() => {
                        setProfileDetails(undefined)
                        setShowAddKeyCompetency(false)
                    }}
                    size='large'
                />
            )}
            {showAddBenefits && profileDetails && (
                <Modal
                    title="Add Benefits"
                    content={<AddBenefits jobProfile={profileDetails}/>}
                    isShown={showAddBenefits}
                    isHidden={() => {
                        setProfileDetails(undefined)
                        setShowAddBenefits(false)
                    }}
                    size='large'
                />
            )}
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
}

export default JobProfile;