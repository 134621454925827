import {createContext} from "react";

export const AuthContext = createContext({
    isLoggedIn: false,
    userId: null,
    token: null,
    login: (uid: any, token: any, expirationDate?: any) => {
    },
    logout: () => {
    },
});
