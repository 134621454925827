import React from "react";
import {WhiteLoadingSpinner, AddPrimaryIcon} from "../../assets/icons";

interface ButtonProps {
    loading?: boolean;
    isValid?: boolean;
    btnText: string;
    width?: string;
    icon?: any;
    onButtonClick?: () => void;
    type: "button" | "submit" | "reset";
}

const Button = ({
                    loading,
                    isValid,
                    btnText,
                    onButtonClick,
                    type,
                    width,
                    icon,
                }: ButtonProps) => {
    return (
        <button
            type={type}
            onClick={onButtonClick}
            disabled={loading || isValid}
            className={`rounded ${width} text-white border bg-sidebarBG border-sidebarBG px-3 py-1.5 text-xs font-semibold  font-Montserrat disabled:text-white disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-50`}
        >
            {loading ? (
                <div className="flex items-center justify-center">
                    <img src={WhiteLoadingSpinner} alt="white loading spinner"/>
                    <span className="ml-2 text-white">Please wait...</span>
                </div>
            ) : (
                <div className='flex items-center justify-center space-x-1'>
                    {icon && (
                        <img src={AddPrimaryIcon} alt="plus icon"/>
                    )}
                    <span> {btnText} </span>
                </div>
            )}
        </button>

    );
};

export default Button;
