import React from "react";

interface IProps {
    label: string;
    color: string;
    icon?: any;
}

const Badge = ({label, color, icon}: IProps) => {
    return (
        <div
            className={`${color} py-0.5 rounded px-3 flex items-center space-x-1.5 text-xs font-Montserrat text-white`}
        >
            <span className="font-semibold">{label}</span>
            {icon && <img src={icon} alt="approved icon"/>}
        </div>
    );
};

export default Badge;
