import React, {useEffect, useState} from "react";
import {Select, type SelectProps} from "antd";
import useAxios from "../../hooks/useAxios";
import {IPrivileges} from "../../types/privileges";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";

interface IProps {
    onCancel: () => void;
    privilegeDetails: IPrivileges | undefined;
}

const AssignPrivileges = ({onCancel, privilegeDetails}: IProps) => {
    const [selectedOption, setSelectedOption] = useState<string[]>();

    const {
        response: privilegeRes,
        error: privilegeError,
        loading: privilegeLoading,
        sendRequest: sendPrivilegeReq,
        clearError: clearPrivilegeError,
    } = useAxios();

    const {
        response: submitPrivilegeRes,
        error: submitPrivilegeError,
        loading: submitPrivilegeLoading,
        sendRequest: sendSubmitPrivilegeReq,
        clearError: clearSubmitPrivilegeError,
    } = useAxios();

    const options: { label: string, value: string }[] = privilegeRes?.data?.map(
        (role: IPrivileges) => ({
            label: role.name,
            value: role.id,
        }),
    );

    const handleChange = (value: string[]) => {
        setSelectedOption(value);
    };

    const onSubmit = async () => {
        try {
            await sendSubmitPrivilegeReq({
                url: `/role/assignPrivileges/${privilegeDetails?.id}`,
                method: "PUT",
                data: selectedOption,
            });
        } catch (err) {
        }
    };

    const getPrivilegesHandler = async () => {
        try {
            await sendPrivilegeReq({
                url: `/role/${privilegeDetails?.id}/notAssignedPrivileges`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getPrivilegesHandler();
    }, []);

    if (submitPrivilegeRes?.data) {
        onCancel();
    }

    return (
        <>
            <form>
                <div className="mb-3">
                    <label
                        htmlFor="roles"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Select Privileges:
                    </label>
                    <div className="mt-3">
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            rootClassName="font-Montserrat"
                            loading={privilegeLoading}
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleChange}
                            options={options?.length ? options : []}
                        />
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button
                        type="button"
                        btnText="Assign Privilege"
                        loading={submitPrivilegeLoading}
                        onButtonClick={onSubmit}
                        isValid={!selectedOption}
                    />
                </div>
            </form>
            {privilegeError ?
                <ErrorModal error={privilegeError.error} onClose={clearPrivilegeError}/> : submitPrivilegeError ?
                    <ErrorModal error={submitPrivilegeError.error} onClose={clearSubmitPrivilegeError}/> : " "}
        </>
    )
}

export default AssignPrivileges;