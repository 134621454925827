import React, {useState} from 'react';
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Checkbox} from "antd";
import Button from "../../shared/components/Button";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import ErrorModal from "../../shared/components/ErrorModal";
import {Editor} from "react-draft-wysiwyg";
import {jobTypeOptions} from "../../shared/constants/SelectOptions";
import {Select} from "antd";
import {convertToRaw, EditorState} from "draft-js";

interface IProps {
    onCancel: () => void;
}

interface IFormData {
    job_title: string;
    location?: string;
    employer_name: string;
    start_date: string;
    end_date?: string;
}

const schema = yup
    .object({
        // employer_email: yup.string().email('Invalid email').required("Email is required"),
        employer_name: yup.string().required("Employer name is required"),
        // employer_phone_number: yup.string().length(10,'Employer phone number must be at least 10 digits').required("Employer Phone number is required"),
        job_title: yup.string().required("Job Title is required"),
        start_date: yup.string().required("Start date is required"),
        end_date: yup.string().optional(),
        location: yup.string().optional(),
    })
    .required();

const AddNewExperience = ({onCancel}: IProps) => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );
    const userData: IProfileUser = getCurrentUserData();

    const [currentWorkHere, setCurrentWorkHere] = useState<boolean>(false);
    const [employment_type, setType] = useState<string>(""); // Ensure this is declared only once.
    const {
        response,
        error,
        loading,
        sendRequest,
        clearError,
    } = useAxios();
    const handleTypeChange = (value: string) => {
        setType(value);
    };
    const onEditorStateChange = (editorContent: EditorState) => {
        setEditorState(editorContent);
    };

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                job_title: data.job_title,
                employer_name: data.employer_name,
                responsibilities: JSON.stringify(
                    convertToRaw(editorState.getCurrentContent())
                ),
                // employer_email: data.employer_email,
                // employer_phone_number: data.employer_phone_number,
                employment_type: employment_type,
                location: data.location,
                start_date: data.start_date,
                end_date: data.end_date,
                is_current_work_here: currentWorkHere,
                candidate_id: userData.userId.id
            };
            await sendRequest({
                url: "/experience",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    }
    if (response?.data) {
        onCancel();
    }


    return (<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-2 grid-cols-2">
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Job Title:
                    </label>
                    <div className="mt-1">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                {...register("job_title")}
                                type="text"
                                placeholder='Type here'
                                name="job_title"
                                id="job_title"
                                className={`pl-2 block w-full rounded ${errors.job_title && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.job_title?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Employment Type:
                    </label>
                    <div className="mt-1">
                        <Select
                            rootClassName="font-Montserrat"
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleTypeChange}
                            options={jobTypeOptions?.length ? jobTypeOptions : []}
                        />
                        {/* <p className="text-red-500 text-xs">{errors.employment_type?.message}</p> */}
                    </div>
                </div>
            </div>
            <div className="mb-32">
                <label
                    htmlFor="responsibilities"
                    className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                >
                    Job Responsibilities:
                </label>
                <div className="mt-1">
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        editorClassName="bg-notesBG rounded px-2 py-1"
                        wrapperClassName="h-32"
                        placeholder="Type job responsibilities here..."

                    />
                </div>
            </div>
            <div className="grid gap-2 grid-cols-2">
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Company Name:
                    </label>
                    <div className="mt-1">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                {...register("employer_name")}
                                type="text"
                                placeholder='Type here'
                                name="employer_name"
                                id="employer_name"
                                className={`pl-2 block w-full rounded ${errors.employer_name && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.employer_name?.message}</p>
                    </div>
                </div>

                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Location
                    </label>
                    <div className="mt-1">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                {...register("location")}
                                type="text"
                                placeholder='Type here'
                                name="location"
                                id="location"
                                className={`pl-2 block w-full rounded ${errors.location && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        {/* <p className="text-red-500 text-xs">{errors.employer_phone_number?.message}</p> */}
                    </div>
                </div>
            </div>

            {/* <div className="mb-3">
                <label
                    htmlFor="employer_email"
                    className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                >
                    Employer Email:
                </label>
                <div className="mt-1">
                    <div className='flex items-center justify-center space-x-5'>
                        <input
                            {...register("employer_email")}
                            type="text"
                            name="employer_email"
                            id="employer_email"
                            className={`pl-2 block w-full rounded ${errors.employer_email && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{errors.employer_email?.message}</p>
                </div>
            </div>
            <div className="mb-3">
                <label
                    htmlFor="email"
                    className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                >
                    Employer Phone Number:
                </label>
                <div className="mt-1">
                    <div className='flex items-center justify-center space-x-5'>
                        <input
                            {...register("employer_phone_number")}
                            type="text"
                            name="employer_phone_number"
                            id="employer_phone_number"
                            className={`pl-2 block w-full rounded ${errors.employer_phone_number && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{errors.employer_phone_number?.message}</p>
                </div>
            </div> */}
            <div className="grid gap-2 grid-cols-2">
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Start Date:
                    </label>
                    <div className="mt-1">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                {...register("start_date")}
                                type="date"
                                name="start_date"
                                id="start_date"
                                className={`pl-2 block w-full rounded ${errors.start_date && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.start_date?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        End Date:
                    </label>
                    <div className="mt-1">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                {...register("end_date")}
                                type="date"
                                name="end_date"
                                // disabled={currentWorkHere}
                                id="end_date"
                                className={`pl-2 block w-full rounded ${errors.end_date && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.end_date?.message}</p>
                        <div className="flex mt-2"><Checkbox onChange={(e) => {
                            setCurrentWorkHere(e.target.checked);
                        }} className="mr-1"/><p className="mt-1 text-xs">I currently
                            work here</p></div>
                    </div>
                </div>
            </div>
            <div className="my-5 border-b"></div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="button"
                    onClick={onCancel}
                    className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                >
                    Cancel
                </button>
                <Button type="submit" btnText="Save Experience" loading={loading}
                        isValid={!editorState.getCurrentContent().getPlainText()}
                />
            </div>
        </form>
        {error ? <ErrorModal error={error.error} onClose={clearError}/> : ""}
    </>);
}

export default AddNewExperience;