import React, {useContext, useState} from "react";
import {DownArrow, LoginIcon, LogoutIcon, NavbarLogo} from "../../assets/icons";
import Modal from "../../shared/components/Modal";
import Login from "../authentication/Login";
import {AuthContext} from "../../context/AuthContext";
import {Dropdown, MenuProps, Space} from "antd";
import {IProfileUser, UserType} from "../../types/ProfileUser";
import {useNavigate} from "react-router-dom";
import Registration from "../registration/Registration";
import ForgotPassword from "../authentication/ForgotPassword";

const Navbar = () => {

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string
    );

    const auth = useContext(AuthContext);

    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

    const navigate = useNavigate();

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <h3
                    onClick={
                        userData?.userId?.user_type === UserType.ADMIN
                            ? () => navigate("/admin/dashboard")
                            : () => navigate("/candidate/profile")
                    }
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Continue
                </h3>
            ),
            icon: <img src={LoginIcon} alt="logout icon"/>,
        },
        {
            key: "2",
            label: (
                <h3
                    onClick={() => auth.logout()}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Logout
                </h3>
            ),
            icon: <img src={LogoutIcon} alt="logout icon"/>,
        },
    ];

    return (
        <>
            {/* Navbar */}
            <div className="flex items-center justify-between px-6 lg:px-12 py-2 bg-primaryBG shadow-md">
                {/* Logo */}
                <img
                    className="cursor-pointer"
                    onClick={() => navigate("/")}  // Navigate to the home page
                    src={NavbarLogo}
                    alt="navbar logo"
                    style={{height: "70px", width: "auto"}}
                />

                {/* Hamburger Menu for Mobile */}
                <div className="lg:hidden">
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="text-primary focus:outline-none"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            {isMenuOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            )}
                        </svg>
                    </button>
                </div>

                {/* Header Links for Desktop */}
                <div className="hidden lg:flex items-center space-x-6 text-sm text-primary">
                    {["Home", "Job Openings", "Process", "Contact Us"].map((link, index) => (
                        <span
                            key={index}
                            className="hover:opacity-80 hover:underline cursor-pointer transition-colors duration-200"
                            onClick={() => {
                                if (link === "Contact Us") {
                                    // Navigate to the Contact Us page
                                    window.location.href = "https://www.bnr.rw/contact"; // Replace with the actual link to your Contact Us page
                                } else {
                                    // Generate the section ID dynamically based on the link name
                                    const sectionId = link.toLowerCase().replace(" ", "-"); // Create an ID from the link text
                                    if (sectionId === "home") {
                                        window.scrollTo({top: 0, behavior: "smooth"}); // Scroll to the top of the page for Home
                                    } else {
                                        const section = document.getElementById(sectionId);
                                        if (section) {
                                            section.scrollIntoView({behavior: "smooth"}); // Scroll to the section smoothly
                                        }
                                    }
                                }
                            }}
                        >
                        {link}
                        </span>
                    ))}
                </div>


                {/* User Authentication */}
                <div className="hidden lg:flex">
                    {auth.isLoggedIn ? (
                        <Dropdown trigger={["click"]} menu={{items}}>
                            <div onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <div className="flex items-center justify-center space-x-2">
                                        <div
                                            className="h-10 w-10 rounded-full bg-primary flex items-center justify-center cursor-pointer">
                                            <h3 className="text-textWhite text-sm">
                                                {userData.userId.names
                                                    .split(" ")
                                                    .map((name: string) => name[0])
                                                    .join("")}
                                            </h3>
                                        </div>
                                        <div className="cursor-pointer">
                                            <div className="flex items-center space-x-1.5">
                                                <h3 className="text-primary font-semibold text-sm">
                                                    {userData.userId.names}
                                                </h3>
                                                <img src={DownArrow} alt="down arrow icon"/>
                                            </div>
                                            <p className="text-gray-500 text-xs">
                                                {UserType[userData.userId.user_type]}
                                            </p>
                                        </div>
                                    </div>
                                </Space>
                            </div>
                        </Dropdown>
                    ) : (
                        <div className="flex space-x-2">
                            <button
                                onClick={() => setOpenLoginModal(true)}
                                className="bg-badgeBG text-primary border-2 border-transparent font-bold px-4 py-1 rounded text-sm hover:bg-primary hover:text-white transition-colors duration-200"
                            >
                                Log In
                            </button>
                            <button
                                onClick={() => setOpenRegisterModal(true)}
                                className="text-primary font-bold px-4 py-0.5 rounded border border-primary text-sm hover:bg-primary hover:text-white transition-colors duration-200"
                            >
                                Create Account
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="lg:hidden bg-primaryBG text-primary px-6 py-4 space-y-4">
                    {["Home", "Job Openings", "Process", "Contact Us"].map((link, index) => (
                        <div
                            key={index}
                            className="hover:text-gray-600 hover:underline cursor-pointer transition-colors duration-200"
                            onClick={() => {
                                if (link === "Home") {
                                    navigate("/");  // Navigate to the home page
                                } else if (link === "Job Openings") {
                                    navigate("/job-openings");  // Navigate to Job Openings page
                                } else if (link === "Process") {
                                    navigate("/process");  // Navigate to Process page
                                } else if (link === "Contact Us") {
                                    navigate("https://www.bnr.rw/contact");  // Navigate to Contact Us page
                                }
                                setIsMenuOpen(false);
                            }}
                        >
                            {link}
                        </div>
                    ))}
                    {auth.isLoggedIn ? (
                        <div
                            onClick={() => auth.logout()}
                            className="text-primary cursor-pointer hover:text-gray-600"
                        >
                            Logout
                        </div>
                    ) : (
                        <div className="flex flex-col space-y-2">
                            <button
                                onClick={() => setOpenLoginModal(true)}
                                className="bg-badgeBG text-primary font-bold px-4 py-2 rounded text-sm hover:bg-primary hover:text-white transition-colors duration-200"
                            >
                                Log In
                            </button>
                            <button
                                onClick={() => setOpenRegisterModal(true)}
                                className="text-primary font-bold px-4 py-2 rounded border border-primary text-sm hover:bg-primary hover:text-white transition-colors duration-200"
                            >
                                Register
                            </button>
                        </div>
                    )}
                </div>
            )}

            {/* Login Modal */}
            {openLoginModal && (
                <Modal
                    title="System Login"
                    content={
                        <Login
                            onCancel={() => setOpenLoginModal(false)}
                            openForgotPswdModal={() => {
                                setOpenLoginModal(false)
                                setOpenForgotPasswordModal(true)
                            }}
                        />
                    }
                    isShown={openLoginModal}
                    isHidden={() => setOpenLoginModal(false)}
                    size="medium"
                />
            )}

            {/* Registration Modal */}
            <Modal
                title="Candidate Registration"
                content={<Registration setOpenRegisterModal={setOpenRegisterModal}/>}
                isShown={openRegisterModal}
                isHidden={() => setOpenRegisterModal(false)}
                size="medium"
            />

            {/* Forgot Password Modal */}

            {openForgotPasswordModal && (
                <Modal
                    title="Forgot Your Password?"
                    content={<ForgotPassword/>}
                    isShown={openForgotPasswordModal}
                    isHidden={() => setOpenForgotPasswordModal(false)}
                    size="medium"
                />
            )}
        </>
    );
};

export default Navbar;
