import React, {useEffect, useState} from "react";
import {AddPrimaryIcon, EditIcon, LanguageIcon, RemoveGrayIcon} from "../../assets/icons";
import {Badge} from "antd";
import useAxios from "../../hooks/useAxios";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import Modal from "../../shared/components/Modal";
import AddNewLanguage from "./addNewLanguage";
import NoRecordFound from "../../shared/components/NoRecordFound";
import FullPageLoader from "../../shared/components/FullPageLoader";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import {ICandidateLanguage} from "./types/candidateLanguage";
import ErrorModal from "../../shared/components/ErrorModal";
import {languageEnum, levelEnum} from "./types/constants";
import EditLanguage from "./EditLanguage";

const Language = () => {

    const userData: IProfileUser = getCurrentUserData();
    const [fetchAgain, setFetchAgain] = useState<boolean>(false);
    const [languageDetails, setLanguageDetails] = useState<ICandidateLanguage>();
    const [showEditLanguage, setShowEditLanguage] = useState<boolean>(false);
    const [openLanguageModel, setOpenLanguageModel] = useState<boolean>(false);

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError
    } = useAxios();

    const {
        response: languageRes,
        error: languageError,
        loading: languageLoading,
        sendRequest: sendLanguageReq,
        clearError: clearLanguageError
    } = useAxios();

    const handleDelete = async (id: string) => {
        try {
            await sendLanguageReq({
                url: `/language/remove/${id}`,
                method: "PUT",
            });
            setFetchAgain(true);
        } catch (err) {
        }
    }

    const fetchLanguage = async () => {
        try {
            await sendRequest({
                url: `/language?candidate=${userData?.userId?.id}`,
                method: "GET",
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        if (fetchAgain) {
            fetchLanguage();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        fetchLanguage();
    }, []);

    useEffect(() => {
        if (languageRes?.data) {
            setFetchAgain(true);
        }
    }, [languageRes?.data]);

    return (
        <>
            <div className="flex justify-end">
                <div
                    className="text-sidebarBG flex items-center space-x-2 px-3 py-1.5 rounded border border-sidebarBG  transition-colors duration-200 cursor-pointer w-max"
                    onClick={() => {
                        setOpenLanguageModel(true);
                    }}
                >
                    <img src={AddPrimaryIcon} alt="Add Icon" className="w-4 h-4"/>
                    <h3 className="text-xs font-semibold ">Add Language</h3>
                </div>
            </div>
            <div className="overflow-y-auto h-52 ">
                <ul className=" divide-y divide-gray-300 dark:divide-gray-300">
                    {response?.data.map((language: ICandidateLanguage) => <li className="py-3 sm:py-2">
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <div className="w-10 h-10 flex items-center justify-center mt-2 bg-white rounded-full ">
                                <img className="w-6 h-6" src={LanguageIcon} alt="Work Icon"/>
                            </div>

                            <div className="flex-1 min-w-0 mt-2">
                                <p className="text-sm text-sidebarBG font-semibold capitalize">
                                    {languageEnum[language['language_name']]}
                                </p>
                                <Badge className=""
                                       count={levelEnum[language['level']].toUpperCase().replace("_", " ")} showZero
                                       color={language['level'] === 0 ? "#52c41a" : language['level'] === 1 ? "#faad14" : "#37628D"}/>
                            </div>
                            <div>
                                <Actions
                                    items={[
                                        <DropdownMenuItem
                                            handlerFn={() => {
                                                setLanguageDetails(language);
                                                setShowEditLanguage(true);
                                            }}
                                            icon={EditIcon}
                                            label="Edit Language"
                                            color="gray-500"
                                        />,
                                        <DropdownMenuItem
                                            handlerFn={async () => {
                                                await handleDelete(language.id);
                                            }}
                                            icon={RemoveGrayIcon}
                                            label="Remove Language"
                                            color="gray-500"
                                        />
                                    ]}
                                />
                            </div>
                        </div>
                    </li>)}
                </ul>
                {response?.data.length <= 0 && <div className="flex items-center justify-center w-full">
                    <div className="w-36"><NoRecordFound textSize="medium"/></div>
                </div>}
            </div>
            {openLanguageModel && (
                <Modal
                    title="Add Language"
                    content={<AddNewLanguage onCancel={() => {
                        setOpenLanguageModel(false);
                        setFetchAgain(true);
                    }}/>}
                    isShown={openLanguageModel}
                    isHidden={() => setOpenLanguageModel(false)}
                    size='medium'
                />
            )}

            {showEditLanguage && languageDetails && (
                <Modal
                    title="Add Language"
                    content={<EditLanguage candidateLanguage={languageDetails} onCancel={() => {
                        setLanguageDetails(undefined);
                        setShowEditLanguage(false);
                        setFetchAgain(true);
                    }}/>}
                    isShown={showEditLanguage}
                    isHidden={() => {
                        setLanguageDetails(undefined);
                        setShowEditLanguage(false)
                    }}
                    size='medium'
                />
            )}
            {(loading || languageLoading) && <FullPageLoader/>}
            {error ? <ErrorModal error={error.message} onClose={clearError}/> : languageError ?
                <ErrorModal error={languageError.message} onClose={clearLanguageError}/> : ''}
        </>
    )
}

export default Language;