import {IProfileUser, UserType} from "../types/ProfileUser";

interface IProps {
    userType: UserType;
    privilege?: string;
    children: any | any[];
}

export const Permissions = ({userType, privilege, children}: IProps) => {

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string,
    );

    let match: boolean = false;

    if (userType === userData.userId.user_type) {
        match = true;
    }

    if (privilege && !userData.userId.privileges.includes(privilege)) {
        return (<></>);
    }

    return (<>{match ? children : <></>} </>);
}

export const getCurrentUserData = (): IProfileUser => {
    return JSON.parse(
        localStorage.getItem("userData") as string,
    );
}