import React from "react";

interface IStatusRowProps {
    title: string;
    color: string;
}

const StatusRow = ({title, color}: IStatusRowProps) => {
    return (
        <div className='flex items-center justify-center'>
            <div className={`${color} rounded-full w-2 h-2 mr-2`}></div>
            <span
                className="text-gray-500">{title}</span>
        </div>
    )
}

export default StatusRow;