import React, {useContext, useState} from "react";
import {
    BackIcon,
    DownArrow,
    HomeIcon, KeyIcon,
    LogoutIcon,
    NotificationIcon,
    SearchIcon,
    SidebarIcon,
} from "../../assets/icons";
import {Dropdown, MenuProps, Space} from "antd";
import {AuthContext} from "../../context/AuthContext";
import {IProfileUser, UserType} from "../../types/ProfileUser";
import {useNavigate} from "react-router-dom";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {MenuOutlined, CloseOutlined} from "@ant-design/icons";
import Modal from "./Modal";
import ChangePassword from "../../pages/authentication/ChangePassword";

const DashboardHeader = () => {

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to toggle sidebar visibility
    const [openChangePswdModal, setOpenChangePswdModal] = useState(false); // State to toggle change password modal visibility

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string
    );

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <h3
                    onClick={() => navigate("/")}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Home
                </h3>
            ),
            icon: <img src={HomeIcon} alt="home icon"/>,
        },
        userData?.userId?.user_type === UserType.CANDIDATE ? (
            {
                key: "2",
                label: (
                    <h3
                        onClick={() => setOpenChangePswdModal(true)}
                        className="font-Montserrat text-gray-500 text-xs"
                    >
                        Change Password
                    </h3>
                ),
                icon: <img src={KeyIcon} alt="logout icon"/>,
            }
        ) : null,
        {
            key: "3",
            label: (
                <h3
                    onClick={() => auth.logout()}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Logout
                </h3>
            ),
            icon: <img src={LogoutIcon} alt="logout icon"/>,
        },
    ];

    return (
        <>
            {/* Header for all screen sizes */}
            <div className="mx-5 mt-3 flex items-center justify-between">
                {/* Hamburger Button for Small Screens */}
                <button
                    className="sm:hidden text-sidebarBG text-2xl p-2 rounded-lg"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                    {isSidebarOpen ? <CloseOutlined/> : <MenuOutlined/>}
                </button>

                {/* Full navbar for large screens */}
                <div className="hidden sm:flex items-center justify-between space-x-5 w-full">
                    {/* Back Button */}
                    <img
                        data-tooltip-id="tooltip-1"
                        onClick={() => navigate(-1)}
                        className="cursor-pointer h-5 w-5 hover:opacity-50 transition-all duration-300 ease-in-out"
                        src={BackIcon}
                        alt="back icon"
                    />
                    <ReactTooltip
                        className="font-Montserrat bg-sidebarBG text-white text-xs"
                        id="tooltip-1"
                        content="Go back"
                    />
                    {/* Search Bar */}
                    {/* <div className="flex py-1 rounded-md border-transparent border-2 bg-searchBG">
            <img className="ml-3" src={SearchIcon} alt="Search Icon" />
            <input
              className="ml-3 rounded focus:outline-none bg-searchBG"
              id="searchAnywhere"
              type="text"
              name="searchAnywhere"
              placeholder="Search"
            />
          </div> */}

                    {/* Notification and User Profile */}
                    <div className="flex items-center justify-center space-x-5">
                        {/* Notification Icon */}
                        <div className="p-2 rounded-lg bg-dashboardCardBG flex items-center justify-center">
                            <img src={NotificationIcon} alt="notification icon"/>
                        </div>

                        {/* User Profile Dropdown */}
                        {auth.isLoggedIn && (
                            <Dropdown trigger={["click"]} menu={{items}}>
                                <div onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <div className="flex items-center justify-center space-x-1">
                                            {/* User Avatar */}
                                            <div
                                                className="h-10 w-10 rounded-full bg-sidebarBG flex items-center justify-center cursor-pointer">
                                                <h3 className="text-white text-sm">
                                                    {userData.userId.names
                                                        .split(" ")
                                                        .map((name: string) => name[0])
                                                        .join("")
                                                        .toUpperCase()}
                                                </h3>
                                            </div>

                                            {/* User Info */}
                                            <div className="cursor-pointer">
                                                <div className="flex items-center justify-center space-x-1.5">
                                                    <h3 className="text-black text-sm">
                                                        {userData.userId.names}
                                                    </h3>
                                                    <img src={DownArrow} alt="down arrow icon"/>
                                                </div>
                                                <p className="text-gray-400 text-xs">
                                                    {UserType[userData.userId.user_type]}
                                                </p>
                                            </div>
                                        </div>
                                    </Space>
                                </div>
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>

            {/* Full navbar visible only on large screens */}
            <div className="hidden sm:block mt-5 ml-5 border-b"></div>

            {/* Only show on small screens (fixed top navbar) */}
            <div
                className="sm:hidden flex items-center justify-between p-4 bg-white shadow-lg fixed top-0 left-0 w-full z-50">
                {/* Notification Icon */}
                <div className="p-2 rounded-lg bg-dashboardCardBG flex items-center justify-center mx-2">
                    <img src={NotificationIcon} alt="notification icon"/>
                </div>

                {/* User Profile Dropdown */}
                {auth.isLoggedIn && (
                    <Dropdown trigger={["click"]} menu={{items}}>
                        <div onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div className="flex items-center justify-center space-x-1 mx-2">
                                    {/* User Avatar */}
                                    <div
                                        className="h-10 w-10 rounded-full bg-sidebarBG flex items-center justify-center cursor-pointer">
                                        <h3 className="text-white text-sm">
                                            {userData.userId.names
                                                .split(" ")
                                                .map((name: string) => name[0])
                                                .join("")
                                                .toUpperCase()}
                                        </h3>
                                    </div>

                                    {/* User Info */}
                                    <div className="cursor-pointer">
                                        <div className="flex items-center justify-center space-x-1.5">
                                            <h3 className="text-black text-sm">
                                                {userData.userId.names}
                                            </h3>
                                            <img src={DownArrow} alt="down arrow icon"/>
                                        </div>
                                        <p className="text-gray-400 text-xs">
                                            {UserType[userData.userId.user_type]}
                                        </p>
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </Dropdown>
                )}
            </div>
            {openChangePswdModal && (
                <Modal
                    title="Change Your Password"
                    content={<ChangePassword onCancel={() => setOpenChangePswdModal(false)}/>}
                    isShown={openChangePswdModal}
                    isHidden={() => setOpenChangePswdModal(false)}
                    size="medium"
                />
            )}
        </>
    );
};

export default DashboardHeader;
