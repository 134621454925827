export enum RequisitionType {
    PERMANENT = 0,
    CONTRACT,
    INTERNSHIP,
    REGULAR,
    TEMPORARY,
    LIMITED_TERM
}

export enum Justification {
    NEW_POSITION = 0,
    REPLACEMENT = 1,
    MODIFICATION = 2,
    EXTENSION = 3
}

export enum EmploymentStatus {
    REGULAR = 0,
    CONTRACTUAL = 1,
    TEMPORARY = 2,
    LIMITED_TERM = 3
}

export enum PayBasis {
    MONTHLY = 0,
    YEARLY = 1
}

export enum HiringModel {
    OPEN = 0,
    COMPETITION = 1,
    INTERNAL_RECRUITMENT = 2
}

export enum JobRequisitionStatus {
    DRAFT = 1,
    PENDING,
    OPEN,
    HOLD,
    CLOSED
}