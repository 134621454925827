import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Dropdown, Menu, Space} from "antd";
import {CloseOutlined, DownOutlined, MenuOutlined} from "@ant-design/icons";

import {SidebarIcon} from "../../assets/icons";
import {Permissions} from "../../utils/utilities";
import {IMenu} from "../layout/types/Menu";

interface IProps {
    menus: IMenu[];
}

const Sidebar = ({menus}: IProps) => {
    const [isOpen, setIsOpen] = useState(false); // State to toggle sidebar visibility

    return (
        <div className="flex sm:mx-3 sm:my-3 mx-0 my-0 relative">
            {/* Sidebar */}
            <div
                className={`fixed top-0 left-0 h-full bg-sidebarBG z-40 transition-transform duration-300 rounded-lg shadow-lg p-4  ${
                    isOpen ? "translate-x-0" : "-translate-x-full"
                } sm:translate-x-0 sm:static w-56 overflow-y-auto`}
            >
                <div className="mb-6 mt-3">
                    {/* Header Section */}
                    <div className="flex items-center justify-between">
                        <h3 className="text-white font-semibold ml-3">E-Recruitment</h3>
                        <img
                            src={SidebarIcon}
                            alt="Sidebar Icon"
                            className="hidden sm:block rounded-full"
                        />
                    </div>
                </div>
                <nav>
                    <ul className="text-white text-sm">
                        {menus.map((menu) => {
                            if (menu.subMenu?.length === 0) {
                                return (
                                    <Permissions privilege={menu?.privilege} userType={menu.userType} key={menu.name}>
                                        <li className="p-2 rounded-lg">
                                            <Link
                                                to={menu["path"]}
                                                className={`flex items-center space-x-2 ${
                                                    menu.marginExtend && "mt-16"
                                                }`}
                                            >
                                                <img
                                                    src={menu["icon"]}
                                                    alt={menu["name"]}
                                                    className="rounded-md"
                                                />
                                                <span>{menu["name"]}</span>
                                            </Link>
                                        </li>
                                    </Permissions>


                                );
                            } else {
                                const subMenus = menu.subMenu?.map((sub: any) => (
                                    <Permissions privilege={sub?.privilege} userType={menu.userType}>
                                        <Menu.Item key={sub["key"]}>{sub["label"]}</Menu.Item>
                                    </Permissions>
                                ))

                                return (
                                    <Permissions privilege={menu?.privilege} userType={menu.userType} key={menu.name}>
                                        <li className="p-2 rounded-lg ">
                                            <Dropdown overlay={<Menu>{subMenus}</Menu>} trigger={["click"]}>
                                                <div className="flex items-center space-x-2 cursor-pointer">
                                                    <Space>
                                                        <img
                                                            src={menu["icon"]}
                                                            alt={menu["name"]}
                                                            className="rounded-md"
                                                        />
                                                        <span>{menu["name"]}</span>
                                                        <DownOutlined/>
                                                    </Space>
                                                </div>
                                            </Dropdown>
                                        </li>
                                    </Permissions>
                                );
                            }
                        })}
                    </ul>
                </nav>
            </div>

            {/* Overlay for Small Screens */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-30 sm:hidden"
                    onClick={() => setIsOpen(false)}
                ></div>
            )}

            {/* Hamburger Button for Small Screens - Positioned on the left side with rounded background */}
            <button
                className="fixed bottom-4 left-4 z-50 text-primaryBG text-2xl p-0 w-14 h-14 rounded-full sm:hidden  bg-sidebarBG shadow-lg flex items-center justify-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? <CloseOutlined/> : <MenuOutlined/>}
            </button>
            {/* Main Content Placeholder */}
        </div>
    );
};

export default Sidebar;
