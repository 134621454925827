import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PageTitle from "../../shared/components/PageTitle";
import useAxios from "../../hooks/useAxios";
import DataTable, {TableColumn} from "react-data-table-component";
import {customTableStyles} from "../../shared/css/customTableStyles";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {IReceivedApplications} from "./types/ReceivedApplications";
import moment from "moment/moment";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import StatusRow from "../../shared/components/StatusRow";
import Actions from "../../shared/components/Actions";
import {
    FileExportIcon,
    FilterListIcon,
    SearchIcon,
    ShortlistingActionIcon,
    ViewIcon
} from "../../assets/icons";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import Modal from "../../shared/components/Modal";
import ViewDocuments from "./ViewDocuments";
import ViewCandidateInfo from "./ViewCandidateInfo";
import Dropdown from "../../shared/components/Dropdown";
import DropdownItem from "../../shared/components/DropdownItem";
import {handleFileDownload} from "../../utils/DownloadFile";
import CandidatesShortlisting from "./CandidatesShortlisting";
import {ApplicationStatusEnum} from "../my-applications/types/ApplicationStatus";
import CandidateExperience from "./CandidateExperience";

const ReceivedApplications = () => {

    const [showViewDocuments, setShowViewDocuments] = useState(false);
    const [showViewCandidateInfo, setShowViewCandidateInfo] = useState(false);
    const [searchValue, setSearchValue] = useState<string>();
    const [searchResults, setSearchResults] = useState<IReceivedApplications[]>([]);
    const [applicationDetails, setApplicationDetails] = useState<IReceivedApplications>();
    const [showShortlistingForm, setShowShortlistingForm] = useState(false);
    const [showCandidateExperience, setShowCandidateExperience] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const {requisitionId} = useParams();

    const navigate = useNavigate();

    const {response, error, loading, sendRequest, clearError} = useAxios()

    const {
        response: retrieveDataRes,
        error: retrieveDataError,
        loading: retrieveDataLoading,
        sendRequest: sendRetrieveDataReq,
        clearError: clearRetrieveDataError
    } = useAxios()

    const columns: TableColumn<IReceivedApplications>[] = [
        {
            name: "Received On",
            selector: (row) => moment(new Date(row.created_at)).format("DD-MM-YYYY"),
            sortable: true,
            minWidth: "125px",
        },
        {
            name: "Names",
            selector: (row) => `${row.candidate.first_name} ${row.candidate.last_name}`,
            minWidth: "170px",
            wrap: true,
        },
        {
            name: "National ID",
            selector: (row) => row.candidate.nid,
            minWidth: "150px",
            wrap: true,
        },
        {
            name: 'Email',
            selector: (row) => row.candidate.email,
            minWidth: "160px",
            wrap: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.candidate.phone_number,
            minWidth: "108px",
            wrap: true,
        },
        {
            name: "Gender",
            selector: (row) => row.candidate.gender === 1 ? "Male" : "Female"

        },
        {
            name: "Marks",
            selector: (row) => <span
                className='font-semibold'>{row.total_marks}/{row.out_of_marks}</span> as unknown as string,
        },
        {
            name: "Status",
            selector: (row) => (
                row.application_status === ApplicationStatusEnum.SHORTLISTED ?
                    (<StatusRow title='SHORTLISTED' color='bg-green-400'/>) :
                    row.application_status === ApplicationStatusEnum.NOT_SHORTLISTED ?
                        (<StatusRow title='NOT SELECTED' color='bg-purpleIconBadge'/>) :
                        row.application_status === ApplicationStatusEnum.SUBMITTED ?
                            (<StatusRow title='RECEIVED' color='bg-blue-400'/>) : ''

            ) as unknown as string,
            minWidth: "150px",
        },
        {
            name: "Actions",
            selector: (row) => (
                <Actions
                    items={[
                        <DropdownMenuItem
                            row={row}
                            handlerFn={() => {
                                setApplicationDetails(row)
                                setShowViewCandidateInfo(true)
                            }}
                            icon={ViewIcon}
                            label="Candidate Info"
                            color="gray-500"
                        />,
                        <DropdownMenuItem
                            row={row}
                            handlerFn={() => {
                                setApplicationDetails(row)
                                setShowViewDocuments(true)
                            }}
                            icon={ViewIcon}
                            label="View Documents"
                            color="gray-500"
                        />,
                        <DropdownMenuItem
                            row={row}
                            handlerFn={() => {
                                navigate(`/job-requisition/application-details/${row.id}`)
                            }}
                            icon={ViewIcon}
                            label="Application Details"
                            color="gray-500"
                        />,
                        <DropdownMenuItem
                            row={row}
                            handlerFn={() => {
                                setApplicationDetails(row)
                                setShowCandidateExperience(true)
                            }}
                            icon={ViewIcon}
                            label="Candidate Experience"
                            color="gray-500"
                        />
                    ]}
                />
            ) as unknown as string,
        }
    ];

    const getJobApplications = async (page: number) => {
        try {
            await sendRequest({
                url: `/jobApplication?requisition=${requisitionId}&pageNumber=${page}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const jobApplicationsSearchHandler = async () => {
        if (searchValue) {
            try {
                await sendRetrieveDataReq({
                    url: `/jobApplication?search=${searchValue}`,
                    method: "GET",
                });
            } catch (err) {
            }
        } else {
            await getJobApplications(pageNumber)
        }
    }

    const pageNumberChangeHandler = (page: number) => {
        setPageNumber(page)
    }

    useEffect(() => {
        getJobApplications(pageNumber);
    }, [pageNumber]);

    useEffect(() => {
        if (fetchAgain) {
            getJobApplications(pageNumber)
            setFetchAgain(false)
        }
    }, [fetchAgain, pageNumber]);

    useEffect(() => {
        if (retrieveDataRes?.data) {
            setSearchResults(retrieveDataRes.data)
        }
    }, [retrieveDataRes?.data]);

    return (
        <>
            <PageTitle title='Applications Received from Candidates' dropdown={
                <Dropdown content={
                    <>
                        {/*<DropdownItem*/}
                        {/*    title='Filter by Shortlisted'*/}
                        {/*    icon={FilterListIcon}*/}
                        {/*    onClickItem={() => {*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <DropdownItem
                            title='Export Applications List'
                            icon={FileExportIcon}
                            onClickItem={async () => await handleFileDownload(requisitionId!, 'All_applications_received_from_candidates', 'requisition/download')}
                        />
                        <DropdownItem
                            title='Candidates Shortlisting'
                            icon={ShortlistingActionIcon}
                            onClickItem={() => {
                                setShowShortlistingForm(true)
                            }}
                        />
                    </>

                }/>

            }/>
            <div className='mx-8 my-10'>
                {/*Search*/}
                <div className='mb-5 w-1/2 mx-auto'>
                    <div className="flex py-1 rounded-md border-transparent border-2 bg-searchBG">
                        <img className="ml-3" src={SearchIcon} alt="Search Icon"/>
                        <input
                            className="ml-3 rounded focus:outline-none bg-searchBG placeholder:text-sm w-full"
                            id="searchAnywhere"
                            type="text"
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value)
                            }}
                            name="searchAnywhere"
                            placeholder="Search by ID, names, phone, email..."
                        />
                        <div className='mx-3 flex items-center justify-end'>
                            <button
                                type="button"
                                onClick={jobApplicationsSearchHandler}
                                className="text-xs bg-sidebarBG text-white px-3 py-0.5 rounded focus:outline-none"
                            >
                                Find
                            </button>
                            <button
                                type="button"
                                onClick={async () => {
                                    setSearchValue('')
                                    searchResults.length && setSearchResults([])
                                    await getJobApplications(pageNumber)
                                }}
                                className="text-xs ml-3 bg-dropdownBG text-black px-3 py-0.5 rounded focus:outline-none"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </div>

                {response?.data?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={searchResults.length ? searchResults : response?.data}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        paginationTotalRows={response?.pagination?.total}
                        onChangePage={pageNumberChangeHandler}
                        customStyles={customTableStyles}
                    />
                ) : (
                    <NoRecordFound/>
                )}
            </div>

            {showViewDocuments && applicationDetails && (
                <Modal
                    title={`Documents Attached by ${applicationDetails.candidate.first_name} ${applicationDetails.candidate.last_name}`}
                    content={
                        <ViewDocuments applicationDetails={applicationDetails}/>
                    }
                    isShown={showViewDocuments}
                    isHidden={() => {
                        setApplicationDetails(undefined)
                        setShowViewDocuments(false)
                    }}
                    size='large'
                />
            )}

            {
                showViewCandidateInfo && applicationDetails && (
                    <Modal
                        title="Candidate Personal Information"
                        content={
                            <ViewCandidateInfo applicationDetails={applicationDetails}/>
                        }
                        isShown={showViewCandidateInfo}
                        isHidden={() => {
                            setApplicationDetails(undefined)
                            setShowViewCandidateInfo(false)
                        }}
                        size='large'
                    />
                )
            }

            {
                showCandidateExperience && applicationDetails && (
                    <Modal
                        title="Candidate Experience"
                        content={
                            <CandidateExperience applicationDetails={applicationDetails}/>
                        }
                        isShown={showCandidateExperience}
                        isHidden={() => {
                            setApplicationDetails(undefined)
                            setShowCandidateExperience(false)
                        }}
                        size='large'
                    />
                )
            }

            {
                showShortlistingForm && requisitionId && (
                    <Modal
                        title="Candidates Shortlisting"
                        content={<CandidatesShortlisting requisitionId={requisitionId} onCancel={
                            () => {
                                setShowShortlistingForm(false)
                                setFetchAgain(true)
                            }
                        }
                        />}
                        isShown={showShortlistingForm}
                        isHidden={() => setShowShortlistingForm(false)}
                        size='medium'
                    />
                )
            }

            {error ? <ErrorModal error={error.error} onClose={clearError}/> : retrieveDataError ?
                <ErrorModal error={retrieveDataError.error} onClose={clearRetrieveDataError}/> : ''}
            {(loading || retrieveDataLoading) && <FullPageLoader/>}
        </>
    );
}

export default ReceivedApplications;