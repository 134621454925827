import React from "react";
import {DownloadIcon, ImageFileIcon, PdfFileIcon, ViewIcon} from "../../assets/icons";
import {Tooltip} from "antd";
import {IReceivedApplications} from "./types/ReceivedApplications";
import moment from "moment";
import {handleFileDownload} from "../../utils/DownloadFile";

interface IProps {
    applicationDetails: IReceivedApplications;
}

const ViewDocuments = ({applicationDetails}: IProps) => {
    return (
        <div className='pt-5'>
            {applicationDetails.attachments.map((item, index) => (
                <div key={index}>
                    <div className='flex items-center justify-between px-8 pb-8'>
                        <div className='flex items-center justify-start'>
                            <div
                                className={`border-2 border-transparent rounded-lg ${item.file.typeext === 'pdf' ? 'bg-filePdf' : 'bg-fileImage'}  p-3`}>
                                <img src={item.file.typeext === 'pdf' ? PdfFileIcon : ImageFileIcon} alt="file icon"/>
                            </div>
                            <div className='ml-3'>
                                <p className='text-sm mb-2 max-w-xl'>{item.title}</p>
                                <p className='text-xs text-gray-500'>Attached
                                    by {applicationDetails.candidate.first_name} {applicationDetails.candidate.last_name.at(0)}.
                                    on {moment(item.file.created_at).format('ll')}</p>
                            </div>
                        </div>
                        <div className='flex items-center justify-end space-x-3'>
                            <Tooltip title='Download'>
                                <div onClick={
                                    async () => {
                                        await handleFileDownload(item.file.id, item.title, 'attachment/download')
                                    }
                                }
                                     className='border rounded-lg bg-downloadHoverBG p-1 hover:bg-white cursor-pointer'>
                                    <img src={DownloadIcon} alt="download icon"/>
                                </div>
                            </Tooltip>
                            {/*{item.name.split('.')[1] === 'pdf' && (*/}
                            {/*<Tooltip title='Preview'>*/}
                            {/*    <div*/}
                            {/*        onClick={*/}
                            {/*            async () => {*/}
                            {/*                await handleFileDownload(item.file.id, item.title)*/}
                            {/*            }*/}
                            {/*        }*/}
                            {/*        className='border rounded-lg bg-loginBG p-1 hover:bg-white cursor-pointer'*/}
                            {/*    >*/}
                            {/*        <img src={ViewIcon} alt="download icon"/>*/}
                            {/*    </div>*/}
                            {/*</Tooltip>*/}
                            {/*)}*/}
                        </div>
                    </div>
                    {/*<div className='border-b mb-8 mx-8'></div>*/}
                </div>
            ))}
        </div>
    )
};

export default ViewDocuments;