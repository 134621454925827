import React from 'react';

interface IDetailRowProps {
    label: string;
    value: any;
    color?: string;
    small?: boolean;
    valueColor?: string;
    labelColor?: string;

}

const DetailRow = ({label, value, color, small, valueColor, labelColor}: IDetailRowProps) => {
    return (
        <div
            className={`grid grid-cols-1 lg:grid-cols-2 gap-4 py-1 ${color && color} ${small ? 'text-xs' : 'text-sm'}`}
        >
            <span className={`flex justify-end ${labelColor ? labelColor : 'text-gray-500'}`}>{label}</span>
            <div className='text-gray-700  break-words'>
                <p className={`${valueColor ? valueColor : 'font-semibold'}`}>{value}</p>
            </div>
        </div>
    );
};

export default DetailRow;
