import React, {useState} from "react";
import {ConfigurationIcon, OptionsIcon} from "../../assets/icons";

interface IProps {
    content: any;
}

const Dropdown: React.FC<IProps> = (props) => {
    const {content} = props;

    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="relative flex flex-col items-center w-64 rounded-lg">
            <button
                onClick={() => setIsOpen((prev) => !prev)}
                className="bg-searchBG pl-1.5 pr-3 py-1.5 w-full flex items-center justify-between  rounded-md tracking-wider border border-transparent active:border-sidebarBG duration-500 active:text-white"
            >
                <div className="flex items-center justify-center space-x-1.5 ml-2">
                    <img src={ConfigurationIcon} alt="settings icon"/>
                    <span className="text-xs text-gray-600 font-Montserrat">
            More options
          </span>
                </div>
                <img src={OptionsIcon} alt="Options Icon"/>
            </button>
            {isOpen && (
                <div
                    className="bg-dashboardCardBG absolute z-10 top-10 flex flex-col items-start rounded-lg p-2 w-full">
                    {content}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
