import React from "react";
import {UserMailIcon} from "../../assets/icons";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import useAxios from "../../hooks/useAxios";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert} from "antd";
import {HttpStatusCode} from "axios";

interface IFormData {
    email: string;
}

const schema = yup
    .object({
        email: yup.string().email('Invalid email').required("Email is required"),
    })
    .required();

const ForgotPassword = () => {

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            await sendRequest({
                url: `/authentication/forgotPassword/${data.email}`,
                method: "PUT"
            });
        } catch (err) {
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {response?.status === HttpStatusCode.Ok && (
                    <Alert
                        className='mb-3 text-xs'
                        message="Email is sent successfully. Check your email and close this window."
                        type="success" showIcon
                    />
                )}
                <p className='text-sm text-gray-500 mb-3'>
                    Provide email address associated with your account,
                    we will send you an email with instructions to reset
                    your password.
                </p>
                <div className="mb-3">
                    <div className='flex border-2 rounded items-center justify-center'>
                        <img className='ml-3' src={UserMailIcon} alt="user email icon"/>
                        <input
                            {...register("email")}
                            type="text"
                            name="email"
                            id="email"
                            placeholder='Enter your email address'
                            className={`pl-2 block w-full ${errors.email && "border-red-500"} py-1 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{errors.email?.message}</p>
                </div>
                <div className="mt-6 mb-3">
                    <button
                        type='submit'
                        className='bg-sidebarBG text-white w-full py-1.5 rounded hover:bg-opacity-80'
                    >
                        Send
                    </button>
                </div>
            </form>
            {error && <ErrorModal error={error.message} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
}

export default ForgotPassword;