import React, {useState} from "react";
import {Select, message, Upload} from "antd";
import type {UploadProps} from 'antd';
import {fileTypeOptions} from "../../shared/constants/SelectOptions";
import Button from "../../shared/components/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {UploadIcon} from "../../assets/icons";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";

interface IProps {
    onCancel: () => void
}

interface IFormData {
    fileName: string;
}

const schema = yup
    .object({
        fileName: yup.string().required("File name is required"),
    })
    .required();

const AddFileAttachment = ({onCancel}: IProps) => {

    const [selectedFileType, setSelectedFileType] = useState<string>();
    const [fileId, setFileId] = useState<string>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const userData: IProfileUser = getCurrentUserData();

    const fileProps: UploadProps = {
        name: 'file',
        accept: '.pdf, .png, .jpg, .jpeg',
        action: `${process.env.REACT_APP_API_BASE_URL}/attachment/upload`,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            console.log(info.file.response?.data?.id)
            if (info.file.status === 'done' && info.file.response?.data?.id) {
                setFileId(info.file.response.data.id);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove() {
            setFileId(undefined);
        }
    };

    const handleSelectedFileType = (value: string) => {
        setSelectedFileType(value)
    }

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                candidate: userData.userId.id,
                title: data.fileName,
                attachment_type: selectedFileType,
                attachment: fileId
            };
            await sendRequest({
                url: "/attachment",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    }

    if (response?.data) {
        message.success('File saved successfully');
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="template_name"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        File Name:
                    </label>
                    <div className="mt-3">
                        <input
                            {...register("fileName")}
                            type="text"
                            name="fileName"
                            id="fileName"
                            placeholder='Type here'
                            className={`pl-2 block w-full rounded ${errors.fileName && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.fileName?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="questionnaire_type"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Select File Type:
                    </label>
                    <div className="mt-3">
                        <Select
                            rootClassName="font-Montserrat"
                            loading={!fileTypeOptions?.length}
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleSelectedFileType}
                            options={fileTypeOptions}
                        />
                    </div>
                </div>
                <div className='mb-3 mt-8'>
                    <Upload {...fileProps}>
                        <button
                            type='button'
                            className="outline outline-1 outline-offset-0 rounded px-3 py-1 text-sm text-gray-500 flex items-center justify-center space-x-1.5 hover:bg-userBadgeBg hover:text-sidebarBG ">
                            <img src={UploadIcon} alt="upload icon"/>
                            <span>Upload File</span>
                        </button>
                    </Upload>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save File"
                            isValid={selectedFileType === undefined || fileId === undefined}
                            loading={loading}/>
                </div>
            </form>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}

        </>
    );
}

export default AddFileAttachment;