import React, {useEffect, useState} from "react";
import PageTitle from "../../shared/components/PageTitle";
import {Gender, IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import {
    addDark,
    BirthPlaceIcon,
    CalendarIcon,
    CloseIcon,
    EditIcon,
    EmailBgIcon,
    FatherIcon,
    GenderIcon,
    MotherIcon,
    PhoneBgIcon,
    YearIcon,
} from "../../assets/icons";
import Tab from "../../shared/components/Tab";
import Experience from "./Experience";
import Education from "./Education";
import Language from "./Language";
import FileAttachment from "./FileAttachment";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {Tooltip} from "antd";


const Profile = () => {
    const [activeTabMenu, setActiveTabMenu] = useState<number>(0);
    const userData: IProfileUser = getCurrentUserData();
    const profileBodies: JSX.Element[] = [<Education/>, <Experience experiences={[]}/>, <Language/>,
        // <Disability/>,
        //     <Certificate/>,
        //     <Referee/>,
        <FileAttachment/>];
    const switchMenu = (index: number) => {
        if (index < profileBodies.length) setActiveTabMenu(index);
    }
    const [newSkill, setNewSkill] = useState<string>(); // Input for new skill
    const [isSkillModalOpen, setIsSkillModalOpen] = useState(false); // Modal toggle
    const [bio, setBio] = useState<string>(""); // Bio text
    const [isEditingBio, setIsEditingBio] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false)

    const {response, error, loading, sendRequest, clearError} = useAxios()
    const {
        response: candidateDataRes,
        error: candidateDataError,
        loading: candidateDataLoading,
        sendRequest: sendCandidateDataReq,
        clearError: clearCandidateDataError
    } = useAxios()


    const handlePostBio = async () => {

        try {
            await sendRequest({
                url: '/candidate-profile/biography',
                method: 'POST',
                data: {
                    biography: bio,
                    candidate_id: userData.userId.id
                }
            })

        } catch (err) {
        }
    };

    const handleAddSkill = async () => {
        try {
            await sendRequest({
                url: '/candidate-profile/skills',
                method: 'POST',
                data: {
                    skills: [newSkill],
                    candidate_id: userData.userId.id
                }
            })

        } catch (err) {
        }
    };

    const handleRemoveSkill = async (skill: string) => {
        try {
            await sendRequest({
                url: '/candidate-profile/skills/remove',
                method: 'PUT',
                data: {
                    skills: [skill],
                    candidate_id: userData.userId.id
                }
            })

        } catch (err) {
        }
    };

    const getCandidateProfile = async () => {
        try {
            await sendCandidateDataReq({
                url: `/candidate-profile/${userData.userId.id}`,
                method: 'GET'
            })

        } catch (err) {
        }
    }

    useEffect(() => {
        getCandidateProfile()
    }, [])

    useEffect(() => {
        if (fetchAgain) {
            getCandidateProfile()
            setFetchAgain(false)
        }
    }, [fetchAgain])

    useEffect(() => {
        if (response?.data) {
            setFetchAgain(true)
        }
    }, [response?.data])

    return (
        <>
            <PageTitle textSize="text-xl" title={`Hi ${userData.userId.names}, Welcome Back!`}/>
            <div className="flex flex-col lg:flex-row pb-10">
                <div
                    className="flex flex-col w-full lg:w-1/4 p-5 rounded-lg border-2 border-transparent bg-dashboardCardBG">
                    <div className="mb-3 flex flex-col items-center justify-center">
                        <img
                            src={`data:image/png;base64,${userData.userId.profile_photo}`}
                            className="w-24 h-24 p-1 rounded-full ring-2 ring-gray-400"
                            alt="candidate profile photo"
                        />
                        <p className="mt-2 text-sm font-bold text-sidebarBG">{userData.userId.names}</p>
                        {/* Editable Bio Section */}
                        <div className="relative mt-5 w-full">
                            <div className="mb-4">
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-semibold text-gray-700">About</p>
                                    {candidateDataRes?.data?.biography && (
                                        <button
                                            onClick={() => setIsEditingBio(!isEditingBio)}
                                            className="text-xs text-gray-500 hover:text-sidebarBG"
                                        >
                                            {isEditingBio ?
                                                (
                                                    <Tooltip title='Close edit' key='close-editing-bio'>
                                                        <img src={CloseIcon} alt=" close icon" className="w-4"/>
                                                    </Tooltip>
                                                ) :
                                                (
                                                    <Tooltip title='Edit bio' key='Edit-bio'>
                                                        <img src={EditIcon} alt="Add Icon" className="w-4"/>
                                                    </Tooltip>
                                                )
                                            }
                                        </button>
                                    )}
                                </div>
                                {candidateDataRes?.data.biography ? (
                                    <>
                                        {isEditingBio ? (
                                            <div className="relative">
                                        <textarea
                                            defaultValue={candidateDataRes?.data?.biography}
                                            onChange={(e) => setBio(e.target.value)}
                                            placeholder="Enter your short bio..."
                                            className="w-full p-2 bg-searchBG border-2 border-transparent rounded mt-2 text-sm h-24"
                                            style={{resize: "none"}} // Disable resizing for better layout
                                        />
                                                <button
                                                    onClick={async () => {
                                                        await handlePostBio()
                                                        setIsEditingBio(false)
                                                    }}
                                                    className="absolute bottom-2 right-2 bg-sidebarBG text-white px-3 py-0.5  mb-1.5 rounded text-xs"
                                                >
                                                    Post
                                                </button>
                                            </div>
                                        ) : (
                                            <p className="mt-2 text-sm text-gray-700">{candidateDataRes?.data.biography}</p>

                                        )}
                                    </>

                                ) : (
                                    <div className="relative">
                                    <textarea
                                        value={bio}
                                        onChange={(e) => setBio(e.target.value)}
                                        placeholder="Enter your short bio..."
                                        className="w-full p-2 bg-searchBG border-2 border-transparent rounded mt-2 text-sm h-24"
                                        style={{resize: "none"}} // Disable resizing for better layout
                                    />
                                        <button
                                            onClick={async () => await handlePostBio()}
                                            className="absolute bottom-2 right-2 bg-sidebarBG text-white px-3 py-0.5  mb-1.5 rounded text-xs"
                                        >
                                            Post
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Skills Section */}
                    <div className="mb-4">
                        <div className="flex justify-between items-center">
                            <p className="text-sm font-semibold text-gray-700">Skills</p>
                            <Tooltip key='add-skill' title='Add skill'>
                                <button
                                    onClick={() => setIsSkillModalOpen(true)}
                                    className="text-xs text-gray-500 hover:text-sidebarBG mr-2"
                                >
                                    <img src={addDark} alt="Add Icon" className="w-4 h-4"/>
                                </button>
                            </Tooltip>

                        </div>
                        {candidateDataRes?.data?.skills?.skills?.length === 0 ? (
                            <p className="mt-2 text-xs text-gray-500">No available skills</p>
                        ) : (
                            <div className="flex items-center flex-wrap mt-2">
                                {candidateDataRes?.data?.skills?.skills?.map((skill: any, index: number) => (
                                    <>
                                        <div
                                            key={index}
                                            className="flex items-center text-sm text-sidebarBG mt-1 mb-2 font-medium me-2 pl-3 pr-2.5 py-1 border rounded-full "
                                        >
                                            <span>{skill}</span>
                                            <Tooltip key='remove-skill' title='Remove skill'>
                                                <div onClick={async () => await handleRemoveSkill(skill)}
                                                     className='ml-2 mb-2'>
                                                    <img src={CloseIcon} alt=" close icon"
                                                         className="w-3 cursor-pointer"/>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Main Section */}
                <div className="mt-6 lg:mt-0 lg:ml-6 grow">
                    <div className="bg-dashboardCardBG rounded-lg p-5">
                        <p className="text-left font-bold mb-5">Basic Information</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            {/* Basic Info Items */}
                            {/* Example */}
                            <div className="flex items-center">
                                <img className="w-10 h-10 rounded-full" src={BirthPlaceIcon} alt="Icon"/>
                                <div className="ml-4">
                                    <p className="text-xs text-gray-500">Personal ID</p>
                                    <p className="text-xs">{userData.userId.nid}</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={YearIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        Age
                                    </p>
                                    <p className="text-xs">
                                        {2024 - parseInt(userData?.userId?.nid?.substring(1, 5) || '0')}
                                    </p>
                                </div>
                            </div>


                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={CalendarIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        Birth Date
                                    </p>
                                    <p className="text-xs">
                                        {userData.userId.date_of_birth}
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={GenderIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        Gender
                                    </p>
                                    <p className="text-xs">
                                        {userData?.userId?.gender === Gender.MALE ? 'MALE' : userData?.userId?.gender === Gender.FEMALE ? 'FEMALE' : ' '}
                                    </p>
                                </div>
                            </div>


                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={EmailBgIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        Email
                                    </p>
                                    <p className="text-xs">
                                        {userData.userId.email}
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={PhoneBgIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        Phone Number
                                    </p>
                                    <p className="text-xs">
                                        {userData.userId.phone_number}
                                    </p>
                                </div>
                            </div>


                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={FatherIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        District
                                    </p>
                                    <p className="text-xs">
                                        {userData.userId.addresses.district}
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={MotherIcon}
                                         alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0 ms-4">
                                    <p className="text-xs text-gray-500">
                                        Sector
                                    </p>
                                    <p className="text-xs">
                                        {userData.userId.addresses.sector}
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="bg-dashboardCardBG rounded-lg p-5 mt-4 ">
                        <Tab activeTabMenu={activeTabMenu} switchMenu={switchMenu} tabBodies={profileBodies}
                             tabMenus={["Academic Qualifications", "Experience", "Language",
                                 //  "Disabilities", "Certificates", "Referees",
                                 "Documents"]}/>
                    </div>
                </div>
            </div>
            {isSkillModalOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-sidebarBG bg-opacity-30 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-1/3">
                        <h3 className="font-bold mb-3">Add Skill</h3>
                        <input
                            type="text"
                            value={newSkill}
                            onChange={(e) => setNewSkill(e.target.value)}
                            placeholder="Enter a skill"
                            className="mb-5 pl-2 block w-full rounded placeholder:text-xs border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none"
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsSkillModalOpen(false)}
                                className="bg-dropdownBG text-sm px-3 py-0.5 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddSkill}
                                className="bg-sidebarBG text-white text-sm px-3 py-0.5 rounded"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {candidateDataError ?
                <ErrorModal error={candidateDataError.error} onClose={clearCandidateDataError}/> : error ?
                    <ErrorModal error={error.error} onClose={clearError}/> : ''}
            {(loading || candidateDataLoading) && <FullPageLoader/>}
        </>);
}

export default Profile;