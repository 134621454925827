import React from "react";
import {Link} from "react-router-dom";

interface IProps {
    title: string;
    icon: any;
    onClickItem?: () => void;
    to?: string;
}

const DropdownItem: React.FC<IProps> = ({title, onClickItem, icon, to}) => {
    return (
        <>
            {to ? (
                <Link
                    to={to}
                    className="flex items-center justify-between w-full hover:bg-userBadgeBg cursor-pointer rounded-r-lg border-l-transparent hover:border-l-black border-l-4 px-3 py-1.5"
                >
                    <p className="text-gray-600 text-xs font-Montserrat">{title}</p>
                    <img src={icon} alt="time icon"/>
                </Link>
            ) : (
                <div
                    onClick={onClickItem}
                    className="flex items-center justify-between w-full hover:bg-userBadgeBg cursor-pointer rounded-r-lg border-l-transparent hover:border-l-black border-l-4 px-3 py-1.5"
                >
                    <p className="text-gray-600 text-xs font-Montserrat">{title}</p>
                    <img src={icon} alt="time icon"/>
                </div>
            )}
        </>
    );
};
export default DropdownItem;
