import React, {useState} from "react";
import {ICandidateLanguage} from "./types/candidateLanguage";
import {Select} from "antd";
import {languageLevelOptions, languageOptions} from "../../shared/constants/SelectOptions";
import Button from "../../shared/components/Button";
import useAxios from "../../hooks/useAxios";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import ErrorModal from "../../shared/components/ErrorModal";

interface IEditLanguageProps {
    onCancel: () => void;
    candidateLanguage: ICandidateLanguage;
}

const EditLanguage = ({onCancel, candidateLanguage}: IEditLanguageProps) => {

    const [level, setLevel] = useState<number>(candidateLanguage.level);
    const [language, setLanguage] = useState<number>(candidateLanguage.language_name);

    const userData: IProfileUser = getCurrentUserData();

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError
    } = useAxios();

    const handleLevelChange = (value: number) => {
        setLevel(value);
    };

    const handleLanguageChange = (value: number) => {
        setLanguage(value);
    };

    const onSubmit = async () => {
        try {
            const payload = {
                language_name: language,
                level: level,
                candidate: userData.userId.id
            };
            await sendRequest({
                url: `/language/${candidateLanguage.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    }

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form>
                <div className="mb-3">
                    <label
                        htmlFor="language"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Language:
                    </label>
                    <div className="mt-2">
                        <Select
                            defaultValue={candidateLanguage.language_name}
                            id='language'
                            rootClassName="font-Montserrat"
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleLanguageChange}
                            options={languageOptions?.length ? languageOptions : []}
                        />
                    </div>
                </div>

                <div className="mb-3">
                    <label
                        htmlFor="level"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Level:
                    </label>
                    <div className="mt-2">
                        <Select
                            defaultValue={candidateLanguage.level}
                            id="level"
                            rootClassName="font-Montserrat"
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleLevelChange}
                            options={languageLevelOptions?.length ? languageLevelOptions : []}
                        />
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-1 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="button" onButtonClick={onSubmit} btnText="Submit" loading={loading}
                    />
                </div>
            </form>
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    );
};

export default EditLanguage;