import React, {useContext, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Navbar from "./Navbar";
import {ArrowIcon, JobSearchIcon} from "../../assets/icons";
import Footer from "./Footer";
import {IPublishedJob} from "../../types/PublishedJob";
import {RequisitionType} from "../../shared/constants/JobRequisition";
import moment from "moment/moment";
import {Editor} from "react-draft-wysiwyg";
import {AuthContext} from "../../context/AuthContext";
import {IProfileUser, UserType} from "../../types/ProfileUser";
import Modal from "../../shared/components/Modal";
import Login from "../authentication/Login";
import ForgotPassword from "../authentication/ForgotPassword";
import Registration from "../registration/Registration";

const JobDescription = () => {

    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);

    const location = useLocation();
    const data: IPublishedJob = location.state;

    const auth = useContext(AuthContext);

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string
    );

    const navigate = useNavigate()

    return (
        <>
            <div className='bg-primaryBG'>
                <Navbar/>
                <div className='bg-badgeBG'>
                    <div className='p-14 flex flex-col items-center justify-center '>
                        <h3 className='text-primary font-bold text-3xl mb-3'>{data.title}</h3>
                        <p className='text-primary text-sm mb-5'>{data.organization_structure ? data.organization_structure.toUpperCase() : ""}</p>
                        <div className='flex items-center justify-center bg-white px-2.5 py-1 space-x-1.5 rounded'>
                            <span onClick={() => navigate('/')}
                                  className='text-primary font-bold text-sm cursor-pointer'>Home</span>
                            <img src={ArrowIcon} alt="arrow icon"/>
                            <span className='text-primary text-sm cursor-pointer'>Jobs</span>
                        </div>
                    </div>
                </div>
                <div className='bg-cardBG pt-20 pb-16 px-44'>
                    <div className='flex space-x-24'>
                        <div className='max-h-72 border-2 p-5 bg-textWhite border-transparent rounded-md min-w-96'>
                            <div className='flex items-center justify-between'>
                                <div
                                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>
                                    Featured
                                </div>
                                <div
                                    className='bg-badgeBG text-primary font-bold px-3 py-1 rounded text-xs'>
                                    {data.requisition_type === RequisitionType.CONTRACT ? 'CONTRACT' : data.requisition_type === RequisitionType.PERMANENT ? 'PERMANENT' : data.requisition_type === RequisitionType.INTERNSHIP ? 'INTERNSHIP' : data.requisition_type === RequisitionType.REGULAR ? 'REGULAR' : data.requisition_type === RequisitionType.TEMPORARY ? 'TEMPORARY' : data.requisition_type === RequisitionType.LIMITED_TERM ? 'LIMITED TERM' : ' '}
                                </div>
                            </div>
                            <div className='flex items-center justify-center flex-col'>
                                <img src={JobSearchIcon} alt="test exam icon"/>
                                <h3 className='text-primary text-center font-bold text-lg mt-5'>
                                    {data.title}
                                </h3>
                                <div className='mt-5 flex items-center justify-center space-x-2'>
                                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>
                                    <p className='text-gray-500 text-sm text-center'>{moment(data.application_deadline).format("llll")}</p>
                                </div>
                                <div className='mt-5'>
                                    <button
                                        onClick={(auth.isLoggedIn && (userData.userId.user_type === UserType.CANDIDATE)) ? () => navigate('/candidate/job-details', {state: data}) : () => setOpenLoginModal(true)}
                                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'
                                    >
                                        Apply For This Position
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='max-w-xl mt-3'>
                            <div className='mb-3'>
                                <h3 className='font-bold text-primary'>Job Description</h3>
                                <div className=''>
                                    <Editor
                                        contentState={JSON.parse(data.job_summary)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <h3 className='font-bold text-primary'>Job Responsibilities</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={JSON.parse(data.job_responsibilities)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='font-bold text-primary'>Job Requirements</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={JSON.parse(data.job_requirements)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="px-3 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Other Related Jobs*/}

                {/*<div className='px-28 py-16'>*/}
                {/*    <h3 className='text-primary font-bold text-3xl mb-3'>Related Job</h3>*/}
                {/*    <p className='text-sm text-primary mb-16'>Departments: Customer Services Project Management*/}
                {/*        Development Design*/}
                {/*        Marketing Accounting*/}
                {/*        / Finance</p>*/}
                {/*    <div className='flex items-center justify-center gap-5 flex-wrap'>*/}
                {/*        <div className='border-2 p-5 bg-cardBG border-transparent rounded-md w-96'>*/}
                {/*            <div className='flex items-center justify-between'>*/}
                {/*                <div*/}
                {/*                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Featured*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className='bg-textWhite text-primary font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Permanent*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className='flex items-center justify-center flex-col'>*/}
                {/*                <img src={JobSearchIcon} alt="test exam icon"/>*/}
                {/*                <h3 className='text-primary text-center font-bold text-lg mt-5'>Product Manager</h3>*/}
                {/*                <div className='mt-5 flex items-center justify-center space-x-2'>*/}
                {/*                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>*/}
                {/*                    <p className='text-gray-500 text-sm text-center'>{moment().format("llll")}</p>*/}
                {/*                </div>*/}
                {/*                <div className='mt-5'>*/}
                {/*                    <button*/}
                {/*                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'>*/}
                {/*                        View Details*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='border-2 p-5 bg-cardBG border-transparent rounded-md w-96'>*/}
                {/*            <div className='flex items-center justify-between'>*/}
                {/*                <div*/}
                {/*                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Featured*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className='bg-textWhite text-primary font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Contract*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className='flex items-center justify-center flex-col'>*/}
                {/*                <img src={JobSearchIcon} alt="test exam icon"/>*/}
                {/*                <h3 className='text-primary text-center font-bold text-lg mt-5'>Software*/}
                {/*                    Engineer</h3>*/}
                {/*                <div className='mt-5 flex items-center justify-center space-x-2'>*/}
                {/*                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>*/}
                {/*                    <p className='text-gray-500 text-sm text-center'>{moment().format("llll")}</p>*/}
                {/*                </div>*/}
                {/*                <div className='mt-5'>*/}
                {/*                    <button*/}
                {/*                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'>*/}
                {/*                        View Details*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='border-2 p-5 bg-cardBG border-transparent rounded-md w-96'>*/}
                {/*            <div className='flex items-center justify-between'>*/}
                {/*                <div*/}
                {/*                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Featured*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className='bg-textWhite text-primary font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Permanent*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className='flex items-center justify-center flex-col'>*/}
                {/*                <img src={JobSearchIcon} alt="test exam icon"/>*/}
                {/*                <h3 className='text-primary text-center font-bold text-lg mt-5'>Cyber Security*/}
                {/*                    Officer</h3>*/}
                {/*                <div className='mt-5 flex items-center justify-center space-x-2'>*/}
                {/*                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>*/}
                {/*                    <p className='text-gray-500 text-sm text-center'>{moment().format("llll")}</p>*/}
                {/*                </div>*/}
                {/*                <div className='mt-5'>*/}
                {/*                    <button*/}
                {/*                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'>*/}
                {/*                        View Details*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Footer/>
            </div>

            {/* Login Modal */}

            {openLoginModal && (
                <Modal
                    title="Login as a Candidate"
                    content={
                        <Login
                            isApplying={true}
                            onCancel={() => setOpenLoginModal(false)}
                            openForgotPswdModal={() => {
                                setOpenLoginModal(false)
                                setOpenForgotPasswordModal(true)
                            }}
                            openRegisterModal={() => {
                                setOpenLoginModal(false)
                                setOpenRegisterModal(true)
                            }}
                        />
                    }
                    isShown={openLoginModal}
                    isHidden={() => setOpenLoginModal(false)}
                    size="medium"
                />
            )}

            {/* Forgot Password Modal */}

            {openForgotPasswordModal && (
                <Modal
                    title="Forgot Your Password?"
                    content={<ForgotPassword/>}
                    isShown={openForgotPasswordModal}
                    isHidden={() => setOpenForgotPasswordModal(false)}
                    size="medium"
                />
            )}

            {/* Registration Modal */}

            {openRegisterModal && (
                <Modal
                    title="Candidate Registration"
                    content={<Registration setOpenRegisterModal={setOpenRegisterModal}/>}
                    isShown={openRegisterModal}
                    isHidden={() => setOpenRegisterModal(false)}
                    size="medium"
                />
            )}

        </>
    )
}

export default JobDescription;