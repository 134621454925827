import React, {useEffect, useState} from "react";
import {EditIcon, LevelIcon} from "../../assets/icons";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateJobLevel from "./CreateJobLevel";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import useAxios from "../../hooks/useAxios";
import {IJobLevel} from "../../types/JobLevel";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import NoRecordFound from "../../shared/components/NoRecordFound";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import EditJobLevel from "./EditJobLevel";
import PageButton from "../../shared/components/PageButton";

const ContainerHeight = 400;

const JobLevel = () => {
    const [showAddLevel, setShowAddLevel] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [data, setData] = useState<IJobLevel[]>([]);
    const [jobLevelDetails, setJobLevelDetails] = useState<IJobLevel>();
    const [editJobLevel, setEditJobLevel] = useState(false);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const getJobLevelHandler = async () => {
        try {
            await sendRequest({
                url: "/settings/jobField",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getJobLevelHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getJobLevelHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} levels loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getJobLevelHandler();
        }
    };

    return (
        <>
            <PageTitle title='Job Level' dropdown={
                <PageButton title='Add New Level' onClickHandler={() => {
                    setShowAddLevel(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                            onScroll={onScroll}
                        >
                            {(item: IJobLevel) => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={LevelIcon}/>}
                                        title={
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                                                {item.name}
                                            </span>
                                        }
                                        description={
                                            <span className="font-Montserrat text-gray-500 text-xs">
                                                {item.description ? item.description : "No description"}
                                            </span>
                                        }
                                    />
                                    <div>
                                        <Actions
                                            items={[
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setJobLevelDetails(item)
                                                        setEditJobLevel(true)
                                                    }}
                                                    icon={EditIcon}
                                                    label="Edit Level"
                                                    color="gray-500"
                                                />,
                                            ]}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddLevel && (
                <Modal
                    title="Add New Job Level"
                    content={<CreateJobLevel onCancel={() => {
                        setShowAddLevel(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddLevel}
                    isHidden={() => setShowAddLevel(false)}
                    size="medium"
                />
            )}
            {editJobLevel && jobLevelDetails && (
                <Modal
                    title="Edit Job Level"
                    content={<EditJobLevel jobLevelDetails={jobLevelDetails} onCancel={() => {
                        setEditJobLevel(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={editJobLevel}
                    isHidden={() => {
                        setJobLevelDetails(undefined)
                        setEditJobLevel(false)
                    }}
                    size="medium"
                />)}
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
}

export default JobLevel;