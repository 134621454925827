import React, { FC } from "react";

type Props = {
    row?: any;
    handlerFn: any;
    icon?: any;
    label: string;
    color?: string;
    statusColor?: string;
    setFetchAgain?: any;
};

const DropdownMenuItem: FC<Props> = ({
                                         row,
                                         handlerFn,
                                         icon,
                                         label,
                                         color,
                                         setFetchAgain,
                                         statusColor,
                                     }) => {
    return (
        <button
            className="w-full flex items-center py-1.5 font-Montserrat text-xs"
            onClick={
                setFetchAgain
                    ? () => handlerFn(row, setFetchAgain)
                    : () => handlerFn(row)
            }
        >
            {icon ? (
                <img src={icon} alt="" className="mr-2 h-5" />
            ) : (
                <div className={`bg-${statusColor} rounded-full w-2 h-2 mr-2`}></div>
            )}
            <span className={color ? `text-${color}` : ""}>{label}</span>
        </button>
    );
};

export default DropdownMenuItem;
