import React from "react";

import {
    ApplicationFormIcon,
    HeaderIcon, InterviewIcon,
    SearchIcon, TestExamIcon,
} from "../../assets/icons";

import {Carousel} from "antd";
import Navbar from "./Navbar";
import FeaturedJobs from "./FeaturedJobs";
import Footer from "./Footer";

const LandingPage = () => {

    return (
        <>
            <div className='bg-primaryBG'>
                {/* Navbar */}
                <Navbar/>

                {/* Header */}
                <div className='bg-badgeBG'>
                    <div className="p-10 lg:p-20 flex flex-col lg:flex-row items-center justify-center">
                        {/* Left Section */}
                        <div className="lg:mr-32 mb-10 lg:mb-0 text-center lg:text-left">
                            <div className="text-primary">
                                <h3 className="text-2xl lg:text-3xl font-bold">Apply and Become Part of The</h3>
                                <h3 className="text-2xl lg:text-3xl font-bold">National Bank of Rwanda Team.</h3>
                                <p className="text-sm lg:text-sm mt-2">
                                    Find jobs, create trackable resumes and enrich your applications
                                </p>
                            </div>
                            <div
                                className="mt-8 flex flex-col sm:flex-row items-center justify-between py-1.5 rounded border-2 border-transparent bg-white"
                            >
                                {/* Search Input */}
                                <div className="flex items-center w-full sm:w-auto mb-3 sm:mb-0">
                                    <img className="ml-3" src={SearchIcon} alt="Search Icon"/>
                                    <input
                                        className="ml-2 w-full text-xs lg:text-sm py-1.5 text-textBG rounded focus:outline-none"
                                        id="searchAnywhere"
                                        type="text"
                                        name="searchAnywhere"
                                        placeholder="Position, Keywords..."
                                    />
                                </div>
                                {/* Button */}
                                <div
                                    className="bg-badgeBG border-2 border-transparent rounded px-2 py-1 mr-2 cursor-pointer flex-shrink-0"
                                >
                                    <span className="text-xs font-bold text-primary">Find Jobs</span>
                                </div>
                            </div>
                            <p className="text-xs lg:text-xs text-primary mt-3">
                                Filter by Departments, Position, or Relevant Keywords to find the right match.
                            </p>
                        </div>

                        {/* Right Section */}
                        <div className="lg:ml-32">
                            <img className="w-72 lg:w-96 mx-auto" src={HeaderIcon} alt="header icon"/>
                        </div>
                    </div>
                </div>

                {/* Featured Jobs Section */}
                <div id="job-openings">
                    <FeaturedJobs/>
                </div>

                {/* Recruitment Process Section */}
                <div id="process" className="my-10">
                    <div className="flex items-center justify-center flex-col mb-10">
                        <h3 className="text-primary font-bold text-2xl mb-3">Our Recruitment Process</h3>
                        <p className="text-sm text-primary">We commit to building a home that belongs to everyone.</p>
                    </div>

                    <div className="w-4/5 mx-auto py-16">
                        {/* Steps Container */}
                        <div
                            className="flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-12">
                            {/* Step 1 */}
                            <div
                                className="flex flex-col items-center w-full lg:w-1/4 hover:scale-105 transition-transform duration-300">
                                <div
                                    className="w-16 h-16 rounded-full bg-primary text-white flex items-center justify-center text-xl font-bold shadow-lg">
                                    1
                                </div>
                                <h3 className="text-xl font-semibold mt-6 text-primary">Online Application</h3>
                                <p className=" text-gray-700 text-sm mt-4 text-center">
                                    Fill out the form and complete your profile with your qualifications, experience,
                                    and skills.
                                </p>
                            </div>

                            {/* Dotted Connector */}
                            <div
                                className="hidden lg:block flex-1 h-1 border-dotted border-t-2 border-gray-300 mx-4"></div>

                            {/* Step 2 */}
                            <div
                                className="flex flex-col items-center w-full lg:w-1/4 hover:scale-105 transition-transform duration-300">
                                <div
                                    className="w-16 h-16 rounded-full bg-primary text-white flex items-center justify-center text-xl font-bold shadow-lg">
                                    2
                                </div>
                                <h3 className="text-xl font-semibold mt-6 text-primary">Technical Test</h3>
                                <p className="text-gray-700 text-sm mt-4 text-center">
                                    Demonstrate your expertise through practical challenges designed to test your
                                    technical proficiency.
                                </p>
                            </div>

                            {/* Dotted Connector */}
                            <div
                                className="hidden lg:block flex-1 h-1 border-dotted border-t-2 border-gray-300 mx-4"></div>

                            {/* Step 3 */}
                            <div
                                className="flex flex-col items-center w-full lg:w-1/4 hover:scale-105 transition-transform duration-300">
                                <div
                                    className="w-16 h-16 rounded-full bg-primary text-white flex items-center justify-center text-xl font-bold shadow-lg">
                                    3
                                </div>
                                <h3 className="text-xl font-semibold mt-6 text-primary">HR Interview</h3>
                                <p className="text-gray-700 text-sm mt-4 text-center">
                                    A discussion to explore your career background, aspirations, and fit with our
                                    company culture.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Footer Section */}
                <div id="contact-us">
                    <Footer/>
                </div>
            </div>
        </>
    );
}

export default LandingPage;
