import React, {useEffect, useState} from "react";
import {
    AddPrimaryIcon,
    EditIcon,
    RemoveGrayIcon,
    WorkIcon
} from "../../assets/icons";
import Modal from "../../shared/components/Modal";
import AddNewExperience from "./AddNewExperience";
import useAxios from "../../hooks/useAxios";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import moment from "moment";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import NoRecordFound from "../../shared/components/NoRecordFound";
import FullPageLoader from "../../shared/components/FullPageLoader";
import ErrorModal from "../../shared/components/ErrorModal";
import {ICandidateExperience} from "./types/candidateExperience";
import EditExperience from "./EditExperience";


interface IProps {
    experiences: any[]
}

const Experience = ({experiences}: IProps) => {
    const userData: IProfileUser = getCurrentUserData();

    const [fetchAgain, setFetchAgain] = useState<boolean>(false);
    const [openExperienceModel, setOpenExperienceModel] = useState<boolean>(false);
    const [openEditExperienceModel, setOpenEditExperienceModel] = useState<boolean>(false);
    const [experienceDetails, setExperienceDetails] = useState<ICandidateExperience>();

    const {
        response: responseExperience,
        error: errorExperience,
        loading: loadingExperience,
        sendRequest: sendRequestExperience,
        clearError: clearErrorExperience
    } = useAxios();

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError
    } = useAxios();

    const handleDelete = async (id: string) => {
        try {
            await sendRequest({
                url: `/experience/remove/${id}`,
                method: "PUT",
            });
            setFetchAgain(true);
        } catch (err) {
        }
    }

    const fetchExperience = async () => {
        try {
            await sendRequestExperience({
                url: `/experience?candidate=${userData?.userId?.id}`,
                method: "GET",
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchExperience();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            fetchExperience();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data) {
            setFetchAgain(true);
        }
    }, [response?.data]);

    return (
        <>
            <div className="flex justify-end">
                <div
                    className="text-sidebarBG flex items-center space-x-2 px-3 py-1.5 rounded border border-sidebarBG  transition-colors duration-200 cursor-pointer w-max"
                    onClick={() => {
                        setOpenExperienceModel(true);
                    }}
                >
                    <img src={AddPrimaryIcon} alt="Add Icon" className="w-4 h-4"/>
                    <h3 className="text-xs font-semibold ">Add Experience</h3>
                </div>
            </div>

            <div className="overflow-y-auto h-52 mt-3">
                <ul className=" divide-y divide-gray-300 dark:divide-gray-300">
                    {responseExperience?.data && responseExperience?.data.map((experience: ICandidateExperience) => <li
                        className="py-3 sm:py-2">
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <div className="w-10 h-10 flex items-center justify-center mt-2 bg-white rounded-full ">
                                <img className="w-5 h-5" src={WorkIcon} alt="Work Icon"/>
                            </div>

                            <div className="flex-1 min-w-0">
                                <p className="text-sm text-sidebarBG">
                                    {experience['job_title']}
                                </p>
                                <p className="text-xs">
                                    {experience['employer_name']} | {moment(new Date(experience['start_date'])).format("ll")}
                                    <span className='font-bold'> - </span> {experience['is_current_work_here'] ?
                                    <span
                                        className='text-sidebarBG font-semibold'>Present</span> : moment(new Date(experience['end_date'])).format("ll")}
                                </p>
                            </div>

                            <div>
                                <Actions
                                    items={[
                                        <DropdownMenuItem
                                            handlerFn={() => {
                                                setExperienceDetails(experience);
                                                setOpenEditExperienceModel(true);
                                            }}
                                            icon={EditIcon}
                                            label="Edit Experience"
                                            color="gray-500"
                                        />,
                                        <DropdownMenuItem
                                            handlerFn={async () => {
                                                await handleDelete(experience.id);
                                            }}
                                            icon={RemoveGrayIcon}
                                            label="Remove Experience"
                                            color="gray-500"
                                        />
                                    ]}
                                />
                            </div>
                        </div>
                    </li>)}
                </ul>
                {responseExperience?.data.length <= 0 && <div className="flex items-center justify-center w-full">
                    <div className="w-36"><NoRecordFound textSize="medium"/></div>
                </div>}
            </div>

            {openExperienceModel && (
                <Modal
                    title="Add Experience"
                    content={<AddNewExperience onCancel={() => {
                        setOpenExperienceModel(false);
                        setFetchAgain(true);
                    }}/>}
                    isShown={openExperienceModel}
                    isHidden={() => setOpenExperienceModel(false)}
                    size='large'
                />
            )}
            {openEditExperienceModel && experienceDetails && (
                <Modal
                    title="Edit Your Experience"
                    content={<EditExperience candidateExperience={experienceDetails} onCancel={() => {
                        setExperienceDetails(undefined);
                        setOpenExperienceModel(false);
                        setFetchAgain(true);
                    }}/>}
                    isShown={openEditExperienceModel}
                    isHidden={() => {
                        setExperienceDetails(undefined);
                        setOpenEditExperienceModel(false)
                    }}
                    size='large'
                />
            )}
            {errorExperience ? <ErrorModal error={errorExperience.error} onClose={clearErrorExperience}/> : error ?
                <ErrorModal error={error.error} onClose={clearError}/> : ''}
            {(loadingExperience || loading) && <FullPageLoader/>}
        </>
    );
}

export default Experience;