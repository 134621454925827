import React from "react";

interface IProps {
  tabMenus: string[];
  activeTabMenu: number;
  tabBodies: JSX.Element[];
  switchMenu: (arg: number) => void;
}

const Tab = ({ tabMenus, activeTabMenu, tabBodies, switchMenu }: IProps) => {
  return (
    <>
      <ul className="flex flex-wrap text-sm text-gray-500 font-medium border-b border-gray-300">
        {tabMenus.map((menu, i) =>
          i === activeTabMenu ? (
            <li key={i} className="mr-4">
              <button
                onClick={() => switchMenu(i)}
                className="inline-block p-4 text-sidebarBG border-b-2 border-sidebarBG font-semibold focus:outline-none"
              >
                {menu}
              </button>
            </li>
          ) : (
            <li key={i} className="mr-4">
              <button
                onClick={() => switchMenu(i)}
                className="inline-block p-4 hover:text-sidebarBG hover:border-b-2 hover:border-gray-300 transition-all"
              >
                {menu}
              </button>
            </li>
          )
        )}
      </ul>
      <div className="mt-4">{tabBodies[activeTabMenu]}</div>
    </>
  );
};

export default Tab;
