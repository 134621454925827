import React, {useEffect, useState} from "react";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import {Select, type SelectProps, Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {IRoles} from "../../types/roles";

interface IProps {
    onCancel: () => void;
}

interface IFormData {
    email: string;
}

const schema = yup
    .object({
        email: yup.string().email('Invalid email').required("Email is required"),
    })
    .required();

const CreateNewUser = ({onCancel}: IProps) => {

    const [selectedRole, setSelectedRole] = useState<string[]>();

    const {
        response,
        error,
        loading,
        sendRequest,
        clearError
    } = useAxios();

    const {
        response: roleRes,
        error: roleError,
        loading: roleLoading,
        sendRequest: sendRoleReq,
        clearError: clearRoleError
    } = useAxios();

    const {
        response: validateUserRes,
        error: validateUserError,
        loading: validateUserLoading,
        sendRequest: sendValidateUserReq,
        clearError: clearValidateUserError
    } = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const roleOptions: SelectProps["options"] = roleRes?.data?.map(
        (role: IRoles) => ({
            label: role.name,
            value: role.id,
        }),
    );

    const handleChange = (value: string[]) => {
        setSelectedRole(value);
    };

    const getRolesHandler = async () => {
        try {
            await sendRoleReq({
                url: "/role",
                method: "GET",
            });
        } catch (err) {
        }
    };

    const validateUserHandler = async (value: string) => {
        try {
            await sendValidateUserReq({
                url: `/user/validateUsername/${value}`,
                method: "GET",
            });
        } catch (err) {
        }
    }

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                email: data.email,
                roles: selectedRole
            };
            await sendRequest({
                url: "/user",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        getRolesHandler();
    }, []);

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="email"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Email:
                    </label>
                    <div className="mt-3">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                {...register("email")}
                                type="email"
                                name="email"
                                onBlur={async (e) => {
                                    await validateUserHandler(e.target.value)
                                }}
                                id="title"
                                placeholder='Type here'
                                disabled={validateUserLoading}
                                className={`pl-2 block w-full rounded ${errors.email && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                            {validateUserLoading &&
                                <Spin indicator={<LoadingOutlined style={{fontSize: 28, color: '#BFBFBF'}} spin/>}/>}
                        </div>
                        <p className="text-red-500 text-xs">{errors.email?.message}</p>
                    </div>
                </div>
                {validateUserRes?.data &&
                    <div className='mb-3 bg-userBadgeBg p-5 rounded-md'>

                        <div className='flex items-center justify-start space-x-2 text-sidebarBG'>
                            <h3 className='font-bold'>First name:</h3>
                            <span>{validateUserRes.data.name.split(' ')[0]}</span>
                        </div>
                        <div className='flex items-center justify-start space-x-2 text-sidebarBG'>
                            <h3 className='font-bold'>Last name:</h3>
                            <span>{validateUserRes.data.name.split(' ')[1]}</span>
                        </div>
                        <div className='flex items-center justify-start space-x-2 text-sidebarBG'>
                            <h3 className='font-bold'>Username:</h3>
                            <span>{validateUserRes.data.sAMAccountName}</span>
                        </div>
                    </div>
                }
                <div className="mb-3">
                    <label
                        htmlFor="roles"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Roles:
                    </label>
                    <div className="mt-2">
                        <Select
                            mode="multiple"
                            rootClassName="font-Montserrat"
                            loading={roleLoading}
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleChange}
                            options={roleOptions?.length ? roleOptions : []}
                        />
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save User" loading={loading}
                            isValid={!selectedRole?.length || !validateUserRes?.data}/>
                </div>
            </form>
            {roleError ? <ErrorModal error={roleError.error} onClose={clearRoleError}/> : validateUserError ?
                <ErrorModal error={validateUserError.error} onClose={clearValidateUserError}/> : error ?
                    <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    );
}

export default CreateNewUser;