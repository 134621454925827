import type {SelectProps} from "antd";

export enum AttachmentTypeEnum {
    NID = 1,
    RESUME,
    PASSPORT,
    COVER_LETTER,
    CERTIFICATE,
    CRIMINAL_RECORD,
    OTHER,
    DEGREE,
}

export interface ICandidateInfo {
    id: string;
    nid: string;
    first_name: string;
    last_name: string;
    father_name: string;
    mother_name: string;
    place_of_birth: string;
    date_of_birth: string;
    gender: number;
    email: string;
    phone_number: string;
    attachments: Attachment[];
}

export const enum MaritalStatus {
    SINGLE = 1,
    MARRIED,
    DIVORCED,
    SEPARATED,
    WIDOWED,
}

interface Attachment {
    id: string;
    title: string;
    description: null;
    attachment: string;
    attachment_type: number;
    created_at: string;
    updated_at: string;
}


export const maritalStatusOptions: SelectProps["options"] = [
    {
        label: 'Single ',
        value: 1,
    },
    {
        label: 'Married',
        value: 2,
    },
    {
        label: 'Divorced',
        value: 3,
    },
    {
        label: 'Separated',
        value: 4,
    },
    {
        label: 'Widowed',
        value: 5,
    },


]