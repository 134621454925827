import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import PageButton from "../../shared/components/PageButton";
import PageTitle from "../../shared/components/PageTitle";
import React from "react";
import Modal from "../../shared/components/Modal";
import CreateTemplateQuestions from "./CreateTemplateQuestions";
import {Dropdown, MenuProps, Space, Tooltip} from "antd";
import {
    AddIcon,
    EditIcon,
    OptionsVerticalIcon, RemoveGrayIcon,
    TemplateFileIcon,
    TemplateQuestionIcon,
    ViewIcon
} from "../../assets/icons";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {ITemplate, QuestionnaireType} from "../../types/questionnaire";
import EditTemplateQuestion from "./EditTemplateQuestion";

const TemplateQuestions = () => {

    const [showAddTemplate, setShowAddTemplate] = useState(false)
    const [fetchAgain, setFetchAgain] = useState(false)
    const [templateDetails, setTemplateDetails] = useState<ITemplate>()
    const [editTemplate, setEditTemplate] = useState(false)

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: deleteRes,
        error: deleteError,
        loading: deleteLoading,
        sendRequest: sendDeleteReq,
        clearError: clearDeleteError
    } = useAxios();

    const handleDelete = async (id: string) => {
        try {
            await sendDeleteReq({
                url: `/questionnaireTemplate/removeTemplate/${id}`,
                method: "PUT",
            });
            setFetchAgain(true);
        } catch (err) {
        }
    }

    const getQuestionnaireHandler = async () => {
        try {
            await sendRequest({
                url: "/questionnaireTemplate",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getQuestionnaireHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getQuestionnaireHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    const navigate = useNavigate()

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <h3
                    onClick={() => navigate('/admin/view-questions', {state: {templateDetails: templateDetails}})}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    View Questions
                </h3>
            ),
            icon: <img className='w-5' src={ViewIcon} alt="add icon"/>,
        },
        {
            key: "2",
            label: (
                <h3
                    onClick={() => navigate('/admin/add-template-questions', {state: {templateDetails: templateDetails}})}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Add Questions
                </h3>
            ),
            icon: <img src={AddIcon} alt="add icon"/>,
        },
        {
            key: "3",
            label: (
                <h3
                    onClick={() => setEditTemplate(true)}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Edit Template
                </h3>
            ),
            icon: <img src={EditIcon} alt="edit icon"/>,
        },
        {
            key: "4",
            label: (
                <h3
                    onClick={async () => await handleDelete(templateDetails?.id!)}
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Remove Template
                </h3>
            ),
            icon: <img src={RemoveGrayIcon} className='w-4' alt="edit icon"/>,
        },
    ];

    useEffect(() => {
        if (deleteRes?.data) {
            setFetchAgain(true);
        }
    }, [deleteRes?.data]);

    return (
        <>
            <PageTitle title='Shortlisting Questions' dropdown={
                <PageButton title='Add New Template' onClickHandler={() => {
                    setShowAddTemplate(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                <div className='flex items-center justify-start flex-wrap gap-5'>
                    {response?.data?.length > 0 ? (
                        response?.data?.map((template: ITemplate, index: number) => (
                            <div key={index} className='w-1/5'>
                                <div className='border border-transparent bg-dashboardCardBG'>
                                    <img src={TemplateQuestionIcon} alt="template question icon"/>
                                </div>
                                <div className='h-32 border py-5 px-3'>
                                    <h3 className='text-sm mb-0.5'>{template.template_name} Questions</h3>
                                    <div className='flex items-center justify-between'>
                                        <div className='flex items-center space-x-1'>
                                            <img src={TemplateFileIcon} alt="template file icon"/>
                                            <h3 className='text-xs text-gray-500'>{template.questionnaire_type === QuestionnaireType.SHORTLISTING_QUESTIONS ? "Shortlisting Questions" : template.questionnaire_type === QuestionnaireType.INTERVIEW_QUESTIONS ? "Interview Questions" : ""}</h3>
                                        </div>
                                        <Dropdown trigger={["click"]} menu={{items}}>
                                            <div onClick={(e) => {
                                                e.preventDefault()
                                                setTemplateDetails(template)
                                            }
                                            }>
                                                <Space>
                                                    <Tooltip title='More options'>
                                                        <div
                                                            className='border-2 hover:bg-gray-200 border-transparent p-1 rounded-full'>
                                                            <img src={OptionsVerticalIcon} alt="options vertical icon"/>
                                                        </div>
                                                    </Tooltip>
                                                </Space>
                                            </div>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : <NoRecordFound/>
                    }
                </div>
            </div>
            {showAddTemplate && (
                <Modal
                    title="Add New Template"
                    content={<CreateTemplateQuestions onCancel={() => {
                        setShowAddTemplate(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddTemplate}
                    isHidden={() => setShowAddTemplate(false)}
                    size="medium"
                />
            )}
            {editTemplate && templateDetails && (
                <Modal
                    title={`Edit ${templateDetails?.template_name} Template`}
                    content={<EditTemplateQuestion templateDetails={templateDetails} onCancel={
                        () => {
                            setTemplateDetails(undefined)
                            setEditTemplate(false)
                            setFetchAgain(true)
                        }

                    }/>}
                    isShown={editTemplate}
                    isHidden={() => {
                        setTemplateDetails(undefined)
                        setEditTemplate(false)
                    }}
                    size="medium"
                />
            )
            }
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : deleteError ?
                <ErrorModal error={deleteError.error} onClose={clearDeleteError}/> : ''}
            {(loading || deleteLoading) && <FullPageLoader/>}
        </>
    )
}

export default TemplateQuestions;