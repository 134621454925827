import React, {useEffect, useState} from "react";
import {IPublishedJob} from "../../types/PublishedJob";
import useAxios from "../../hooks/useAxios";
import {IQuestionnaire} from "../../types/questionnaire";
import {QuestionType} from "../../shared/constants/QuestionType";
import {Checkbox, message, Radio, Space} from "antd";
import NoRecordFound from "../../shared/components/NoRecordFound";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import Button from "../../shared/components/Button";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import {useLocation, useNavigate} from "react-router-dom";
import {TemplateFileIcon} from "../../assets/icons";
import SuccessModal from "../../shared/components/SuccessModal";

const Questionnaire = () => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
        {
            question_id: string;
            answer_choices?: string[];
            answer_text?: string;
        }[]
    >([]);

    const location = useLocation();
    const navigate = useNavigate();
    const jobDetails: IPublishedJob = location.state;
    const userData: IProfileUser = getCurrentUserData();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: responseApply,
        error: errorApply,
        loading: loadingApply,
        sendRequest: sendRequestApply,
        clearError: clearErrorApply,
    } = useAxios();

    const getQuestionsHandler = async () => {
        try {
            await sendRequest({
                url: `/requisition/${jobDetails.id}/0/templateQuestions`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const radioOnChange = (questionId: string, checkedValue: string) => {
        setQuestionnaireAnswers((prevState) => [
            ...prevState,
            {
                question_id: questionId,
                answer_choices: [checkedValue],
            },
        ]);
    };

    const checkboxOnChange = (questionId: string, checkedValue: string) => {
        if (questionnaireAnswers.some((item) => item.question_id === questionId)) {
            setQuestionnaireAnswers((prevState) => {
                return prevState.map((value) => {
                    if (value.question_id === questionId) {
                        return {
                            ...value,
                            answer_choices: [...value.answer_choices!, checkedValue],
                        };
                    }
                    return value;
                });
            });
        } else {
            setQuestionnaireAnswers((prevState) => [
                ...prevState,
                {
                    question_id: questionId,
                    answer_choices: [checkedValue],
                },
            ]);
        }
    };

    const onChangeHandler = (questionId: string, inputValue: string) => {
        if (questionnaireAnswers.some((item) => item.question_id === questionId)) {
            setQuestionnaireAnswers((prevState) => {
                return prevState.map((value) => {
                    if (value.question_id === questionId) {
                        return {
                            ...value,
                            answer_text: inputValue,
                        };
                    }
                    return value;
                });
            });
        } else {
            setQuestionnaireAnswers((prevState) => [
                ...prevState,
                {
                    question_id: questionId,
                    answer_text: inputValue,
                },
            ]);
        }
    };

    useEffect(() => {
        getQuestionsHandler();
    }, []);

    const submitJobApplicationHandler = async () => {
        try {
            const payload = {
                requisition: jobDetails.id,
                candidate: userData.userId.id,
                questionnaire_answers: questionnaireAnswers,
            };
            await sendRequestApply({
                url: "/jobApplication",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        if (responseApply?.data) {
            setShowSuccessModal(true);
        }
    }, [responseApply?.data]);

    return (
        <>
            <div className="mx-auto px-4 lg:px-20 xl:px-52 mb-5">
                {response?.data?.length > 0 && (
                    <div
                        className="mb-5 p-5 sm:p-8 bg-userBadgeBg rounded-t-lg rounded-b-md border-t-sidebarBG border-t-8">
                        <h3 className="font-bold text-lg sm:text-xl mb-1.5">{`${jobDetails.title}`}</h3>
                        <div className="flex items-center space-x-2">
                            <img src={TemplateFileIcon} alt="template file icon"/>
                            <p className="text-sm text-gray-700">Help us know you better!</p>
                        </div>
                    </div>
                )}

                {response?.data?.length > 0 ? (
                    response?.data?.map((item: IQuestionnaire) => {
                        return item.question_type === QuestionType.SHORT_ANSWER ? (
                            <div
                                key={item.id}
                                className="mb-5 p-5 sm:p-8 bg-userBadgeBg border-2 border-transparent rounded-md"
                            >
                                <h3 className="text-gray-600 mb-3">{item.question}</h3>
                                <input
                                    onBlur={(e) => onChangeHandler(item.id, e.target.value)}
                                    type="text"
                                    name={item.question.split(" ").join("_").toLowerCase()}
                                    id={item.question.split(" ").join("_").toLowerCase()}
                                    placeholder="Type here"
                                    className="w-full sm:w-3/5 lg:w-2/5 pl-2 py-1 rounded border border-gray-300 text-gray-600 sm:text-sm focus:outline-none"
                                />
                            </div>
                        ) : item.question_type === QuestionType.SINGLE_CHOICE ? (
                            <div
                                key={item.id}
                                className="mb-5 p-5 sm:p-8 bg-userBadgeBg border-2 border-transparent rounded-md"
                            >
                                <h3 className="text-gray-600 mb-3">{item.question}</h3>
                                <Radio.Group>
                                    <Space direction="vertical">
                                        {item?.answer_options?.map((optionItem) => (
                                            optionItem.answer_option ? (
                                                <Radio
                                                    key={optionItem.id}
                                                    value={optionItem.id}
                                                    className="text-gray-500"
                                                    onChange={() => radioOnChange(item.id, optionItem.id)}
                                                >
                                                    {optionItem.answer_option}
                                                </Radio>
                                            ) : ('')
                                        ))}
                                    </Space>
                                </Radio.Group>
                            </div>
                        ) : item.question_type === QuestionType.MULTIPLE_CHOICE ? (
                            <div
                                key={item.id}
                                className="mb-5 p-5 sm:p-8 bg-userBadgeBg border-2 border-transparent rounded-md"
                            >
                                <h3 className="text-gray-600 mb-3">{item.question}</h3>
                                <Checkbox.Group>
                                    <Space direction="vertical">
                                        {item?.answer_options?.map((optionItem) => (
                                            optionItem.answer_option ? (
                                                <Checkbox
                                                    key={optionItem.id}
                                                    value={optionItem.id}
                                                    className="text-gray-500"
                                                    onChange={(e) =>
                                                        checkboxOnChange(item.id, optionItem.id)
                                                    }
                                                >
                                                    {optionItem.answer_option}
                                                </Checkbox>
                                            ) : ('')
                                        ))}
                                    </Space>
                                </Checkbox.Group>
                            </div>
                        ) : (
                            <div
                                key={item.id}
                                className="mb-5 p-5 sm:p-8 bg-userBadgeBg border-2 border-transparent rounded-md"
                            >
                                <h3 className="text-gray-600 mb-3">{item.question}</h3>
                                <textarea
                                    onBlur={(e) => onChangeHandler(item.id, e.target.value)}
                                    rows={4}
                                    className="w-full sm:w-3/5 lg:w-2/5 pl-2 py-1.5 rounded border border-gray-300 text-gray-600 focus:outline-none"
                                    placeholder="Type here"
                                />
                            </div>
                        );
                    })
                ) : (
                    <NoRecordFound/>
                )}

                {response?.data?.length > 0 && (
                    <div className="mt-5 flex justify-end gap-4">
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => navigate("/candidate/job-details", {state: jobDetails})}*/}
                        {/*    className="text-sm bg-dropdownBG px-4 py-2 rounded font-semibold"*/}
                        {/*>*/}
                        {/*    Back*/}
                        {/*</button>*/}
                        <Button
                            type="button"
                            onButtonClick={submitJobApplicationHandler}
                            isValid={!questionnaireAnswers.length}
                            btnText="Submit"
                            loading={loadingApply}
                        />
                    </div>
                )}
            </div>

            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {errorApply && <ErrorModal error={errorApply.error} onClose={clearErrorApply}/>}
            {(loading || loadingApply) && <FullPageLoader/>}
            {showSuccessModal && (
                <SuccessModal
                    title="Successfully Submitted"
                    message={`Thank you for submitting your application for the ${jobDetails.title} role at National Bank of Rwanda! We will review your application and get back to you as soon as possible.`}
                    onClose={() => {
                        setShowSuccessModal(false);
                        message.success("Application Submitted Successfully");
                        navigate("/candidate/my-applications");
                    }}
                />
            )}
        </>
    );
};

export default Questionnaire;
