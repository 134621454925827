import React, {useState} from "react";
import {IPublishedJob} from "../../types/PublishedJob";
import {OfficeChairIcon, SaveIcon} from "../../assets/icons";
import PageTitle from "../../shared/components/PageTitle";
import {useLocation} from "react-router-dom";
import JobApplicationForm from "./JobApplicationForm";
import Modal from "../../shared/components/Modal";
import moment from "moment";
import {RequisitionType} from "../../shared/constants/JobRequisition";
import {Editor} from "react-draft-wysiwyg";
import Button from "../../shared/components/Button";
import {IProfileUser} from "../../types/ProfileUser";

const JobDetails = () => {
    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string
    );

    const location = useLocation();
    const jobDetails: IPublishedJob = location.state;

    return (
        <>
            <PageTitle title={jobDetails?.title}/>
            <div className="px-5 sm:px-10">
                <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between gap-5">
                    {/* Left Section: Job Details */}
                    <div className="w-full lg:max-w-3xl">
                        <div className="mb-3">
                            <h3 className="font-bold text-lg">Job Description</h3>
                            <div>
                                <Editor
                                    contentState={JSON.parse(jobDetails.job_summary)}
                                    readOnly
                                    toolbarHidden
                                    editorClassName="px-3 rounded"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <h3 className="font-bold text-lg">Job Responsibilities</h3>
                            <div className="ml-2">
                                <Editor
                                    contentState={JSON.parse(jobDetails.job_responsibilities)}
                                    readOnly
                                    toolbarHidden
                                    editorClassName="px-3 rounded"
                                />
                            </div>
                        </div>
                        <div>
                            <h3 className="font-bold text-lg">Job Requirements</h3>
                            <div className="ml-2">
                                <Editor
                                    contentState={JSON.parse(jobDetails.job_requirements)}
                                    readOnly
                                    toolbarHidden
                                    editorClassName="px-3 rounded"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Right Section: Summary Card */}
                    <div className="w-full lg:w-1/4 border border-gray-300 rounded-xl p-4">
                        <div className="border border-transparent rounded-xl p-3 bg-jobPostCardBG mb-5">
                            <div className="flex items-center justify-between mb-5">
                                <div className="border border-transparent rounded-3xl bg-white px-2.5 py-1.5">
                                    <h3 className="text-xs">
                                        {moment(jobDetails.application_deadline).format("ll")}
                                    </h3>
                                </div>
                                <img src={SaveIcon} alt="save icon"/>
                            </div>
                            <div className="mb-5">
                                <div className="flex items-center justify-start">
                                    <div className="bg-sidebarBG rounded-full w-2 h-2 mr-1.5"></div>
                                    <span className="text-xs text-gray-600">Open Position</span>
                                </div>
                                <div className="flex items-center justify-between space-x-3">
                                    <h3 className="text-lg font-semibold text-wrap">
                                        {jobDetails.title}
                                    </h3>
                                    <img src={OfficeChairIcon} alt="position icon"/>
                                </div>
                            </div>
                            <div className="flex items-center justify-start flex-wrap gap-2">
                                <div className="border border-gray-500 rounded-3xl px-2 py-1 w-fit">
                                    <h3 className="text-xs">{jobDetails.requisition_reference_number}</h3>
                                </div>
                                <div className="border border-gray-500 rounded-3xl px-2 py-1 w-fit">
                                    <h3 className="text-xs">
                                        {jobDetails.requisition_type === RequisitionType.CONTRACT
                                            ? "Contract"
                                            : jobDetails.requisition_type === RequisitionType.PERMANENT
                                                ? "Permanent"
                                                : jobDetails.requisition_type === RequisitionType.INTERNSHIP
                                                    ? "Internship"
                                                    : jobDetails.requisition_type === RequisitionType.REGULAR
                                                        ? "Regular"
                                                        : jobDetails.requisition_type === RequisitionType.TEMPORARY
                                                            ? "Temporary"
                                                            : jobDetails.requisition_type === RequisitionType.LIMITED_TERM
                                                                ? "Limited Term"
                                                                : " "}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mx-3 mb-5">
                            <div>
                                <h3 className="text-sm font-semibold mb-0.5 underline">Deadline:</h3>
                                <p className="text-xs text-gray-500">
                                    {moment(jobDetails.application_deadline).format("lll")}
                                </p>
                            </div>
                            <div
                                onClick={() => setShowDisclaimer(true)}
                                className="border border-transparent rounded-3xl bg-sidebarBG px-2.5 py-1.5 cursor-pointer"
                            >
                                <h3 className="text-xs font-semibold text-white">Apply now</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Application Modal */}
            {showApplicationForm && (
                <Modal
                    title={`Apply for ${jobDetails?.title}`}
                    titleSize="text-xl"
                    content={
                        <JobApplicationForm
                            jobDetails={jobDetails}
                            onCancel={() => setShowApplicationForm(false)}
                        />
                    }
                    isShown={showApplicationForm}
                    isHidden={() => setShowApplicationForm(false)}
                    size="large"
                />
            )}

            {/* Disclaimer Modal */}
            {showDisclaimer && (
                <Modal
                    title="Disclaimer"
                    titleSize="text-2xl"
                    content={
                        <div>
                            <h3 className="mb-3 text-lg text-gray-700">
                                By clicking on the "<span className="text-sidebarBG">Apply now</span>" button, you agree
                                to the following:
                            </h3>
                            <p className="text-gray-700 ml-3">
                                I <span className='text-sidebarBG'>{userData.userId.names}</span>, hereby declare that
                                the information that I am
                                going to provide in this
                                application is
                                accurate,
                                complete, and true to the best of my knowledge. I understand that any misrepresentation,
                                omission, or false information may result in the disqualification of my application or
                                termination of employment, in case am selected. I also agree to notify National Bank of
                                Rwanda of any changes to my information. By submitting this application, I consent to
                                the processing of my personal data as per the company's privacy policy and data
                                protection guidelines.
                            </p>
                            <div className="my-5 border-b"></div>
                            <div className="mt-5 flex items-center justify-end gap-x-6">
                                <button
                                    onClick={() => setShowDisclaimer(false)}
                                    type="button"
                                    className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold text-black"
                                >
                                    No, I don't agree
                                </button>
                                <Button
                                    type="button"
                                    btnText="Yes, I agree"
                                    onButtonClick={() => {
                                        setShowDisclaimer(false);
                                        setShowApplicationForm(true);
                                    }}
                                />
                            </div>
                        </div>
                    }
                    isShown={showDisclaimer}
                    isHidden={() => setShowDisclaimer(false)}
                    size="large"
                />
            )}
        </>
    );
};

export default JobDetails;
