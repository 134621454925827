import {Select, type SelectProps} from "antd";
import Button from "../../shared/components/Button";
import React, {useEffect, useState} from "react";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import {ITemplate} from "../../types/questionnaire";
import {IJobRequisition} from "../../types/JobRequisition";

interface IProps {
    onCancel: () => void;
    requisition: IJobRequisition;
}

const AssignQuestionTemplate = ({onCancel, requisition}: IProps) => {

    const [selectedQuestionTempl, setSelectedQuestionTempl] = useState<string>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: templateQnRes,
        error: templateQnError,
        loading: templateQnLoading,
        sendRequest: sendTemplateQnReq,
        clearError: clearTemplateQnError
    } = useAxios();

    const questionTemplateOptions: SelectProps["options"] = templateQnRes?.data?.map(
        (item: ITemplate) => ({
            label: item.template_name + ' Question Template',
            value: item.id,
        }),
    );

    const handleQuestionTemplChange = (value: string) => {
        setSelectedQuestionTempl(value);
    }

    const onSubmit = async () => {
        try {
            await sendRequest({
                url: `/requisition/assignTemplate/${selectedQuestionTempl}/${requisition.id}`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    };

    const getQuestionTemplateHandler = async () => {
        try {
            await sendTemplateQnReq({
                url: '/questionnaireTemplate',
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getQuestionTemplateHandler();
    }, []);

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <div>
                <div className="mb-3">
                    <label
                        htmlFor="roles"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Select Privileges:
                    </label>
                    <div className="mt-3">
                        <Select
                            rootClassName="font-Montserrat"
                            loading={templateQnLoading}
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleQuestionTemplChange}
                            options={questionTemplateOptions?.length ? questionTemplateOptions : []}
                        />
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button
                        type="button"
                        btnText="Assign Template"
                        loading={loading}
                        onButtonClick={onSubmit}
                        isValid={!selectedQuestionTempl}
                    />
                </div>
            </div>
            {templateQnError ? <ErrorModal error={templateQnError.error} onClose={clearTemplateQnError}/> : error ?
                <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    );
}

export default AssignQuestionTemplate;