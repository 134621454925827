import React from "react";
import { FacebookIcon, InstagramIcon, TwitterIcon, YoutubeIcon, FooterLogo, NavbarLogo } from "../../assets/icons";

const Footer = () => {
    return (
        <>
            {/* Footer */}
            <div className="bg-badgeBG rounded-t-lg">
                {/* Top Section */}
                <div className="py-6 px-4 lg:px-12">
                    <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-6 lg:space-y-0 lg:space-x-16">
                        {/* Left Section: Centered Logo */}
                        <div className="flex justify-center items-center lg:justify-start lg:items-center h-full">
                            <img src={NavbarLogo} alt="Bank Logo" className="h-20 w-auto" />
                        </div>

                        {/* Center and Right Section: Quick Links and Get in Touch */}
                        <div className="flex flex-col lg:flex-row justify-between space-y-6 lg:space-y-0 lg:space-x-12">
                            {/* Quick Links */}
                            <div className="text-primary text-center lg:text-left">
                                <h3 className="mb-3 font-bold text-base lg:text-lg">Quick Links</h3>
                                <ul className="">
                                {[
                                    { href: "https://www.bnr.rw/aboutus", label: "Vision and Mission" },
                                    { href: "https://www.bnr.rw/Board", label: "Board of Directors" },
                                    { href: "https://www.bnr.rw/directors", label: "Organization" },
                                    { href: "https://www.bnr.rw/faqs", label: "FAQ" },
                                ].map((link, index) => (
                                    <li key={index}>
                                        <a href={link.href} className="text-sm cursor-pointer hover:underline">
                                            {link.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            </div>


                            {/* Get in Touch */}
                            <div className="text-primary text-center lg:text-left">
                                <h3 className="mb-3 font-bold text-base lg:text-lg">Get in Touch</h3>
                                <ul className="space-y-2">
                                    <li className="text-sm">KN 6 AV 4</li>
                                    <li className="text-sm">P.O Box 531</li>
                                    <li className="text-sm">info@bnr.rw</li>
                                    <li className="text-sm">(+250) 788 199 000</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Divider */}
                <div className="border-t border-primary mx-4 lg:mx-12"></div>

                {/* Bottom Section */}
                <div className="py-4 px-4 lg:px-12 flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
                {/* Footer Text */}
                <p className="text-primary text-sm lg:text-sm text-center lg:text-left">
                    @ 2024 National Bank of Rwanda
                </p>

                {/* Social Media Links */}
                <div className="flex space-x-3 items-center">
                    <p className="text-primary text-sm lg:text-sm text-center lg:text-left mr-2">
                        Follow us
                    </p>
                    {[
                        { href: "https://www.youtube.com/@nationalbankofrwandanbr2038", icon: YoutubeIcon, alt: "YouTube Icon" },
                        { href: "https://x.com/centralbankrw", icon: TwitterIcon, alt: "Twitter Icon" },
                        { href: "https://www.instagram.com/centralbankrw/", icon: InstagramIcon, alt: "Instagram Icon" },
                        { href: "https://www.facebook.com/CentralBankRw/", icon: FacebookIcon, alt: "Facebook Icon" },
                    ].map((link, index) => (
                        <a key={index} href={link.href} target="_blank" rel="noopener noreferrer">
                            <img
                                src={link.icon}
                                alt={link.alt}
                                className="h-5 w-5 cursor-pointer hover:opacity-80"
                            />
                        </a>
                    ))}
                </div>
            </div>

            </div>
        </>
    );
};

export default Footer;
