import React, {useEffect, useState} from "react";
import useAxios from "../../hooks/useAxios";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import {
    AssignRole, BlackLoadingSpinner,
    PageLoadingSpinner,
    RemoveRole,
} from "../../assets/icons";
import NoRecordFound from "../../shared/components/NoRecordFound";
import ErrorModal from "../../shared/components/ErrorModal";
import {IPrivileges} from "../../types/privileges";


interface IProps {
    roleDetails: IPrivileges | undefined;
}

const ContainerHeight = 400;

const PrivilegesAssignedToRole = ({roleDetails}: IProps) => {

    const [fetchAgain, setFetchAgain] = useState(false);

    const [data, setData] = useState<IPrivileges[]>([]);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: privilegeRes,
        error: privilegeError,
        loading: privilegeLoading,
        sendRequest: sendPrivilegeReq,
        clearError: clearPrivilegeError
    } = useAxios();

    const removePrivilegeHandler = async (privilegeId: string) => {

        try {
            await sendPrivilegeReq({
                url: `/role/removePrivileges/${roleDetails?.id}/${privilegeId}`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        if (privilegeRes?.data) {
            setFetchAgain(true);
        }
    }, [privilegeRes?.data]);

    const getPrivilegesHandler = async () => {
        try {
            await sendRequest({
                url: `/role/${roleDetails?.id}/privilege`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getPrivilegesHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getPrivilegesHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} roles loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getPrivilegesHandler();
        }
    };

    return (
        <>
            {(loading) && <img className="mx-auto" src={PageLoadingSpinner} alt="loader"/>}
            {(!loading) && (
                <>
                    {(response?.data?.length > 0) ? (
                            <div className='py-10 px-3'>
                                <List size='small'>
                                    <VirtualList
                                        data={data}
                                        height={ContainerHeight}
                                        itemHeight={47}
                                        itemKey="id"
                                        onScroll={onScroll}
                                    >
                                        {(item: IPrivileges) => (
                                            <List.Item key={item.id}>
                                                <List.Item.Meta
                                                    avatar={<Avatar src={AssignRole}/>}
                                                    title={
                                                        <span
                                                            className="font-Montserrat text-gray-700 font-semibold text-sm">
                            {item.name}
                          </span>
                                                    }
                                                    description={
                                                        <span className="font-Montserrat text-gray-400 text-xs">
                            {item.description.split('_').join(' ')}
                          </span>
                                                    }
                                                />
                                                <div>
                                                    <button
                                                        onClick={async () => await removePrivilegeHandler(item.id)}
                                                        key={item.id}
                                                        className='bg-gray-100 rounded-md py-0.5 px-3 flex items-center justify-center space-x-2 tracking-wider border border-transparent active:border-sidebarBG duration-500 active:text-black'>
                                                        {privilegeLoading ? (<>
                                                            <img src={BlackLoadingSpinner} alt="white loading spinner"/>
                                                            <span
                                                                className="ext-gray-500 text-gray-500">Please wait...</span>

                                                        </>) : (<>
                                                            <img src={RemoveRole} alt=" remove icon"/>
                                                            <span
                                                                className='text-gray-500 text-xs'>Remove Privilege</span>
                                                        </>)}
                                                    </button>
                                                </div>
                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List>
                            </div>
                        ) :
                        <div className='mb-20'>
                            <NoRecordFound/>
                        </div>
                    }
                </>
            )}
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : privilegeError ?
                <ErrorModal error={privilegeError.error} onClose={clearPrivilegeError}/> : ""}
        </>
    )
}

export default PrivilegesAssignedToRole;