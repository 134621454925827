import React, {useEffect, useState} from "react";
import {Benefit_KeyCompetency, IJobProfile} from "../../types/JobProfile";
import {Avatar, List, message, Select, type SelectProps} from "antd";
import VirtualList from "rc-virtual-list";
import {
    BenefitsIcon,
    BlackLoadingSpinner, ClearIcon,
    PageLoadingSpinner,
    PlusIcon,
    RemoveRole,
    SkillsIcon,
    WhiteLoadingSpinner
} from "../../assets/icons";
import useAxios from "../../hooks/useAxios";
import {IAllowance} from "../../types/allowance";
import ErrorModal from "../../shared/components/ErrorModal";

interface IProps {
    jobProfile: IJobProfile
}

const ContainerHeight = 400;

const AddBenefits = ({jobProfile}: IProps) => {

    const [selectedOption, setSelectedOption] = React.useState<string>();
    const [data, setData] = useState<Benefit_KeyCompetency[]>([]);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: benefitsRes,
        error: benefitsError,
        loading: benefitsLoading,
        sendRequest: sendBenefitsReq,
        clearError: clearBenefitsError,
    } = useAxios();

    const {
        response: jobProfileRes,
        error: jobProfileError,
        loading: jobProfileLoading,
        sendRequest: sendJobProfileReq,
        clearError: clearJobProfileError,
    } = useAxios();

    const options: SelectProps["options"] = benefitsRes?.data?.map(
        (benefit: IAllowance) => ({
            label: benefit.allowance_name,
            value: benefit.id,
        }),
    );

    const handleChange = (value: string) => {
        setSelectedOption(value);
    };

    const onSubmit = async () => {
        try {
            await sendRequest({
                url: `/jobProfile/${jobProfile.id}/assignBenefit/${selectedOption}`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    };

    const removeBenefitHandler = async (benefitId?: string) => {

        try {
            await sendRequest({
                url: `/jobProfile/${jobProfile.id}/removeBenefit/${benefitId}`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    }

    const getBenefitsHandler = async () => {
        try {
            await sendBenefitsReq({
                url: `/jobProfile/${jobProfile.id}/notAssignedBenefits`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const getJobProfilesHandler = async () => {
        try {
            await sendJobProfileReq({
                url: `/jobProfile/find/${jobProfile.id}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getBenefitsHandler();
    }, []);

    useEffect(() => {
        getJobProfilesHandler()
    }, []);

    useEffect(() => {
        if (jobProfileRes?.data?.benefits?.length > 0) {
            setData(jobProfileRes?.data?.benefits);
            message.success(`${jobProfileRes?.data?.benefits?.length} Benefits loaded!`);
        }
    }, [jobProfileRes?.data?.benefits]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getJobProfilesHandler();
        }
    };

    useEffect(() => {
        if (response?.data || response?.status === 200) {
            setSelectedOption(undefined);
            getBenefitsHandler();
            getJobProfilesHandler();
        }
    }, [response]);

    return (
        <>
            <div className='pt-3 pb-10 px-3'>
                <div className='border-2 border-transparent py-3 px-5 bg-dashboardCardBG rounded-lg'>
                    <div className='flex items-center justify-between'>
                        <div className='w-1/2'>
                            <Select
                                rootClassName="font-Montserrat"
                                loading={benefitsLoading}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleChange}
                                options={options?.length ? options : []}
                            />
                        </div>
                        <button
                            className="flex items-center justify-center border border-transparent rounded bg-sidebarBG py-1 px-3 space-x-1 disabled:text-white disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-50"
                            onClick={onSubmit}
                            disabled={!selectedOption}
                        >
                            {loading ? (
                                <div className="flex items-center justify-center">
                                    <img src={WhiteLoadingSpinner} alt="white loading spinner"/>
                                    <span className="ml-2 text-white">Please wait...</span>
                                </div>
                            ) : (
                                <>
                                    <img src={PlusIcon} alt="plus icon"/>{" "}
                                    <span className="font-semibold text-sm text-white font-Montserrat">
                                        Add Benefit
                                    </span>
                                </>
                            )}

                        </button>
                    </div>
                </div>
                <div className='py-10'>
                    {jobProfileLoading && <img className="mx-auto" src={PageLoadingSpinner} alt="loader"/>}
                    {jobProfileRes?.data?.benefits?.length > 0 ? (
                        <List size='small'>
                            <VirtualList
                                data={data}
                                height={ContainerHeight}
                                itemHeight={47}
                                itemKey="id"
                                onScroll={onScroll}
                            >
                                {(item: Benefit_KeyCompetency) => (
                                    <List.Item key={item.id}>
                                        <List.Item.Meta
                                            avatar={<Avatar src={BenefitsIcon}/>}
                                            title={
                                                <span
                                                    className="font-Montserrat text-gray-700 font-semibold text-sm">
                            {item.allowance.allowance_name}
                          </span>
                                            }
                                            description={
                                                <span className="font-Montserrat text-gray-400 text-xs">
                            {item?.allowance?.description ? item.allowance.description : "Key Competency"}
                          </span>
                                            }
                                        />
                                        <div>
                                            <button
                                                onClick={async () => await removeBenefitHandler(item.allowance.id)}
                                                key={item.id}
                                                className='bg-gray-100 rounded py-1 px-3 flex items-center justify-center space-x-2 tracking-wider hover:opacity-70 border border-transparent active:border-sidebarBG duration-500 active:text-black'>
                                                {loading ? (<>
                                                    <img src={BlackLoadingSpinner} alt="white loading spinner"/>
                                                    <span
                                                        className="ext-gray-500 text-gray-500">Please wait...</span>

                                                </>) : (<>
                                                    <img src={ClearIcon} alt=" remove icon"/>
                                                    <span
                                                        className='text-gray-500 text-xs'>Remove Benefit</span>
                                                </>)}
                                            </button>
                                        </div>
                                    </List.Item>
                                )}
                            </VirtualList>
                        </List>
                    ) : null}
                </div>
            </div>
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : benefitsError ?
                <ErrorModal error={benefitsError.error} onClose={clearBenefitsError}/> : jobProfileError ?
                    <ErrorModal error={jobProfileError.error} onClose={clearJobProfileError}/> : " "}
        </>
    )
}

export default AddBenefits;