import React, {useEffect, useState} from "react";
import {ITemplate, QuestionnaireType} from "../../types/questionnaire";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Select, type SelectProps} from "antd";
import {questionnaireTypeOptions} from "../../shared/constants/SelectOptions";
import Button from "../../shared/components/Button";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import {IJobProfile} from "../../types/JobProfile";

interface IProps {
    onCancel: () => void;
    templateDetails: ITemplate
}

interface IFormData {
    template_name?: string;
    description?: string;
}

const schema = yup
    .object({
        template_name: yup.string(),
    })
    .required();

const EditTemplateQuestion = ({onCancel, templateDetails}: IProps) => {

    const [selectedQuestionnaireType, setSelectedQuestionnaireType] = useState<number>(templateDetails.questionnaire_type);
    const [selectedJobProfile, setSelectedJobProfile] = useState<string>(templateDetails.template_name);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: jobProfileRes,
        error: jobProfileError,
        loading: jobProfileLoading,
        sendRequest: sendJobProfileReq,
        clearError: clearJobProfileError
    } = useAxios();

    const jobProfileOptions: SelectProps["options"] = jobProfileRes?.data?.map(
        (profile: IJobProfile) => ({
            label: profile.title,
            value: profile.title,
        }),
    );

    const handleQuestionnaireTypeChange = (value: any) => {
        setSelectedQuestionnaireType(value)
    }

    const handleJobProfileChange = (value: string) => {
        setSelectedJobProfile(value);
    };

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });


    const onSubmit = async (data: IFormData) => {
        try {
            await sendRequest({
                url: `/questionnaireTemplate/${templateDetails.id}`,
                method: "PUT",
                data: {
                    template_name: selectedJobProfile,
                    description: data.description,
                    questionnaire_type: selectedQuestionnaireType
                },
            });
        } catch (err) {
        }
    }

    const getJobProfileHandler = async () => {
        try {
            await sendJobProfileReq({
                url: "/jobProfile",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getJobProfileHandler();
    }, []);

    if (response?.data) {
        onCancel()
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="template_name"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Template name:
                    </label>
                    <div className="mt-3">
                        <Select
                            defaultValue={templateDetails.template_name}
                            rootClassName="font-Montserrat"
                            loading={jobProfileLoading}
                            allowClear
                            showSearch
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleJobProfileChange}
                            options={jobProfileOptions?.length ? jobProfileOptions.filter((item) => item.value !== templateDetails.template_name) : []}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="questionnaire_type"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Questionnaire Type:
                    </label>
                    <div className="mt-3">
                        <Select
                            rootClassName="font-Montserrat"
                            defaultValue={templateDetails.questionnaire_type === QuestionnaireType.SHORTLISTING_QUESTIONS ? "SHORTLISTING QUESTIONS" : templateDetails.questionnaire_type === QuestionnaireType.INTERVIEW_QUESTIONS ? "INTERVIEW QUESTIONS" : ""}
                            loading={!questionnaireTypeOptions?.length}
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Please select"
                            onChange={handleQuestionnaireTypeChange}
                            options={questionnaireTypeOptions}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Description:
                    </label>
                    <div className="mt-2">
            <textarea
                {...register("description")}
                id="description"
                name="description"
                defaultValue={templateDetails.description}
                placeholder='Type here'
                rows={3}
                className={`pl-2 block w-full ${errors.description && "border-red-500"} rounded-md border-2 py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
            />
                        <p className="text-red-500 text-xs">{errors.description?.message}</p>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Template" isValid={!selectedJobProfile} loading={loading}/>
                </div>
            </form>
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : jobProfileError ?
                <ErrorModal error={jobProfileError.error} onClose={clearJobProfileError}/> : ''}
        </>
    );
}

export default EditTemplateQuestion;