import React from "react";
import DetailRow from "../../shared/components/DetailRow";
import {HiringModel, Justification, RequisitionType} from "../../shared/constants/JobRequisition";

const ExpandedJobRequisition = ({data}: any) => {
    return (
        <div className='max-w-xl mx-auto p-5'>
            <h3 className='bg-userBadgeBg text-gray-600 font-semibold text-lg text-center'>
                Other Details
            </h3>
            <DetailRow
                label='Justification'
                value={Justification[data.justification].split('_').join(' ')}
            />
            <DetailRow label='Approved By' value={data.hiring_manager.names}/>
            <DetailRow label='Hiring Model' value={HiringModel[data.hiring_model].split('_').join(' ')}/>
            <DetailRow label='Reference No.'
                       value={data.requisition_reference_number ? data.requisition_reference_number : 'N/A'}/>
            <DetailRow label='Requisition Type' value={RequisitionType[data.requisition_type].split('_').join(' ')}/>
            <DetailRow label='Shortlisting Marks' value={data.shortlisting_marks}/>
            <DetailRow label='Number of Openings' value={data.number_of_openings}/>
            <DetailRow label='is Experience Mandatory' value={data.is_experience_required ? 'YES' : 'NO'}/>
        </div>
    )
}

export default ExpandedJobRequisition;