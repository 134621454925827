import React, {useEffect, useState} from "react";
import {TemplateFileIcon} from "../../assets/icons";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {IQuestionnaire, QuestionnaireType} from "../../types/questionnaire";
import {QuestionType} from "../../shared/constants/QuestionType";
import {Checkbox, GetProp, Radio, RadioChangeEvent, Space} from "antd";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {useLocation} from "react-router-dom";
import {IProfileUser, UserType} from "../../types/ProfileUser";

const ViewQuestions = () => {

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string
    );

    const {state} = useLocation()

    const {response, loading, error, sendRequest, clearError} = useAxios()

    const [value, setValue] = useState<number>();
    const radioOnChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const checkboxOnChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
        console.log('checked = ', checkedValues);
    };

    const getQuestionnaireHandler = async () => {
        try {
            await sendRequest({
                url: `/questionnaireTemplate/${state?.templateDetails.id}/questions`,
                method: "GET"
            })
        } catch (err) {
        }
    }

    useEffect(() => {
        getQuestionnaireHandler();
    }, []);

    return (
        <>
            <div className='mx-52 pb-10'>
                <div className='mb-5 p-8 bg-userBadgeBg rounded-t-lg rounded-b-md border-t-sidebarBG border-t-8'>
                    <h3 className='font-bold text-lg mb-1.5'>{state?.templateDetails?.template_name ? state?.templateDetails?.template_name + ' Questionnaire' : ''}</h3>
                    <div className='flex items-center space-x-1.5'>
                        <img src={TemplateFileIcon} alt="template file icon"/>
                        <p className='text-sm text-gray-700'>{state?.templateDetails?.questionnaire_type === QuestionnaireType.SHORTLISTING_QUESTIONS ? "Shortlisting Questions" : state?.templateDetails?.questionnaire_type === QuestionnaireType.INTERVIEW_QUESTIONS ? "Interview Questions" : "No Available Questionnaire"}</p>
                    </div>
                </div>
                {response?.data?.length > 0 ? (
                    response?.data?.map((item: IQuestionnaire, index: number) => (
                        item.question_type === QuestionType.SHORT_ANSWER ? (
                            <div key={item.id}
                                 className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <h3 className='text-gray-600 mb-3'>{item.question}</h3>
                                <input
                                    type="text"
                                    name={item.question.split(" ").join("_").toLowerCase()}
                                    id={item.question.split(" ").join("_").toLowerCase()}
                                    placeholder='Type here'
                                    className={`pl-2 w-2/5 block rounded border-2 border-transparent py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                />
                            </div>
                        ) : item.question_type === QuestionType.SINGLE_CHOICE ? (
                            <div key={item.id}
                                 className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <h3 className='text-gray-600 mb-3'>{item.question}</h3>
                                <div className='flex items-center space-x-3'>
                                    <Radio.Group onChange={radioOnChange} value={value}>
                                        <Space direction="vertical">
                                            {item?.answer_options?.map((optionItem, optionIndex) => (
                                                optionItem.answer_option && (
                                                    <Radio disabled key={optionItem.id} value={optionIndex}>
                                                        <div className='flex items-center justify-center space-x-3'>
                                                            <span className='text-gray-600'>
                                                                {optionItem.answer_option}
                                                            </span>
                                                            {userData.userId.user_type !== UserType.CANDIDATE && (
                                                                <span className='text-gray-400 text-xs'>
                                                                {optionItem.weight + '/' + item.weight}
                                                            </span>
                                                            )}
                                                        </div>
                                                    </Radio>
                                                )
                                            ))}
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </div>
                        ) : item.question_type === QuestionType.MULTIPLE_CHOICE ? (
                            <div key={item.id}
                                 className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <h3 className='text-gray-600 mb-3'>{item.question}</h3>
                                <div className='flex items-center space-x-3'>
                                    <Checkbox.Group onChange={checkboxOnChange}>
                                        <Space direction={"vertical"}>
                                            {item?.answer_options?.map((optionItem, optionIndex) => (
                                                optionItem.answer_option &&
                                                <Checkbox disabled key={optionItem.id}
                                                          value={optionIndex}>
                                                    <div className='flex items-center justify-center space-x-3'>
                                                        <span className='text-gray-600'>
                                                            {optionItem.answer_option}
                                                        </span>
                                                        {userData.userId.user_type !== UserType.CANDIDATE && (
                                                            <span className='text-gray-400 text-xs'>
                                                            {optionItem.weight + '/' + item.weight}
                                                        </span>
                                                        )}
                                                    </div>
                                                </Checkbox>
                                            ))}
                                        </Space>
                                    </Checkbox.Group>
                                </div>
                            </div>
                        ) : item.question_type === QuestionType.PARAGRAPH ? (
                            <div key={item.id}
                                 className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <h3 className='text-gray-600 mb-3'>{item.question}</h3>
                                <textarea
                                    name={item.question.split(" ").join("_").toLowerCase()}
                                    id={item.question.split(" ").join("_").toLowerCase()}
                                    placeholder='Type here'
                                    rows={4}
                                    className={`pl-2 w-3/5 block rounded border-2 border-transparent py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                                    defaultValue={""}
                                />
                            </div>
                        ) : ' '
                    ))
                ) : <NoRecordFound/>
                }
            </div>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    );
}

export default ViewQuestions;