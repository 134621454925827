import React from "react";

interface Props {
    children: React.ReactNode | any;
}

interface DropdownItemProps
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children: React.ReactNode;
}

const useToggle = () => {
    const [show, setShow] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement>(null);

    const toggle = React.useCallback(() => {
        setShow((prevState) => !prevState);
    }, []);

    // close dropdown when you click outside
    React.useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (!ref.current?.contains(event.target)) {
                if (!show) return;
                setShow(false);
            }
        };
        window.addEventListener("click", handleOutsideClick);
        return () => window.removeEventListener("click", handleOutsideClick);
    }, [show, ref]);

    // close dropdown when you click on "ESC" key
    React.useEffect(() => {
        const handleEscape = (event: any) => {
            if (!show) return;

            if (event.key === "Escape") {
                setShow(false);
            }
        };
        document.addEventListener("keyup", handleEscape);
        return () => document.removeEventListener("keyup", handleEscape);
    }, [show]);

    return {
        show,
        toggle,
        ref,
    };
};

const style = {
    menu: `block z-40 fixed overflow-x-visible overflow-y-visible right-0 min-w-28 lg:min-w-60 float-right py-2 px-0 text-left rounded-b-sm mt-0.5 mb-0 mx-0 bg-white shadow-lg`,
    item: `flex items-center justify-start w-full px-3 lg:px-8 text-xs lg:text-sm font-medium text-gray-600 border-0 hover:bg-gray-100 cursor-pointer`,
};

export function DropdownTable({ children }: Props) {
    const { show, toggle, ref } = useToggle();
    /* First child contains the dropdown toggle */
    const dropdownToggle = children[0];

    /* Second child contains the dropdown menu */
    const dropdownMenu = children[1];

    return (
        <>
            <button
                onClick={toggle}
                type="button"
                ref={ref as any}
                className={`py-1 flex items-center justify-center text-gray-600 font-medium w-20 hover:bg-gray-200 rounded transition duration-150 ease-in-out ${
                    show ? "bg-gray-200 text-white" : ""
                }`}
                aria-expanded="true"
                aria-haspopup="true"
            >
                {dropdownToggle}
            </button>
            {show && <>{dropdownMenu}</>}
        </>
    );
}

export function DropdownToggle({ children }: Props) {
    return <>{children}</>;
}

export function DropdownMenu({ children }: Props) {
    return (
        <div className="">
            <div
                style={{ transform: "translate3d(0px, 3px, 0px)" }}
                className={style.menu}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                {children}
            </div>
        </div>
    );
}

/* You can wrap the 'a' tag with Link if you are using either Create-React-App, Next.js or Gatsby */
export function DropdownItem({ children }: DropdownItemProps) {
    return (
        <button tabIndex={0} className={style.item} role="menuitem">
            {children}
        </button>
    );
}

export function DropdownDivider() {
    return <hr className="my-2 text-gray-300" />;
}
