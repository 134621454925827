import React from "react";
import {IGrade} from "../../types/grade";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";

interface IProps {
    onCancel: () => void;
    gradeDetails: IGrade | undefined;
}

interface IFormData {
    description: string;
    grade_code: string;
}

const schema = yup
    .object({
        grade_code: yup.string().required("Grade name is required"),
        description: yup.string().required("Description is required"),
    })
    .required();

const EditGrade = ({onCancel, gradeDetails}: IProps) => {
    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                grade_code: data.grade_code,
                description: data.description,
            };
            await sendRequest({
                url: `/settings/grade/${gradeDetails?.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    };

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="grade_code"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Grade name:
                    </label>
                    <div className="mt-3">
                        <input
                            {...register("grade_code")}
                            type="text"
                            defaultValue={gradeDetails?.grade_code}
                            name="grade_code"
                            id="title"
                            className={`pl-2 block w-full rounded ${errors.grade_code && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.grade_code?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Description:
                    </label>
                    <div className="mt-2">
            <textarea
                {...register("description")}
                id="description"
                name="description"
                rows={3}
                className={`pl-2 block w-full ${errors.description && "border-red-500"} rounded-md border-2 py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                defaultValue={gradeDetails?.description}
            />
                        <p className="text-red-500 text-xs">{errors.description?.message}</p>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Changes" loading={loading}/>
                </div>
            </form>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
        </>
    );
};

export default EditGrade;