import React, {useState} from "react";
import {AddQnIcon, RemoveIcon, TemplateFileIcon} from "../../assets/icons";
import {questionTypeOptions} from "../../shared/constants/SelectOptions";
import {Select, Checkbox, Radio, Space, GetProp, RadioChangeEvent, Tooltip} from "antd";
import {QuestionType} from "../../shared/constants/QuestionType";
import {useLocation, useNavigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";

const AddTemplateQuestions = () => {

    const {state} = useLocation()

    const navigate = useNavigate()

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const [question, setQuestion] = useState("");
    const [questionScore, setQuestionScore] = useState<number>();
    const [optionScore, setOptionScore] = useState<number>();
    const [inputs, setInputs] = useState<{
        questionInputLabel: string,
        questionInputFieldName: string,
        score?: number,
        questionType: number | undefined,
        options?: { option: string, score?: number }[]
    }[]>([]);
    const [radioOptions, setRadioOptions] = useState<{ option: string, score?: number }[]>([{option: "", score: 0}]);
    const [checkboxOptions, setCheckboxOptions] = useState<{ option: string, score?: number }[]>([{
        option: "",
        score: 0
    }]);
    const [questionType, setQuestionType] = useState<number>();
    const [optionQuestion, setOptionQuestion] = useState("");

    const [value, setValue] = useState<number>();
    const radioOnChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const checkboxOnChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
        console.log('checked = ', checkedValues);
    };

    const handleAddInput = (questionInputValue: string, scoreValue?: number, options?: {
        option: string;
        score?: number;
    }[]) => {

        setInputs([...inputs, {
            questionInputLabel: questionInputValue,
            questionInputFieldName: questionInputValue.split(" ").join("_").toLowerCase(),
            score: scoreValue,
            questionType: questionType,
            options
        }]);

        /* Emptying adding question input fields, after adding question */
        setQuestion("");
        setQuestionScore(NaN);
        setQuestionType(undefined);
        setRadioOptions([{option: "", score: 0}])
        setCheckboxOptions([{option: "", score: 0}])

    };

    const handleAddRadioOption = (option: string, score?: number) => {
        setRadioOptions([...radioOptions, {option, score}]);
        setOptionQuestion("");
    };

    const handleAddCheckboxOption = (option: string, score?: number) => {
        setCheckboxOptions([...checkboxOptions, {option, score}]);
        setOptionQuestion("");
    }

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    //     let {name, value} = event.target;
    //     let onChangeValue: any = [...inputs];
    //     onChangeValue[index][name] = value;
    //     setInputs(onChangeValue);
    // };

    const handleDeleteInput = (index: number) => {
        const newArray = [...inputs];
        newArray.splice(index, 1);
        setInputs(newArray);
    };

    const handleDeleteRadioOption = (index: number) => {
        let newArray = [...radioOptions];
        newArray.splice(index, 1);
        setRadioOptions(newArray);
    };

    const handleDeleteCheckboxOption = (index: number) => {
        let newArray = [...checkboxOptions];
        newArray.splice(index, 1);
        setCheckboxOptions(newArray);
    }

    const questionTypeChangeHandler = (value: number) => {
        setQuestionType(value);
    }


    const onSubmit = async () => {
        try {
            await sendRequest({
                url: "/questionnaireTemplate/addQuestion",
                method: "POST",
                data: inputs.map((item) => (
                    {
                        template: state.templateDetails.id,
                        question: item.questionInputLabel,
                        question_type: item.questionType,
                        total_weight: item.score,
                        answer_options: item.options?.length ? item.options.map((optionItem) => ({
                            weight: optionItem.score,
                            answer_option: optionItem.option
                        })) : []

                    }
                )),
            });
        } catch (err) {
        }
    }

    if (response?.status === 201) {
        navigate('/admin/template-questions')
    }

    return (
        <>
            <div className='mx-52'>
                <div className='mb-5 p-8 bg-userBadgeBg rounded-t-lg rounded-b-md border-t-sidebarBG border-t-8'>
                    <h3 className='font-bold text-lg mb-1.5'>Questionnaire Template Form</h3>
                    <div className='flex items-center space-x-1.5'>
                        <img src={TemplateFileIcon} alt="template file icon"/>
                        <p className='text-sm text-gray-700'>Shortlisting Questions</p>
                    </div>
                </div>
                {
                    inputs.map((item, index) => (
                        item.questionType === QuestionType.SHORT_ANSWER ? (
                            <div key={index} className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <h3 className='text-gray-600 mb-3'>{item.questionInputLabel}</h3>
                                <div className='flex items-center space-x-3'>
                                    <input
                                        type="text"
                                        name={item.questionInputFieldName}
                                        id={item.questionInputFieldName}
                                        placeholder='Type here'
                                        className={`pl-2 w-2/5 block rounded border-2 border-transparent py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                                    />
                                    <button onClick={() => handleDeleteInput(index)} className='cursor-pointer w-8'>
                                        <img src={RemoveIcon} alt="remove question icon"/>
                                    </button>
                                </div>
                            </div>
                        ) : item.questionType === QuestionType.SINGLE_CHOICE ? (
                            <div key={index} className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <div className='flex items-center mb-3 space-x-3'>
                                    <h3 className='text-gray-600'>{item.questionInputLabel}</h3>
                                    <Tooltip title='Discard Question'>
                                        <button onClick={() => handleDeleteInput(index)} className='cursor-pointer w-6'>
                                            <img src={RemoveIcon} alt="remove question icon"/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className='flex items-center space-x-3'>
                                    <Radio.Group onChange={radioOnChange} value={value}>
                                        <Space direction="vertical">
                                            {item?.options?.map((optionItem, optionIndex) => (
                                                optionItem.option && (
                                                    <Radio key={optionIndex} value={optionIndex}>
                                                        <div className='flex items-center justify-center space-x-3'>
                                                            <span className='text-gray-600'>{optionItem.option}</span>
                                                            <span
                                                                className='text-xs text-gray-500'>{optionItem.score + '/' + item.score}</span>
                                                        </div>
                                                    </Radio>

                                                )
                                            ))}
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </div>
                        ) : item.questionType === QuestionType.MULTIPLE_CHOICE ? (
                            <div key={index} className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <div className='flex items-center mb-3 space-x-3'>
                                    <h3 className='text-gray-600'>{item.questionInputLabel}</h3>
                                    <Tooltip title='Discard Question'>
                                        <button onClick={() => handleDeleteInput(index)} className='cursor-pointer w-6'>
                                            <img src={RemoveIcon} alt="remove question icon"/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className='flex items-center space-x-3'>
                                    <Checkbox.Group onChange={checkboxOnChange}>
                                        <Space direction={"vertical"}>
                                            {item?.options?.map((optionItem, optionIndex) => (
                                                optionItem.option &&
                                                <Checkbox key={optionIndex} value={optionIndex}>
                                                    <div className='flex items-center justify-center space-x-3'>
                                                        <span className='text-gray-600'>{optionItem.option}</span>
                                                        <span
                                                            className='text-gray-500 text-xs'>{optionItem.score + '/' + item.score}</span>
                                                    </div>
                                                </Checkbox>
                                            ))}
                                        </Space>
                                    </Checkbox.Group>
                                </div>
                            </div>
                        ) : item.questionType === QuestionType.PARAGRAPH ? (
                            <div key={index} className='mb-5 p-8 bg-userBadgeBg border-2 border-transparent rounded-md'>
                                <div className='flex items-center mb-3 space-x-3'>
                                    <h3 className='text-gray-600'>{item.questionInputLabel}</h3>
                                    <button onClick={() => handleDeleteInput(index)} className='cursor-pointer w-6'>
                                        <img src={RemoveIcon} alt="remove question icon"/>
                                    </button>
                                </div>
                                <textarea
                                    name={item.questionInputFieldName}
                                    id={item.questionInputFieldName}
                                    placeholder='Type here'
                                    rows={4}
                                    className={`pl-2 w-3/5 block rounded border-2 border-transparent py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                                    defaultValue={""}
                                />
                            </div>
                        ) : ''

                    ))
                }
                <div className='mb-5 p-8 bg-userBadgeBg rounded-r-lg border-l-sidebarBG border-l-8'>
                    <div className='flex items-center justify-between space-x-3'>
                        <input
                            type="text"
                            value={question}
                            name="question"
                            id="question"
                            onChange={(e) => setQuestion(e.target.value)}
                            placeholder='Question'
                            className={`pl-2 block w-2/5 rounded border-2 border-transparent py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <div className='flex items-center justify-between space-x-3'>
                            <input
                                type="number"
                                value={questionScore}
                                name="score"
                                id="score"
                                onChange={(e) => setQuestionScore(parseInt(e.target.value))}
                                placeholder='Score'
                                className={`pl-2 block w-1/3 rounded border-2 border-transparent px-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none disabled:cursor-not-allowed`}
                            />
                            <Select
                                rootClassName="font-Montserrat"
                                loading={!questionTypeOptions?.length}
                                value={questionType}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Select type"
                                onChange={questionTypeChangeHandler}
                                options={questionTypeOptions}
                            />
                            <Tooltip title='Add Question'>
                                <button
                                    disabled={!question || !questionType || !questionScore || (questionType === QuestionType.SINGLE_CHOICE && radioOptions.length <= 1) || (questionType === QuestionType.MULTIPLE_CHOICE && checkboxOptions.length <= 1)}
                                    onClick={
                                        questionType === QuestionType.SINGLE_CHOICE ?
                                            () => handleAddInput(question, questionScore, radioOptions) : questionType === QuestionType.MULTIPLE_CHOICE ? () => handleAddInput(question, questionScore, checkboxOptions) : () => handleAddInput(question, questionScore)
                                    }
                                    className='cursor-pointer w-12 disabled:opacity-50'
                                >
                                    <img src={AddQnIcon} alt="add question icon"/>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {questionType === QuestionType.SINGLE_CHOICE ? (
                        <div className='mt-5 border-2 rounded-md bg-white border-transparent'>
                            {radioOptions.map((item, index) => (
                                <div key={index} className='flex items-center justify-start space-x-5 m-5'>
                                    <input
                                        type="text"
                                        name="option"
                                        id="option"
                                        onChange={(e) => setOptionQuestion(e.target.value)}
                                        placeholder='Option'
                                        className='w-2/5 block rounded border-2 px-2  text-gray-600 sm:text-sm sm:leading-6 focus:outline-none'
                                    />
                                    <input
                                        type="number"
                                        name="score"
                                        id="score"
                                        onChange={(e) => setOptionScore(parseInt(e.target.value))}
                                        placeholder='Score'
                                        className='block w-1/6 rounded border-2 px-2 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none'
                                    />
                                    <div className='flex items-center justify-center space-x-3'>
                                        {(radioOptions.length > 1) && (
                                            <button
                                                className='bg-dropdownBG rounded px-3 py-1 text-black text-xs disabled:opacity-50'
                                                onClick={() => handleDeleteRadioOption(index + 1)}
                                            >
                                                Delete
                                            </button>
                                        )}
                                        {index === radioOptions.length - 1 && (
                                            <button
                                                disabled={!optionQuestion}
                                                className='bg-sidebarBG rounded px-3 py-1 text-white text-xs disabled:opacity-50'
                                                onClick={() => handleAddRadioOption(optionQuestion, optionScore)}
                                            >
                                                Add Option
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : questionType === QuestionType.MULTIPLE_CHOICE ? (
                        <div className='mt-5 border-2 rounded-md bg-white border-transparent'>
                            {checkboxOptions.map((item, index) => (
                                <div key={index} className='flex items-center justify-start space-x-5 m-5'>
                                    <input
                                        type="text"
                                        name="option"
                                        id="option"
                                        onChange={(e) => setOptionQuestion(e.target.value)}
                                        placeholder='Option'
                                        className='w-2/5 block rounded border-2 px-2  text-gray-600 sm:text-sm sm:leading-6 focus:outline-none'
                                    />
                                    <input
                                        type="number"
                                        name="score"
                                        id="score"
                                        onChange={(e) => setOptionScore(parseInt(e.target.value))}
                                        placeholder='Score'
                                        className='block w-1/6 rounded border-2 px-2 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none'
                                    />
                                    <div className='flex items-center justify-center space-x-3'>
                                        {(checkboxOptions.length > 1) && (
                                            <button
                                                className='bg-dropdownBG rounded px-3 py-1 text-black text-xs disabled:opacity-50'
                                                onClick={() => handleDeleteCheckboxOption(index + 1)}
                                            >
                                                Delete
                                            </button>
                                        )}
                                        {index === checkboxOptions.length - 1 && (
                                            <button
                                                disabled={!optionQuestion}
                                                className='bg-sidebarBG rounded px-3 py-1 text-white text-xs disabled:opacity-50'
                                                onClick={() => handleAddCheckboxOption(optionQuestion, optionScore)}
                                            >
                                                Add Option
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : ''
                    }
                </div>
                <button
                    className='mb-10 bg-sidebarBG text-white px-3 py-1 rounded float-end disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-50'
                    onClick={onSubmit}
                    disabled={!inputs.length}
                >
                    Save Added Questions
                </button>
            </div>
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default AddTemplateQuestions