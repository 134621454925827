import React from "react";
import {CloseIcon} from "../../assets/icons";

interface ModalProps {
    isShown: boolean;
    title: string;
    content: any;
    size?: 'medium' | 'large';
    isHidden: () => void;
    titleSize?: string;
}

const Modal: React.FC<ModalProps> = (props) => {
    const {isShown, title, content, isHidden, size, titleSize} = props;
    return isShown ? (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div
                    className={
                        size === "medium"
                            ? "relative my-6 mx-auto bg-white w-4/5 lg:w-1/3 rounded-lg max-h-screen"
                            : size === "large"
                                ? "relative my-6 mx-auto bg-white w-4/5 lg:max-w-4xl rounded-lg max-h-screen"
                                : "relative my-6 mx-auto bg-white w-4/5 lg:w-1/3 rounded-lg max-h-screen"
                    }
                >
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between mx-5 mt-5 mb-3">
                            <h1 className={`font-Montserrat text-black font-bold ${titleSize ? titleSize : 'text-lg'}`}>{title}</h1>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={isHidden}
                            >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <img src={CloseIcon} alt=" close icon"/>
                </span>
                            </button>
                        </div>
                        <div className="mx-5 border-b"></div>
                        <div className="relative mx-6 my-5 flex-autobg-red-500">{content}</div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-sidebarBG"></div>
        </>
    ) : null;
};
export default Modal;
