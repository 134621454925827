import {EmailIcon, IdIcon, PhoneIcon, VerifiedIcon} from "../../assets/icons";
import React, {useEffect, useState} from "react";
import useAxios from "../../hooks/useAxios";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {NidaDetails} from "../../types/NidaDetails";
import {CustomErrors} from "./Registration";
import {boolean} from "yup";
import Button from "../../shared/components/Button";

interface IProps {
    register: any;
    errors: any;
    next: ()=>void;
}

export interface valueObj {
    value: string;
    error?: string;
}
const Details = ({register,errors,next}: IProps) =>{
    const {
        response: validateNidRes,
        error: validateNidError,
        loading: validateNidLoading,
        sendRequest: validateNidReq,
        clearError: clearNidError
    } = useAxios();
    const validateNidHandler = async (nid:string)=>{
        try {
            await validateNidReq({
                url: `/integrations/nida/${nid}`,
                method: "GET",
                data: {},
            });
        } catch (err) {
        }
    }



    const [customErrors, setCustomErrors] = useState<CustomErrors>({});

    const [nid,setNid] = useState<valueObj>({ value:'',error: undefined});
    const [email,setEmail] = useState<valueObj>({ value:'',error: undefined});
    const [phoneNumber,setPhoneNumber] = useState<valueObj>({ value:'',error: undefined});

    const isEmail=(email: string): boolean => {
        const regexp = new RegExp(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/);
        if(regexp.test(email)){
            const errors_: CustomErrors = customErrors;
            errors_.phoneNumber = undefined;
            setCustomErrors(errors_);
            return true;
        }else{
            const errors_: CustomErrors = customErrors;
            errors_.email = "Invalid email address";
            setCustomErrors(errors_);
        }
        return false;
    }

    const isNID=(nid: string): boolean => {
        if(nid.length===16){
            const errors_: CustomErrors = customErrors;
            errors_.phoneNumber = undefined;
            setCustomErrors(errors_);
            return true;
        }else{
            const errors_: CustomErrors = customErrors;
            errors_.nid = "Invalid NID";
            setCustomErrors(errors_);
            return false;
        }
    }

    const isPhoneNumber=(phoneNumber: string): boolean => {
        if(!phoneNumber.startsWith("078") &&
            !phoneNumber.startsWith("079") &&
            !phoneNumber.startsWith("072") &&
            !phoneNumber.startsWith("073") ){
            const errors_: CustomErrors = customErrors;
            errors_.phoneNumber = "Invalid Phone Number";
            setCustomErrors(errors_);
            return false;
        }
        if(phoneNumber.length === 10){
            const errors_: CustomErrors = customErrors;
            errors_.phoneNumber = undefined;
            setCustomErrors(errors_);
            return true;
        }else{
            const errors_: CustomErrors = customErrors;
            errors_.phoneNumber = "Invalid Phone Number";
            setCustomErrors(errors_);
            return false;
        }

    }

    const validateDetails = ()=>{
        if(email.error === undefined &&
            nid.error === undefined &&
            phoneNumber.error === undefined && email.value !== '' && phoneNumber.value !== ''&&
            nid.value!==''
        ){
            next();
        }

    }



    const onChangeNid=(value: string)=>{
        const nid=isNID(value);
       if(nid){
           setNid({value,error:undefined});
       }else{
           setNid({value,error:'Invalid NID'});
       }
    }

    const onChangeEmail=(value: string)=>{
        const email=isEmail(value);
        if(email){
            setEmail({value,error:undefined});
        }else{
            setEmail({value,error:'Invalid Email'});
        }
    }

    const onChangePhoneNumber=(value: string)=>{
        const email=isPhoneNumber(value);
        if(email){
            setPhoneNumber({value,error:undefined});
        }else{
            setPhoneNumber({value,error:'Invalid Phone Number'});
        }
    }


    return (
        <div>
            <div className="mb-3">
                <div className="mt-3">
                    <div className='flex border-2 rounded w-full '>
                        <img className='ml-3' src={IdIcon} alt="user email icon"/>
                        <input
                            {...register("nid")}
                            type="text"
                            name="nid"
                            id="nid"
                            onChange={(e)=>onChangeNid(e.target.value)}
                            onBlur={async (e) => {
                                await validateNidHandler(e.target.value)
                            }}
                            placeholder='Enter your NID'
                            className={`pl-2 block w-full ${nid.error && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        {validateNidLoading &&
                            <Spin indicator={<LoadingOutlined style={{fontSize: 28, color: '#BFBFBF'}} spin/>}/>}
                        {validateNidRes?.data && <img className='mr-3' src={VerifiedIcon} alt="user email icon"/>}
                    </div>
                    <p className="text-red-500 text-xs">{nid.error}</p>
                </div>
            </div>
            {validateNidRes?.data && <div>
                <p className="text-xs">{validateNidRes?.data?.foreName} {validateNidRes?.data?.surnames} </p>
                <p className="w-16 mt-2 bg-badgeBG p-0.5 flex items-center justify-center rounded text-primary text-xs">Verified</p>
            </div>}
            <div className="mb-3">
                <div className="mt-3">
                    <div className='flex border-2 rounded w-full '>
                        <img className='ml-3' src={EmailIcon} alt="user email icon"/>
                        <input
                            {...register("email")}
                            onChange={(e)=>onChangeEmail(e.target.value)}
                            type="text"
                            name="email"
                            id="email"
                            placeholder='Enter your email'
                            className={`pl-2 block w-full ${email.error && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{email.error}</p>
                </div>
            </div>

            <div className="mb-3">
                <div className="mt-3">
                    <div className='flex border-2 rounded w-full '>
                        <img className='ml-3' src={PhoneIcon} alt="user email icon"/>
                        <input
                            {...register("phoneNumber")}
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder='Enter your phone number'
                            onChange={(e)=>onChangePhoneNumber(e.target.value)}
                            className={`pl-2 block w-full ${phoneNumber.error && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                    </div>
                    <p className="text-red-500 text-xs">{phoneNumber.error}</p>
                </div>
            </div>

            <div className="flex items-center justify-between mt-8">
                <Button btnText={'Next'}
                        // isValid={current === 1 ? !isEmailVerified : false}
                        type="button"
                        onButtonClick={() => validateDetails()}/>
            </div>
        </div>);
}

export default Details;