import React, {useEffect, useState} from 'react'
import {IJobRequisition} from "../../types/JobRequisition";
import {Select, type SelectProps, Switch} from "antd";
import {
    hiringModelOptions,
    justificationOptions, payBasisOptions,
    requisitionTypeOptions
} from "../../shared/constants/SelectOptions";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import useAxios from "../../hooks/useAxios";
import {IJobProfile} from "../../types/JobProfile";
import {IUsers} from "../../types/user";
import {Privileges} from "../../shared/constants/privileges";
import {
    HiringModel,
    Justification,
    PayBasis,
    RequisitionType
} from "../../shared/constants/JobRequisition";
import ErrorModal from "../../shared/components/ErrorModal";
import moment from "moment";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

interface IProps {
    onCancel: () => void;
    requisitionDetails: IJobRequisition
}

interface IFormData {
    number_of_openings: number;
    shortlisting_marks: number;
    application_deadline: string;
    maximum_age?: string;
}

const schema = yup
    .object({
        number_of_openings: yup.number().required(),
        shortlisting_marks: yup.number().required(),
        application_deadline: yup.string().required(),
        maximum_age: yup.string().optional(),
    })
    .required();

const EditJobRequisition = ({onCancel, requisitionDetails}: IProps) => {

    const [selectedJobProfile, setSelectedJobProfile] = useState<string>(requisitionDetails.job_profile.id);
    const [selectedRequisitionType, setSelectedRequisitionType] = useState<number>(requisitionDetails.requisition_type);
    const [selectedJustification, setSelectedJustification] = useState<number>(requisitionDetails.justification);
    const [selectedPayBasis, setSelectedPayBasis] = useState<number>(requisitionDetails.pay_basis);
    const [selectedHiringModel, setSelectedHiringModel] = useState<number>(requisitionDetails.hiring_model);
    const [selectedHiringManager, setSelectedHiringManager] = useState<string>(requisitionDetails.hiring_manager.id);
    const [isExperienceRequired, setIsExperienceRequired] = useState<boolean>(requisitionDetails.is_experience_required);

    const {response, error, loading, sendRequest, clearError} = useAxios()

    const {
        response: jobProfileRes,
        error: jobProfileError,
        loading: jobProfileLoading,
        sendRequest: sendJobProfileReq,
        clearError: clearJobProfileError
    } = useAxios();


    const {
        response: userRes,
        error: userError,
        loading: userLoading,
        sendRequest: sendUserReq,
        clearError: clearUserError
    } = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const jobProfileOptions: { label: string, value: string }[] = jobProfileRes?.data?.map(
        (profile: IJobProfile) => ({
            label: profile.title,
            value: profile.id,
        }),
    );

    const userOptions: SelectProps["options"] = userRes?.data?.map(
        (user: IUsers) => ({
            label: user.names,
            value: user.id,
        }),
    )

    const handleJobProfileChange = (value: string) => {
        setSelectedJobProfile(value);
    };

    const handleJobRequisitionChange = (value: any) => {
        setSelectedRequisitionType(value);
    };

    const handleJustificationChange = (value: any) => {
        setSelectedJustification(value);
    };

    const handlePayBasisChange = (value: any) => {
        setSelectedPayBasis(value);
    };

    const handleHiringModelChange = (value: any) => {
        setSelectedHiringModel(value);
    }

    const handleHiringManagerChange = (value: string) => {
        setSelectedHiringManager(value);
    }

    const onSwitchChange = (checked: boolean) => {
        setIsExperienceRequired(checked);
    };

    const onSubmit = async (data: IFormData) => {
        try {
            await sendRequest({
                url: `/requisition/${requisitionDetails.id}`,
                method: "PUT",
                data: {
                    job_profile: selectedJobProfile,
                    shortlisting_marks: data.shortlisting_marks,
                    requisition_type: selectedRequisitionType,
                    number_of_openings: data.number_of_openings,
                    justification: selectedJustification,
                    application_deadline: data.application_deadline,
                    maximum_age: data.maximum_age,
                    hiring_manager: selectedHiringManager,
                    pay_basis: selectedPayBasis,
                    hiring_model: selectedHiringModel,
                    is_experience_required: isExperienceRequired
                },
            });
        } catch (err) {
        }
    }

    const getJobProfileHandler = async () => {
        try {
            await sendJobProfileReq({
                url: "/jobProfile",
                method: "GET",
            });
        } catch (err) {
        }
    };

    const getUsersHandler = async () => {
        try {
            await sendUserReq({
                url: `/user?privilege=${Privileges.CAN_APPROVE_REQUISITION}`,
                method: "GET",
            });
        } catch (err) {
        }

    }

    useEffect(() => {
        getJobProfileHandler();
    }, []);

    useEffect(() => {
        getUsersHandler();
    }, []);

    if (response?.data) {
        onCancel()
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3 flex items-center justify-center space-x-5'>
                    <div className='w-1/2'>
                        <label
                            htmlFor="Job Profile"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Job Profile:
                        </label>
                        <div className="mt-2">
                            <Select
                                defaultValue={requisitionDetails.job_profile.title}
                                rootClassName="font-Montserrat"
                                loading={jobProfileLoading}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleJobProfileChange}
                                options={jobProfileOptions?.length ? jobProfileOptions : []}
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <label
                            htmlFor="shortlisting_marks"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Shortlisting Marks:
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("shortlisting_marks")}
                                type="number"
                                defaultValue={requisitionDetails.shortlisting_marks}
                                name="shortlisting_marks"
                                placeholder='Type here'
                                id="shortlisting_marks"
                                className={`px-2 block w-full rounded ${errors.shortlisting_marks && "border-red-500"} placeholder:text-xs border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                            <p className="text-red-500 text-xs">{errors.shortlisting_marks?.message}</p>
                        </div>
                    </div>
                </div>
                <div className="mb-3 flex items-center justify-center space-x-5">
                    <div className='w-1/2'>
                        <label
                            htmlFor="requisition_type"
                            className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                        >
                            Requisition Type:
                        </label>
                        <div className="mt-2">
                            <Select
                                defaultValue={requisitionDetails.requisition_type === RequisitionType.INTERNSHIP ? 'INTERNSHIP' : requisitionDetails.requisition_type === RequisitionType.CONTRACT ? 'CONTRACT' : requisitionDetails.requisition_type === RequisitionType.PERMANENT ? 'PERMANENT' : ''}
                                rootClassName="font-Montserrat"
                                loading={!requisitionTypeOptions?.length}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleJobRequisitionChange}
                                options={requisitionTypeOptions}
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <label
                            htmlFor="number_of_openings"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Number of Openings:
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("number_of_openings")}
                                type="number"
                                name="number_of_openings"
                                defaultValue={requisitionDetails.number_of_openings}
                                placeholder='Type here'
                                id="number_of_openings"
                                className={`px-2 block w-full rounded ${errors.number_of_openings && "border-red-500"} placeholder:text-xs border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                            <p className="text-red-500 text-xs">{errors.number_of_openings?.message}</p>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <label
                            htmlFor="justification"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Justification:
                        </label>
                        <div className="mt-2">
                            <Select
                                defaultValue={requisitionDetails.justification === Justification.EXTENSION ? 'EXTENSION' : requisitionDetails.justification === Justification.MODIFICATION ? 'MODIFICATION' : requisitionDetails.justification === Justification.NEW_POSITION ? 'NEW POSITION' : requisitionDetails.justification === Justification.REPLACEMENT ? 'REPLACEMENT' : ''}
                                rootClassName="font-Montserrat"
                                loading={!justificationOptions?.length}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleJustificationChange}
                                options={justificationOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-3 flex items-center justify-center space-x-5">
                    <div className='w-1/2'>
                        <label
                            htmlFor="pay_basis"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Pay Basis:
                        </label>
                        <div className="mt-2">
                            <Select
                                defaultValue={requisitionDetails.pay_basis === PayBasis.MONTHLY ? 'MONTHLY' : requisitionDetails.pay_basis === PayBasis.YEARLY ? 'YEARLY' : ''}
                                rootClassName="font-Montserrat"
                                loading={!payBasisOptions?.length}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handlePayBasisChange}
                                options={payBasisOptions}
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <label
                            htmlFor="maximum_age"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Maximum Age:
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("maximum_age")}
                                type="number"
                                defaultValue={requisitionDetails.maximum_age}
                                name="maximum_age"
                                id="maximum_age"
                                placeholder='Type here'
                                className={`px-2 block w-full rounded ${errors.maximum_age && "border-red-500"} placeholder:text-xs border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                            <p className="text-red-500 text-xs">{errors.maximum_age?.message}</p>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <label
                            htmlFor="hiring_model"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Hiring Model:
                        </label>
                        <div className="mt-2">
                            <Select
                                defaultValue={requisitionDetails.hiring_model === HiringModel.OPEN ? 'OPEN' : requisitionDetails.hiring_model === HiringModel.COMPETITION ? 'COMPETITION' : requisitionDetails.hiring_model === HiringModel.INTERNAL_RECRUITMENT ? 'INTERNAL RECRUITMENT' : ' '}
                                rootClassName="font-Montserrat"
                                loading={!hiringModelOptions?.length}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleHiringModelChange}
                                options={hiringModelOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-3 flex items-center justify-center space-x-5">
                    {/*<div className='w-1/2'>*/}
                    {/*    <label*/}
                    {/*        htmlFor="employment_status"*/}
                    {/*        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"*/}
                    {/*    >*/}
                    {/*        Employment Status:*/}
                    {/*    </label>*/}
                    {/*    <div className="mt-2">*/}
                    {/*        <Select*/}
                    {/*            defaultValue={requisitionDetails.employment_status === EmploymentStatus.CONTRACTUAL ? 'CONTRACTUAL' : requisitionDetails.employment_status === EmploymentStatus.REGULAR ? 'REGULAR' : requisitionDetails.employment_status === EmploymentStatus.LIMITED_TERM ? 'LIMITED TERM' : requisitionDetails.employment_status === EmploymentStatus.TEMPORARY ? 'TEMPORARY' : ''}*/}
                    {/*            rootClassName="font-Montserrat"*/}
                    {/*            loading={!employmentStatusOptions?.length}*/}
                    {/*            allowClear*/}
                    {/*            style={{width: "100%"}}*/}
                    {/*            placeholder="Please select"*/}
                    {/*            onChange={handleEmploymentStatusChange}*/}
                    {/*            options={employmentStatusOptions}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='w-1/2'>
                        <label
                            htmlFor="application_deadline"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Application Deadline:
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("application_deadline")}
                                type="datetime-local"
                                defaultValue={moment(requisitionDetails.application_deadline).format('YYYY-MM-DDTHH:mm')}
                                name="application_deadline"
                                id="application_deadline"
                                placeholder='Type here'
                                className={`pl-2 block w-full rounded ${errors.application_deadline && "border-red-500"}  placeholder:text-xs border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                            <p className="text-red-500 text-xs">{errors.application_deadline?.message}</p>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <label
                            htmlFor="hiring_manager"
                            className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                        >
                            Hiring Manager:
                        </label>
                        <div className="mt-2">
                            <Select
                                defaultValue={requisitionDetails.hiring_manager.names}
                                rootClassName="font-Montserrat"
                                loading={userLoading}
                                allowClear
                                style={{width: "100%"}}
                                placeholder="Please select"
                                onChange={handleHiringManagerChange}
                                options={userOptions?.length ? userOptions : []}
                            />
                        </div>
                    </div>
                </div>
                <div className='mb-3 w-1/2 flex items-center justify-start space-x-2'>
                    <Switch
                        rootClassName='bg-switchBG'
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        onChange={onSwitchChange}
                        defaultChecked={isExperienceRequired}
                    />
                    <h3 className='text-gray-500 text-sm'>is Candidate Experience Mandatory?</h3>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Requisition" loading={loading}
                            isValid={
                                !selectedJobProfile ||
                                !selectedHiringManager ||
                                (selectedRequisitionType === undefined) ||
                                (selectedJustification === undefined) ||
                                (selectedPayBasis === undefined) ||
                                (selectedHiringModel === undefined)
                            }
                    />
                </div>
            </form>
            {jobProfileError ?
                <ErrorModal error={jobProfileError.error} onClose={clearJobProfileError}/> : userError ?
                    <ErrorModal error={userError.error} onClose={clearUserError}/> : error ?
                        <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    )
}

export default EditJobRequisition;