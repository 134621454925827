import { TableStyles } from "react-data-table-component";

export const customTableStyles = {
  headRow: {
    style: {
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "#dfeadc",
    },
  },
  headCells: {
    style: {
      textAlign: "center",
    },
  },
  rows: {
    style: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#FEFDFB",
      },
      fontSize: "12px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "#e5eee3",
      },
      cursor: "pointer",
    },
  },
  columns: {
    style: {
      width: "auto",
    },
  },
} as TableStyles;
