import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Button from "../../shared/components/Button";
import {Select, type SelectProps} from "antd";
import {Editor} from "react-draft-wysiwyg";
import {convertToRaw, EditorState} from "draft-js";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import {IErpPosition} from "./types/ErpPosition";

interface IProps {
    onCancel: () => void;
}

const CreateJobProfile = ({onCancel}: IProps) => {

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );
    const [jobDescription, setJobDescription] = useState(() => EditorState.createEmpty());
    const [jobRequirements, setJobRequirements] = useState(() => EditorState.createEmpty());

    const [selectedJobProfile, setSelectedJobProfile] = useState<string>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: jobProfileRes,
        error: jobProfileError,
        loading: jobProfileLoading,
        sendRequest: sendJobProfileReq,
        clearError: clearJobProfileError
    } = useAxios();

    const {
        handleSubmit,
    } = useForm();

    const jobProfileOptions: { label: string, value: string }[] = jobProfileRes?.data?.map(
        (position: IErpPosition) => ({
            label: position.name,
            value: position.position_id
        }),
    );

    const onSubmit = async () => {
        try {
            const payload = {
                position_id: selectedJobProfile,
                job_responsibilities: JSON.stringify(
                    convertToRaw(editorState.getCurrentContent()),
                ),
                job_summary: JSON.stringify(
                    convertToRaw(jobDescription.getCurrentContent()),
                ),
                job_requirement: JSON.stringify(
                    convertToRaw(jobRequirements.getCurrentContent()),
                ),
            };
            await sendRequest({
                url: "/jobProfile",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    }

    const handleJobProfileChange = (value: string) => {
        setSelectedJobProfile(value);
    }

    const onEditorStateChange = (editorContent: EditorState) => {
        setEditorState(editorContent);
    };

    const onJobDescriptionChange = (editorContent: EditorState) => {
        setJobDescription(editorContent);
    }

    const onJobRequirementsChange = (editorContent: EditorState) => {
        setJobRequirements(editorContent);
    }

    const getJobProfileHandler = async () => {
        try {
            await sendJobProfileReq({
                url: `/jobProfile/erp`,
                method: "GET",
            });
        } catch (err) {
        }
    }


    useEffect(() => {
        getJobProfileHandler();
    }, []);

    if (response?.data) {
        onCancel();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-3 w-2/3'>
                    <label
                        htmlFor="organization"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Title:
                    </label>
                    <div className="mt-2">
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            rootClassName="font-Montserrat"
                            loading={jobProfileLoading}
                            style={{width: '100%'}}
                            placeholder="Please select"
                            allowClear
                            onChange={handleJobProfileChange}
                            options={jobProfileOptions?.length ? jobProfileOptions : []}
                        />
                    </div>
                </div>
                <div className="mb-24">
                    <label
                        htmlFor="job_description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        About the Job:
                    </label>
                    <div className="mt-1">
                        <Editor
                            editorState={jobDescription}
                            editorClassName="bg-notesBG"
                            wrapperClassName="h-32"
                            onEditorStateChange={onJobDescriptionChange}
                            placeholder=" Type job description here..."
                        />
                    </div>
                </div>
                <div className="mb-24">
                    <label
                        htmlFor="responsibilities"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Responsibilities:
                    </label>
                    <div className="mt-1">
                        <Editor
                            editorState={editorState}
                            editorClassName="bg-notesBG"
                            wrapperClassName="h-32"
                            onEditorStateChange={onEditorStateChange}
                            placeholder=" Type job responsibilities here..."
                        />
                    </div>
                </div>
                <div className="">
                    <label
                        htmlFor="requirements"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Job Requirements:
                    </label>
                    <div className="mt-1">
                        <Editor
                            editorState={jobRequirements}
                            editorClassName="bg-notesBG"
                            wrapperClassName="h-32"
                            onEditorStateChange={onJobRequirementsChange}
                            placeholder=" Type job requirements here..."
                        />
                    </div>
                </div>
                <div className="mt-24 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Job Profile" loading={loading}
                            isValid={
                                !selectedJobProfile ||
                                !editorState.getCurrentContent().getPlainText() ||
                                !jobDescription.getCurrentContent().getPlainText() ||
                                !jobRequirements.getCurrentContent().getPlainText()
                            }
                    />
                </div>
            </form>
            ,
            {jobProfileError ?
                <ErrorModal error={jobProfileError.error} onClose={clearJobProfileError}/> : error ?
                    <ErrorModal error={error.error} onClose={clearError}/> : " "}
        </>
    );
}

export default CreateJobProfile;