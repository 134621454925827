import React from "react";

interface IProps {
    title: string;
    dropdown?: any;
    textSize?: string
}

const PageTitle: React.FC<IProps> = ({title, dropdown, textSize}) => {

    return (
        <>
            <div className='flex items-center justify-between mb-8'>
                <h3 className={`text-black ${textSize ? textSize : 'text-2xl'} font-bold`}>{title}</h3>
                {dropdown ? dropdown : ""}
            </div>
        </>
    );
};

export default PageTitle;
