import {NoDataFound, NoJobDataFound} from "../../assets/icons";

interface NoRecordFoundProps {
    color?: string;
    title?: string;
    textSize?: 'small' | 'medium' | 'large';
    type?: 'DATA' | 'JOB';
    WidthSize?: string;
}

const NoRecordFound = ({color, title, type, textSize, WidthSize}: NoRecordFoundProps) => {
    return (
        <div className={`text-center max-w-96 ${WidthSize} mx-auto`}>
            <img src={type === 'JOB' ? NoJobDataFound : NoDataFound} alt="table filter" className="mx-auto"/>
            <h1 className={`${textSize === 'small' ? 'text-xs' : textSize === 'medium' ? 'text-sm' : textSize === 'large' ? 'text-xl' : 'text-xl'} ${type === 'JOB' ? 'text-primary' : 'text-gray-700'} font-Montserrat`}>
                Oops!! No record found.
            </h1>
            <p className={`${textSize === 'small' ? 'text-xs' : textSize === 'medium' ? 'text-sm' : textSize === 'large' ? 'text-lg' : 'text-lg'}  ${type === 'JOB' ? 'text-primary' : 'text-gray-700'} font-Montserrat`}>
                Please try again!
            </p>
        </div>
    );
};

export default NoRecordFound;
