import React, {useEffect, useState} from "react";
import {EditIcon} from "../../assets/icons";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import AddNewAllowance from "./AddNewAllowance";
import DataTable, {TableColumn} from "react-data-table-component";
import {customTableStyles} from "../../shared/css/customTableStyles";
import {IAllowance} from "../../types/allowance";
import moment from "moment";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import NoRecordFound from "../../shared/components/NoRecordFound";
import EditAllowance from "./EditAllowance";
import PageButton from "../../shared/components/PageButton";

const Allowance = () => {
    const [addNewBenefit, setAddNewBenefit] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [editAllowance, setEditAllowance] = useState(false);
    const [allowanceDetails, setAllowanceDetails] = useState<IAllowance>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const columns: TableColumn<IAllowance>[] = [
        {
            name: "Created On",
            selector: (row) => moment(new Date(row.created_at)).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "Benefit name",
            selector: (row) =>
                (
                    <span className="text-gray-500">
            {" "}
                        {row.allowance_name.toUpperCase()}{" "}
          </span>
                ) as unknown as string,
            wrap: true,
        },
        {
            name: "Benefit amount",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.amount} RWF</span>
                ) as unknown as string,
            sortable: true,
        },
        {
            name: "Description",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.description}</span>
                ) as unknown as string,
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row) =>
                (
                    <Actions
                        items={[
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setAllowanceDetails(row);
                                    setEditAllowance(true);
                                }}
                                icon={EditIcon}
                                label="Edit benefit"
                                color="gray-500"
                            />,
                        ]}
                    />
                ) as unknown as string,
        },
    ];

    const getAllowanceHandler = async () => {
        try {
            await sendRequest({
                url: "/settings/allowance",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getAllowanceHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getAllowanceHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    return (
        <>
            <PageTitle title='Benefits/Allowances' dropdown={
                <PageButton title='Add New Benefit' onClickHandler={() => {
                    setAddNewBenefit(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={response?.data}
                        pagination
                        paginationServer
                        paginationPerPage={15}
                        customStyles={customTableStyles}
                    />
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {addNewBenefit && (
                <Modal
                    title="Add New Benefit"
                    content={<AddNewAllowance onCancel={() => {
                        setAddNewBenefit(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={addNewBenefit}
                    isHidden={() => setAddNewBenefit(false)}
                    size="medium"
                />
            )}
            {editAllowance && allowanceDetails && (
                <Modal
                    title="Edit Job Benefit"
                    content={<EditAllowance allowanceDetails={allowanceDetails} onCancel={() => {
                        setEditAllowance(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={editAllowance}
                    isHidden={() => {
                        setAllowanceDetails(undefined)
                        setEditAllowance(false)
                    }}
                    size="medium"
                />)}
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default Allowance;