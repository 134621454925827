import React from "react";
import {KeyIcon} from "../../assets/icons";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {message} from "antd";
import {HttpStatusCode} from "axios";

interface IProps {
    onCancel: () => void;
}

interface IFormData {
    oldPassword: string;
    newPassword: string;
    confirmPswd: string;
}

const schema = yup
    .object({
        oldPassword: yup.string().required("Old password is required"),
        newPassword: yup.string().required("New password is required"),
        confirmPswd: yup.string().oneOf([yup.ref('newPassword')], 'Passwords should match').required("Confirm password is required")
    })
    .required();

const ChangePassword = ({onCancel}: IProps) => {

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            await sendRequest({
                url: "/candidate-profile/password/changePassword",
                method: "PUT",
                data: {
                    old_password: data.oldPassword,
                    new_password: data.newPassword,
                }
            });
        } catch (err) {
        }
    }

    if (response?.status === HttpStatusCode.Ok) {
        onCancel();
        message.success('Password changed successfully');
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <div className="mt-3">
                        <div className='flex border-2 rounded items-center justify-center'>
                            <img className='ml-3' src={KeyIcon} alt="user email icon"/>
                            <input
                                {...register("oldPassword")}
                                type="password"
                                name="oldPassword"
                                id="oldPassword"
                                placeholder='Enter your old password'
                                className={`pl-2 block w-full ${errors.oldPassword && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.oldPassword?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="mt-3">
                        <div className='flex border-2 rounded items-center justify-center'>
                            <img className='ml-3' src={KeyIcon} alt=" password key icon"/>
                            <input
                                {...register("newPassword")}
                                type="password"
                                name="newPassword"
                                placeholder='Enter new password'
                                id="newPassword"
                                className={`pl-2 block w-full ${errors.newPassword && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.newPassword?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="mt-3">
                        <div className='flex border-2 rounded items-center justify-center'>
                            <img className='ml-3' src={KeyIcon} alt=" password key icon"/>
                            <input
                                {...register("confirmPswd")}
                                type="password"
                                name="confirmPswd"
                                placeholder='Re-enter new password'
                                id="confirmPswd"
                                className={`pl-2 block w-full ${errors.confirmPswd && "border-red-500"} py-0.5 placeholder:text-xs text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                            />
                        </div>
                        <p className="text-red-500 text-xs">{errors.confirmPswd?.message}</p>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 mb-3 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button
                        type="submit"
                        btnText="Save new password" loading={loading}
                    />

                </div>
            </form>
            {error && <ErrorModal error={error.message} onClose={clearError}/>}
        </>
    )
}

export default ChangePassword;