import React from "react";
import Chart from 'react-apexcharts'
import {AddIcon, EditIcon, MenuIcon, OptionsVerticalIcon, ViewIcon} from "../../assets/icons";
import {Dropdown, MenuProps, Space} from "antd";

const series = [55, 44]
const options: any = {
    chart: {
        width: 380,
        type: 'pie',
        fontFamily: 'Montserrat',
        animations: {
            easing: 'easeinout',
        },
    },
    colors: ['#81632D', '#BE9446', '#E6D6B7', '#D5B986'],
    stroke: {
        curve: 'smooth',
        width: 0
    },
    labels: ['Female', 'Male'],

}


const PieChartComponent = () => {

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <h3
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Filter by Gender
                </h3>
            ),
        },
        {
            key: "2",
            label: (
                <h3
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Filter by Age
                </h3>
            ),
        },
        {
            key: "3",
            label: (
                <h3
                    className="font-Montserrat text-gray-500 text-xs"
                >
                    Filter by Applicant Status
                </h3>
            ),
        },
    ];

    return (
        <div>
            <div className='mb-5 flex items-center justify-between'>
                <h3 className='font-bold text-gray-700 text-sm ml-2'>Shortlisted Applicants</h3>
                <Dropdown trigger={["click"]} menu={{items}}>
                    <div onClick={(e) => {
                        e.preventDefault()
                    }
                    }>
                        <Space>
                            <div className='cursor-pointer'>
                                <img src={MenuIcon} alt="menu icon"/>
                            </div>
                        </Space>
                    </div>
                </Dropdown>

            </div>
            <Chart options={options} series={series} type="pie" height={380}/>
        </div>
    )
}

export default PieChartComponent;