export enum Status {
    ACTIVE = 1,
    INACTIVE = 2,
}

export enum RoleAction {
    approve = 'APPROVE',
    disable = 'DISABLE',
}

export enum UserAction {
    approve = 'APPROVE',
    disable = 'DISABLE',
}