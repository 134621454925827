import React from "react";
import {PlusIcon} from "../../assets/icons";

interface IProps {
    onClickHandler: () => void
    title: string
}

const PageButton = ({onClickHandler, title}: IProps) => {
    return (
        <button
            className="flex items-center justify-center border border-transparent rounded bg-sidebarBG py-1 px-3 space-x-1"
            onClick={onClickHandler}
        >
            <img src={PlusIcon} alt="plus icon"/>{" "}
            <span className="font-semibold text-white">
              {" "}
                {title}
            </span>
        </button>
    )
}

export default PageButton