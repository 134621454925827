import React, {useEffect, useState} from "react";
import {EditIcon, QualificationIcon} from "../../assets/icons";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateJobQualification from "./CreateJobQualification";
import useAxios from "../../hooks/useAxios";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import NoRecordFound from "../../shared/components/NoRecordFound";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {IJobQualification} from "../../types/JobQualification";
import EditQualification from "./EditQualification";
import PageButton from "../../shared/components/PageButton";

const ContainerHeight = 400;

const JobQualification = () => {

    const [showAddJobQualification, setShowAddJobQualification] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [data, setData] = useState<IJobQualification[]>([]);
    const [editQualification, setEditQualification] = useState(false);
    const [qualificationDetails, setQualificationDetails] = useState<IJobQualification>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const getJobQualificationHandler = async () => {
        try {
            await sendRequest({
                url: "/settings/qualification",
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getJobQualificationHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getJobQualificationHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} qualifications loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getJobQualificationHandler();
        }
    };

    return (
        <>
            <PageTitle title='Job Qualifications' dropdown={
                <PageButton title='Add Job Qualification' onClickHandler={() => {
                    setShowAddJobQualification(true)
                }
                }/>
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <List>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                            onScroll={onScroll}
                        >
                            {(item: IJobQualification) => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={QualificationIcon}/>}
                                        title={
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                                                {item.name}
                                            </span>
                                        }
                                        description={
                                            <span className="font-Montserrat text-gray-500 text-xs">
                                                {item.description ? item.description : "No description available"}
                                            </span>
                                        }
                                    />
                                    <div>
                                        <Actions
                                            items={[
                                                <DropdownMenuItem
                                                    handlerFn={() => {
                                                        setQualificationDetails(item)
                                                        setEditQualification(true)
                                                    }}
                                                    icon={EditIcon}
                                                    label="Edit Job Qualification"
                                                    color="gray-500"
                                                />,
                                            ]}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showAddJobQualification && (
                <Modal
                    title="Add New Job Qualification"
                    content={<CreateJobQualification onCancel={() => {
                        setShowAddJobQualification(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddJobQualification}
                    isHidden={() => setShowAddJobQualification(false)}
                    size="medium"
                />
            )}
            {editQualification && qualificationDetails && (
                <Modal
                    title="Edit Job Qualification"
                    content={<EditQualification qualificationDetails={qualificationDetails} onCancel={() => {
                        setEditQualification(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={editQualification}
                    isHidden={() => {
                        setQualificationDetails(undefined)
                        setEditQualification(false)
                    }}
                    size="medium"
                />)}

            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default JobQualification;